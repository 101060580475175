// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/**
 * the code contained in this file is rendering the content (meaning the ui-elements) of
 * the modal that opens when an item on the survey screen is clicked on. the user-input
 * received by these ui-elements is persisted in the object "questionnaireItemMap", located
 * in the checkIn state.
 *
 * the following terms are used in the comments in this file:
 *
 * item:
 * a single questionnaire item:
 * https://www.hl7.org/fhir/questionnaire-definitions.html#Questionnaire.item
 *
 * categories:
 * all first level items with linkIds like "1" or "6" or "15"
 * page:
 * a page is composed of all sub-items of a category that have
 * the identical value as the second position of their linkId. for example:
 * all linkIds starting with "1.2" (and "1.2.1" and "1.2.1.1" and so on) will
 * be considered a page
 */

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, { useEffect, useRef, useState } from 'react';
// check if Alert doesn't work on web, then follow example from https://github.com/necolas/react-native-web/issues/1026 -> should work
import {
  Dimensions,
  I18nManager,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import alert from '../../components/shared/Alert';
import { useDispatch, useSelector } from 'react-redux';

// components
// RNModal is not supported for web -> should work now
// TOOD: find alternative, but perhaps simply use the "regular" Modal, s. https://necolas.github.io/react-native-web/docs/modal/
// What I have done now: use RNModal for native and Paper Modal for web

// RNModal for native
// import RNModal from '../shared/RNModal';
// should work now
import RNModal from 'react-native-modal';

// Modal for web (needs the other imports to work as well)
// no need because react-native-modal works now
// import { Modal, Portal, PaperProvider } from 'react-native-paper';

// import {Icon} from 'react-native-elements';
// import { Icon } from '@rneui/themed';

// redux actions
import {setContinueCategoryIndex, setContinueWithCategoryIndex, switchContent} from '../../store/questionnaire.slice';

// services & config
import setAccessibilityResponder from '../../services/setAccessibilityResponder';
import translate from '../../services/localization';
import { appConfig, theme } from '../../config';

// custom components
import QuestionnaireItem from './questionnaireItem';
import BottomBar from './bottomBar';
import RenderHtml from 'react-native-render-html';
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';
import exportService from '../../services/questionnaireAnalyzer';
import { ScrollIndicatorWrapper } from '../../components/shared';
import {
  sendQuestionnaireResponse,
  reset,
  resetAnswers,
} from '../../store/sharedActions';
import NetInfo from '@react-native-community/netinfo';
import { ConfirmDialog } from 'react-native-simple-dialogs';
import { IconButton, MD3Colors } from 'react-native-paper';
import ProgressBar from '../../components/questionnaireModal/progressbar';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Routes } from '../../navigation/constants';

/***********************************************************************************************
 * component:
 * renders the questionnaireModal and the contents of the questionnaire
 ***********************************************************************************************/
function QuestionnaireModal({ nav = null }) {
  const dispatch = useDispatch();

  // creating references
  const scrollViewRef = useRef();
  const modalTitleRef = useRef();

  const [dialogCancelVisible, setDialogCancelVisible] = useState();
  const [headerShown, setHeaderShown] = useState(false);

  // setting defaults
  let scrollOffset = 0;

  const {
    pageIndex,
    categoryIndex,
    categories,
    itemMap,
    FHIRmetadata: metadata,
  } = useSelector((state) => state.Questionnaire);

  const { subjectId } = useSelector((state) => state.User);

  const progress = () => {
    return appConfig.useStrictModeProgressBar
      ? questionnaireAnalyzer.calculatePageProgress(
          categories,
          categoryIndex,
          pageIndex,
          itemMap,
        )
      : pageIndex / categories[categoryIndex].item.length;
  };

  // show the modal if the currently chosen categoryIndex is valid (i.e. > -1)
  const modalVisible = categoryIndex > -1;

  const { width } = useWindowDimensions();

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      (e) => {
        setKeyboardVisible(true); // or some other action
        setKeyboardHeight(e.endCoordinates.height);
      },
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false); // or some other action
        setKeyboardHeight(0);
      },
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  useEffect(() => {
    setAccessibilityResponder(modalTitleRef);
  });

  /**
   * handles the scroll-event of the scrollView
   * @param  {object} event scroll event
   */
  const handleOnScroll = (event) => {
    // just sets the current scrollOffset
    scrollOffset = event.nativeEvent.contentOffset.y;
  };

  /**
   * @param  {{ y: number, animated: boolean }} element UI element that RNModal will scroll to (for example if the software-keyboard is shown)
   */
  const handleScrollTo = (element) => {
    // scrolls to the given element if the scrollView is currently active
    scrollViewRef?.current?.scrollTo({ ...element, animated: true });
  };

  const hideModalHandle = () => {
    dispatch(switchContent({ categoryIndex: -1, pageIndex: 0 }));
  };

  const {
        registryDataErstmeldebogenKind,
        registryDataVerlaufsbogenKind
    } = useSelector((state) => state.RegistryData);


  // check whether the current page has been completely answered
  const completed =
    itemMap?.[categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId]?.done;

  const required =
    itemMap?.[categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId]
      ?.required;

  const skippable =
    itemMap?.[
      categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId
    ]?.fieldAnnotation?.includes('[skippable]');
  console.log('skippable');
  console.log(skippable);

  /**
   * handler for the 'forward' and 'confirm' buttons
   */
  const _handleForwardPress = () => {
    setAccessibilityResponder(modalTitleRef);
    // skip questions whose dependencies are not
    let index = pageIndex;

    if (
      categories[categoryIndex].item[index - 1]?.fieldAnnotation?.includes(
        '[stop]',
      )
    ) {
      hideModalHandle();
    }

    while (index < categories[categoryIndex].item.length) {
      if (
        questionnaireAnalyzer.checkConditionsOfSingleItem(
          categories[categoryIndex].item[index],
          itemMap,
          registryDataVerlaufsbogenKind,
        ) &&
        !questionnaireAnalyzer.itemIsEmbedded(
          categories[categoryIndex].item[index],
          itemMap,
        )
      ) {
        dispatch(switchContent({ pageIndex: index + 1 }));
        return handleScrollTo({ y: 0, animated: false });
      }
      index += 1;
    }

    

        if (categories[categoryIndex].item[index - 1]?.fieldAnnotation?.includes('[continue')) {
            dispatch(setContinueWithCategoryIndex(3));
        }


        let submitSingle = categories[categoryIndex].item[index-1]?.fieldAnnotation?.includes('[submitsingle]');
    console.log(categories[categoryIndex].item);
    console.log(index - 1);
    console.log('submitting single...', submitSingle)
    console.log('submitting single...', categories[categoryIndex].item[index - 1])
    console.log('submitting single...', categories[categoryIndex].item[0])

    if (submitSingle) {
      console.log('submitting single2...');
      NetInfo.fetch().then((state) => {
        console.log('submitting single3...');
        if (state.isConnected) {
          console.log('submitting single4...');
          let instrument = categories[categoryIndex].instrument_name;

          console.log('submitting instrument...');

          console.log(JSON.stringify(itemMap));
          console.log('this should be the itemmap ');
          let response = exportService.createResponseJSON(
            itemMap,
            categories,
            metadata,
            instrument,
          );

          console.log('response instrument');
          console.log(response);

          dispatch(
            sendQuestionnaireResponse({
              body: response,
              instrument: instrument,
            }),
          );
        } else {
          alert(
            translate('generic').errorNoInternetTitle,
            translate('generic').errorNoInternet,
            [
              {
                text: translate('generic').ok,
              },
            ],
            { cancelable: false },
          );
        }
      });
    }

    // hideModalHandle();
  }
  const handleForwardPress = () => {
    let fieldAnnotation =
      categories[categoryIndex].item[pageIndex - 1]?.fieldAnnotation;
    let regex = /\[end\|(.+?)]/;
    let matches = fieldAnnotation.match(regex);

    if (matches) {
      let props = matches[1].split('|');

      let option = props[0];
      let title = props[1];
      let msg = props[2];

      let answer =
        itemMap[categories[categoryIndex].item[pageIndex - 1].linkId]
          .answer?.[0]?.valueCoding?.code;

      if (answer == option) {
        alert(
          title,
          msg,
          [
            {
              text: translate('generic').ok,
            },
          ],
          { cancelable: false },
        );
        return;
      }
    }

    regex = /\[docscan\|(.+?)]/;
    matches = fieldAnnotation.match(regex);

    if (matches) {
      let props = matches[1].split('|');

      let option = props[0];
      let answer =
        itemMap[categories[categoryIndex].item[pageIndex - 1].linkId]
          .answer?.[0]?.valueCoding?.code;

      if (answer == option) {
        if (Platform.OS === 'web') {
          // had to add this, because the navigation stack is behind the modal and otherwise the upload
          // functionality only appears once the modal is closed manually
          hideModalHandle();
        }
        nav.navigate(Routes.DOCUMENT);
        return;
      }
    }

    if (!completed && required) {
      let buttons = [
        {
          text: translate('accessibility').questionnaire.warningAnswerNow,
          style: 'cancel',
        },
      ];

      if (skippable) {
        let skipBtn = {
          text: translate('accessibility').questionnaire.warningSkip,
          style: 'default',
          onPress: () => {
            dispatch(_handleForwardPress);
          },
        };
        buttons.push(skipBtn);
      }

      alert(
        translate('accessibility').questionnaire.warningNotAnsweredTitle,
        skippable
          ? translate('accessibility').questionnaire.warningNotAnswered
          : translate('accessibility').questionnaire.requiredNotAnswered,
        buttons,
        { cancelable: false },
      );
    } else {
      _handleForwardPress();
    }
  };

  function extractCopyrightContent(str: string): string | null {
    const regex = /\[copyright=(.*?)\]/;
    const match = regex.exec(str);
    if (match) {
      return match[1];
    }
    return null;
  }

  /**
   * renders the content based on the currently chosen category
   */

  let copyrightNotice = extractCopyrightContent(
    categories?.[categoryIndex]?.item[pageIndex - 1]?.fieldAnnotation,
  );

  return (
    <RNModal
      avoidKeyboard={true}
      propagateSwipe
      coverScreen={true}
      // backdropOpacity={0.9}
      style={[
        localStyle.modal,
        {
          marginTop: isKeyboardVisible && Platform.OS === 'ios' ? 0 : 0,
        },
      ]}
      scrollTo={handleScrollTo}
      scrollOffset={scrollOffset}
      isVisible={modalVisible}
      onModalWillShow={() => {
        // nav?.setOptions({ headerShown: false });
      }}
      onModalWillHide={() => {
        // nav?.setOptions({ headerShown: true });
      }}
      testID="QuestionnaireModal"
    >
      {/* renders the content of the page */}
      {modalVisible && (
        <View style={{ height: '100%' }}>
          // TODO: check why I said this should be explicitly for web before
          {/* {Platform.OS === 'web' && ( */}
            <ConfirmDialog
              title="Fragebogen schließen"
              message="Möchten Sie den Fragebogen wirklich schließen? Sie können ihn zu einem späteren Zeitpunkt fortführen."
              visible={dialogCancelVisible}
              onTouchOutside={() => setDialogCancelVisible(false)}
              positiveButton={{
                title: 'Ja',
                onPress: () => {
                  setDialogCancelVisible(false);
                  hideModalHandle();
                },
              }}
              negativeButton={{
                title: 'Nein',
                onPress: () => setDialogCancelVisible(false),
              }}
            />
          {/* )} */}
          {/* {Platform.OS === 'web' && (
              <BinaryAlert
                title="Fragebogen schließen"
                message="Möchten Sie den Fragebogen wirklich schließen? Sie können ihn zu einem späteren Zeitpunkt fortführen."
                onPositivePress={() => {
                  setDialogCancelVisible(false);
                  hideModalHandle();
                }}
                onNegativePress={() => setDialogCancelVisible(false)}
              ></BinaryAlert>
            )} */}
          <SafeAreaView
            style={[
              localStyle.content,
              { height: Dimensions.get('window').height - 90 - keyboardHeight },
            ]}
          >
            <View style={localStyle.titleWrapper}>
              <Text
                style={localStyle.modalTitle}
                ref={modalTitleRef}
                accessibilityRole={translate('accessibility').types.header}
              >{`${categories[categoryIndex].text}`}</Text>

              <IconButton
                icon="close"
                iconColor={theme.colors.black}
                size={30}
                onPress={() => {
                  setDialogCancelVisible(true);
                }}
                containerColor={theme.colors.white}
                mode={'contained'}
              />
            </View>

            {appConfig.useProgressBar && (
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  marginBottom: 30,
                }}
              >
                <ProgressBar progress={progress()} />
              </View>
            )}

            <ScrollIndicatorWrapper
              rescroll={pageIndex}
              smallStepScroll={400}
              ref={scrollViewRef}
              availableHeight={
                Dimensions.get('window').height - 90 - keyboardHeight - 50
              }
              onScroll={handleOnScroll}
            >
              {/*<ScrollView*/}
              {/*    ref={scrollViewRef}*/}
              {/*    onScroll={handleOnScroll}*/}
              {/*    // scrollEventThrottle={16}*/}
              {/*>*/}
              <View style={{ paddingLeft: 10, paddingRight: 10 }}>
                {copyrightNotice && <Text>{copyrightNotice}</Text>}

                {categories?.[categoryIndex]?.item[pageIndex - 1]
                  ?.sectionHeader && (
                  <RenderHtml
                    contentWidth={width}
                    source={{
                      html:
                        '<div style="font-weight: bold; font-size: 1.2em">' +
                        markdownToHtml(
                          categories[categoryIndex].item[pageIndex - 1]
                            .sectionHeader,
                        ) +
                        '</div>',
                    }}
                  />
                )}
                <QuestionnaireItem
                  handleForwardPress={handleForwardPress}
                  item={categories?.[categoryIndex]?.item[pageIndex - 1]}
                  key={categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId}
                  testID={`QuestionnaireItem_${
                    categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId
                  }`}
                />
              </View>
              {/*</ScrollView>*/}
            </ScrollIndicatorWrapper>
          </SafeAreaView>

          {/* renders the bottom bar with the buttons to switch between
                questions*/}

          <BottomBar
            modalTitleRef={modalTitleRef}
            handleScrollTo={handleScrollTo}
            handleForwardPress={handleForwardPress}
            hideModal={hideModalHandle}
          />
        </View>
      )}
      {/* empty View in case the modal is hidden */}
      {!modalVisible && <View />}
    </RNModal>
  );
}

/***********************************************************************************************
 styles
 ***********************************************************************************************/

function markdownToHtml(markdown: string): string {
  const breakRegex = /\r?\n/g;
  markdown = markdown.replace(breakRegex, '<br />');

  // Replace boldface syntax with <strong> tags
  const boldRegex = /\*{2}(.+?)\*{2}/g;
  markdown = markdown.replace(boldRegex, '<strong>$1</strong>');

  // Replace italic syntax with <em> tags
  const italicRegex = /\/\/(.+?)\/\//g;
  markdown = markdown.replace(italicRegex, '<em>$1</em>');

  // Replace underlined syntax with <u> tags
  const underlineRegex = /_{2}(.+?)_{2}/g;
  markdown = markdown.replace(underlineRegex, '<u>$1</u>');

  return markdown;
}

const localStyle = StyleSheet.create({
  modal: {
    // justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    borderRadius: 10,
  },

  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
    alignItems: 'center',
    width: I18nManager.isRTL ? '100%' : 'auto',

    paddingTop: Platform.OS === 'ios' ? 0 : 10,
    paddingStart: 15,

    backgroundColor: theme.colors.white,
  },

  modalTitle: {
    ...theme.fonts.header2,
    fontSize: 24,
    paddingTop: 10,
    ...theme.fonts.header1,
    color: theme.values.defaultModalTitleColor,
  },

  content: {
    backgroundColor: theme.values.defaultModalContentBackgroundColor,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    ...(I18nManager.isRTL && {
      alignItems: 'flex-start',
    }),
  },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default QuestionnaireModal;
