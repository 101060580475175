import * as React from "react";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {theme} from "../../config";

const StartScreenButton = ({
  title,
  text,
  onPress = () => {},
  isCompleted = false,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.mainButton]}>
    <View style={{ paddingRight: 40 }}>
      <View style={{ width: '100%' }}>
        <Text
          style={{
            ...theme.fonts.title2,
            color: isCompleted ? '#888888' : theme.colors.primary,
            marginBottom: 8,
            marginTop: !!text ? 0 : 12,
          }}
        >
          {title}
        </Text>
        {!!text && (
          <Text
            style={{
              ...theme.fonts.body,
              paddingRight: isCompleted ? 20 : 0,
              color: isCompleted
                ? '#888888'
                : theme.values.defaultTitleTextColor,
            }}
          >
            {text}
          </Text>
        )}
      </View>
    </View>

    {!isCompleted && (
      <View
        style={{
          marginRight: 0,
          paddingLeft: 15,
          marginLeft: 'auto',
          alignContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Image
          resizeMode="contain"
          style={{ width: 20, height: 50, marginBottom: 30 }}
          source={require('../../assets/images/ausklappen.png')}
        />
      </View>
    )}

    {isCompleted && (
      <View
        style={{
          marginRight: 0,
          paddingLeft: 15,
          marginLeft: 'auto',
          alignContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Image
          resizeMode="contain"
          style={{ width: 40, height: 40, marginBottom: 30 }}
          source={require('../../assets/images/check.png')}
        />
      </View>
    )}
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  mainButton: {
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 20,

    paddingRight: 20,
    marginBottom: 15,
    flexDirection: 'row',
    borderRadius: 20,
    borderWidth: 0,
    backgroundColor: '#EDEDED',
    paddingTop: 30,
  },
  icon: {
    height: 50,
    width: 50,
  },
  icon_rotated: {
    transform: [{ rotate: '180deg' }],
  },
});

export default StartScreenButton;
