// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {Image, StyleSheet, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../../propTypes';

// custom components

// services & config
import {theme} from '../../config';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function TermsScreen({navigation}) {

    let {width} = useWindowDimensions();


    return (<View>
        <View style={localStyle.wrapper}>
            {/* content */}
            <Image source={require('src/assets/images/illu-allgemeine-infos-aatm.png')}
                   style={{width: 1170 / 2.5, height: 855 / 2.5, align: 'center', alignSelf: 'center'}}
                   resizeMode={"contain"}/>
            <RenderHtml contentWidth={width} source={{
                html:
                    '<h1>Nutzungsbedingungen</h1><p style="font-size: 120%">Durch die Installation und Nutzung dieser ' +
                    'App erklären Sie sich mit den vorliegenden Nutzungsbedingungen einverstanden. Wenn Sie mit den' +
                    ' Bedingungen nicht einverstanden sind, dürfen Sie die App nicht nutzen.</p> ' +
                    '<p style="font-size: 120%; font-weight: bold;">1. Begriffsbestimmungen</p>' +
                    '<p style="font-size: 120%;">Nutzer / Nutzerin: sind die Personen, die die App im Rahmen der Teilnahme an einem patientengeführtem' +
                    ' Register verwenden.' +
                    'Betreiber: Betreiber ist das Universitätsklinikum Bonn (AöR)</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">2. Zweck der App:</p>' +
                    '<p style="font-size: 120%;">Diese App dient der patientengeführten Datenpflege in einem medizinischen Register' +
                    ' („Alpha-1-KIDS-Register“, das Register für Kinder und Jugendliche mit Alpha-1-Antitrypsinmangel).' +
                    ' Näheres zum Register erfahren Sie in Ihrer Teilnahmeeinwilligung. Die Nutzung der App erfolgt ' +
                    'personalisiert. Eine Anmeldung ist nur mittels erteilten QR-Code möglich.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">3. Verantwortung des Nutzers und der Nutzerin:</p>' +
                    '<p style="font-size: 120%;">Die App darf nicht für rechtswidrige oder betrügerische Zwecke genutzt werden. ' +
                    'Die Inhalte dürfen nicht vervielfältigt, gedruckt oder an Dritte weitergegeben werden. Nutzer und Nutzerinnen' +
                    ' dürfen die App nur mittels des erteilten QR-Codes nutzen und Dritten keine Möglichkeit verschaffen, die App zu nutzen.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">4. Geistiges Eigentum:</p>' +
                    '<p style="font-size: 120%;">Alle Rechte an geistigem Eigentum in Bezug auf die App und deren Inhalte (einschließlich Texte,' +
                    ' Grafiken, Logos, Designs, usw.) liegen beim Betreiber oder seinen Lizenzgebern. Die App darf ' +
                    'nicht ohne ausdrückliche Zustimmung modifiziert oder anderweitig verwendet werden.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">5. Haftungsausschluss:</p>' +
                    '<p style="font-size: 120%; ">Die App wurde mit der größtmöglichen Sorgfalt vom Betreiber entwickelt' +
                    ' und getestet. Der Betreiber übernimmt keine Verantwortung für die Funktionalität, Vollständigkeit ' +
                    'oder Zuverlässigkeit der App. Die Nutzung der App erfolgt auf eigenes Risiko.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">6. Änderungen der Nutzungsbedingungen:</p>' +
                    '<p style="font-size: 120%;">Der Betreiber behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit für die Zukunft' +
                    ' zu ändern oder zu aktualisieren. Über derartige Änderungen werden die Nutzer und Nutzerinnen' +
                    ' mindestens 30 Tage vor dem geplanten Inkrafttreten der Änderung in Kenntnis gesetzt. Sofern' +
                    ' die Nutzung der App auch nach Ablauf der 30 Tage fortgesetzt wird, gelten die Änderungen als' +
                    ' wirksam vereinbart. Nutzer und Nutzerinnen, die mit den Änderungen der Nutzungsbedingungen nicht' +
                    ' einverstanden sind, können die App jederzeit löschen.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">7. Anwendbares Recht:</p>' +
                    '<p style="font-size: 120%;">Diese Nutzungsbedingungen unterliegen dem Recht der' +
                    ' Bundesrepublik Deutschland unter Ausschluss der Kollisionsnormen des deutschen internationalen' +
                    ' Privatrechts und sind nach diesem auszulegen.</p>' +
                    '<p style="font-size: 120%; font-weight: bold;">8. Schlussbestimmungen:</p>' +
                    '<p style="font-size: 120%; ">Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam ' +
                    'sein oder werden oder sollten sie den gesetzlichen Regelungen widersprechen, so wird hierdurch ' +
                    'die Wirksamkeit der Nutzungsbedingungen im Übrigen nicht berührt. Tritt sein solcher Fall ein, ' +
                    'wird die ungültige Bestimmung durch eine Bestimmung ersetzt, die dem wirtschaftlichen Zweck der' +
                    ' unwirksamen Bestimmung in rechtswirksamer Weise am nächsten kommt. Diese Herangehensweise gilt' +
                    ' auch für Lücken dieser Nutzungsbedingungen.</p>'

            }}/>

        </View>
    </View>);
}

TermsScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        width: 700,
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default TermsScreen;
