// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, { useState, useEffect } from 'react';
import {
  Alert,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// components
import { Picker } from '@react-native-picker/picker';
// import { ListItem } from 'react-native-elements';
import { ListItem } from '@rneui/themed';

// not supported by web
// find alternative for web -> done by hardcoding it
// TODO: find better solution
import VersionCheck from '../components/shared/VersionCheck';

// following imports not supported by web -> should work now
// custom components
import {
  AboutListItem,
  AboutListLink,
  RedirectModal,
} from '../components/about';
// not supported for web -> should be now
import { Banner, Spinner, ScrollIndicatorWrapper } from '../components/shared';

// redux actions
import { reset } from '../store/sharedActions';
import { updateLanguage } from '../store/user.slice';

// services & config
import { appConfig, theme } from '../config';
import kioskMode from '../config/kioskApiConfig';
import translate, {
  availableLanguages,
  getLanguageTag,
} from '../services/localization';
import { Routes, Stacks } from '../navigation/constants';

// TODO: find alternative for web if necessary
// import {captureScreen} from "react-native-view-shot";
import { Button, FAB, TextInput } from 'react-native-paper';
import { loggedInClient } from '../services/rest';

// find alternative of file system for web, but here not used
// import RNFS from "react-native-fs";

// TODO: find alternative for web
// import {showMessage} from "react-native-flash-message";

/***********************************************************************************************
 * component:
 * renders the about screen which contains information about the app as well as some links to
 * websites and the screen with legal information necessary in some regions
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 **********************************************************************************************/
function AboutScreen({ navigation }) {
  const dispatch = useDispatch();

  // internal state for the redirect modal
  const [modalState, setModalState] = useState({
    hidden: true,
    modalLink: null,
  });

  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  // const [path, setPath] = useState('');

  // get data from global state
  const started = useSelector((state) => state.Questionnaire.started);
  const subjectId = useSelector((state) => state.User.subjectId);
  const loading = useSelector((state) => state.Globals.loading);

  /**
   * shows a confirmation-dialog. if confirmed, it deletes the local data, logs the user
   * out and navigates back to the landing-screen.
   */
  const clearAll = () => {
    Alert.alert(
      translate('generic').warning,
      translate('generic').eraseAllWarning,
      [
        {
          text: translate('generic').delete,
          onPress: () => {
            dispatch(reset()).then(() => {
              navigation.replace(Stacks.SIGNED_OUT, {
                screen: Routes.STARTSCREEN,
              });
            });
          },
        },
        {
          text: translate('generic').abort,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  // handler for the 'change language' spinner/menu
  const changeLanguage = (languageTag) => {
    // in case some some questions of the current questionnaire have been answered,
    // warn the user that those answers will be lost when changing the language
    if (started) {
      // TODO: check if Alert.alert works for web, otherwise use custom (Binary) alert?
      Alert.alert(
        translate('generic').warning,
        translate('about').languageWarning +
          translate('about').languageWarningAddition,
        [
          {
            text: translate('generic').delete,
            onPress: () => {
              dispatch(updateLanguage({ languageTag, subjectId }));
            },
          },
          {
            text: translate('generic').abort,
            style: 'cancel',
          },
        ],
        { cancelable: false },
      );
    } else {
      dispatch(updateLanguage({ languageTag, subjectId }));
    }
  };

  // TODO: check that this works for web and native
  let version = VersionCheck.getCurrentVersion() + ", Build " + VersionCheck.getCurrentBuildNumber();

  return loading ? (
    <Spinner />
  ) : (
    <View style={localStyle.wrapper}>
      {subjectId && (
        <>
          <Modal
            visible={feedbackModalVisible}
            transparent={true}
            onRequestClose={() => {
              setFeedbackModalVisible(false);
            }}
          >
            <View
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                padding: 30,
              }}
            >
              <Text style={localStyle.title}>Haben Sie Feedback an uns?</Text>
              <Text style={localStyle.subTitle}>
                Hier können Sie uns Ihr Feedback mitteilen:
              </Text>

              <TextInput
                contentStyle={{ backgroundColor: '#EBEBEB' }}
                outlineStyle={{ borderColor: 'black' }}
                underlineStyle={{ borderColor: 'black' }}
                outlineColor={'black'}
                activeOutlineColor={'black'}
                activeUnderlineColor={'black'}
                underlineColor={'black'}
                multiline={true}
                value={feedbackText}
                label={'Hier Feedback eingeben'}
                height={200}
                numberOfLines={10}
                onChangeText={(text) => setFeedbackText(text)}
                style={{ marginTop: 20 }}
              ></TextInput>

              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 50,
                }}
              >
                <TouchableOpacity
                  style={[localStyle.buttonOutlined, { width: '50%' }]}
                  onPress={() => {
                    setFeedbackModalVisible(false);
                  }}
                >
                  <Text style={localStyle.buttonLabelOutlined}>Abbrechen</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[localStyle.button, { width: '50%' }]}
                  onPress={() => {
                    setFeedbackModalVisible(false);
                    loggedInClient
                      .importRecord(
                        subjectId,
                        'feedback',
                        '<feedback_text>' + feedbackText + '</feedback_text>',
                      )
                      .then(() => {
                        showMessage({
                          message: 'Feedback erfolgreich abgeschickt.',
                          position: 'bottom',
                          type: 'success',
                        });
                      });
                  }}
                >
                  <Text style={localStyle.buttonLabel}>Senden</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </>
      )}

      {/* top banner */}
      <Banner nav={navigation} title={translate('about').title} noMenu />

      {/* the modal to be opened */}
      <RedirectModal
        showModal={!modalState.hidden}
        modalLink={modalState.modalLink}
        hideModal={() => {
          setModalState({ hidden: true, modalLink: null });
        }}
      />

      {/* ScrollView with content */}
      <View style={localStyle.wrapper}>
        <ScrollIndicatorWrapper>
          <View style={localStyle.wrapper}>
            {/* holds the list-items */}
            <View style={localStyle.wrapper}>
              {/* links to the LegalInformationScreen */}
              {appConfig.allowAccessToLegalInformationScreen && (
                <ListItem
                  containerStyle={localStyle.containerStyle}
                  onPress={() => navigation.navigate(Routes.LEGAL_INFORMATION)}
                >
                  {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                  <ListItem.Content>
                    <ListItem.Title style={localStyle.title}>
                      {translate('about').legal.title}
                    </ListItem.Title>

                    <ListItem.Subtitle style={localStyle.subTitle}>
                      {translate('about').legal.subTitle}
                    </ListItem.Subtitle>
                  </ListItem.Content>

                  {/* the icon on the right-hand-side */}
                  <ListItem.Chevron
                    {...{
                      type: translate('about').legal.iconType,
                      name: translate('about').legal.iconTitle,
                      color: theme.values.legalListLinkIconColor,
                      reverse: true,
                      size: 12,
                    }}
                  />
                </ListItem>
              )}

              {/* iterates over all items in translate('webViews') */}
              {translate('webViews').map((webView) => {
                if (webView.settings) {
                  return (
                    // navigates to the webview screen
                    <AboutListLink
                      key={webView.title}
                      navigation={navigation}
                      webView={webView}
                    />
                  );
                }
              })}

              {/* iterates over all items in translate('modalLinks') */}
              {translate('modalLinks').map((modalLink) => (
                // navigates to the webview screen
                <AboutListItem
                  showModal={() => setModalState({ hidden: false, modalLink })}
                  key={modalLink.title}
                  modalLink={modalLink}
                />
              ))}
              <ListItem containerStyle={localStyle.containerStyle}>
                {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                <ListItem.Content>
                  <ListItem.Title style={localStyle.title}>
                    {'Version'}
                  </ListItem.Title>
                  <ListItem.Subtitle style={localStyle.subTitle}>
                    {version}
                  </ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            </View>

            {/* optional buttons on the bottom of the screen - JUST FOR DEVELOPMENT*/}
            <View style={localStyle.bottom}>
              {/*/!* language picker *!/*/}
              {/*<View style={localStyle.languagePickerWrapper}>*/}
              {/*  <Text style={localStyle.title}>*/}
              {/*    {translate('about').languageSelection}*/}
              {/*  </Text>*/}

              {/*  {started && (*/}
              {/*    <Text style={localStyle.warningSubTitle}>*/}
              {/*      {translate('about').languageWarning}*/}
              {/*    </Text>*/}
              {/*  )}*/}

              {/*  <Picker*/}
              {/*    testID="languagePicker"*/}
              {/*    style={localStyle.picker}*/}
              {/*    mode="dropdown"*/}
              {/*    selectedValue={getLanguageTag()}*/}
              {/*    onValueChange={(itemValue) => {*/}
              {/*      if (getLanguageTag() !== itemValue) {*/}
              {/*        changeLanguage(itemValue);*/}
              {/*      }*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {Object.keys(availableLanguages).map((key) => (*/}
              {/*      <Picker.Item*/}
              {/*        key={key}*/}
              {/*        label={availableLanguages[key].title}*/}
              {/*        value={key}*/}
              {/*      />*/}
              {/*    ))}*/}
              {/*  </Picker>*/}
              {/*</View>*/}

              {/* delete-all-data button */}
              {/*{(appConfig.showEraseAll || kioskMode.active) && (*/}
              {subjectId && <TouchableOpacity
                style={localStyle.buttonAlert}
                onPress={clearAll}
                accessibilityLabel={translate('about').delete}
                accessibilityRole={translate('accessibility').types.button}
                accessibilityHint={translate('accessibility').logoutHint}
              >
                <Text style={[localStyle.buttonLabel, { color: theme.classes.buttonAlert.textColor }]}>
                  {translate('about').delete}
                </Text>
              </TouchableOpacity>}
              {/*)}*/}

              <Text style={{ marginTop: 10, textAlign: 'center' }}>
                App-Entwicklung: Institut für Digitale Medizin,
                Universitätsklinikum Bonn (UKB).{'\n'}
                &copy; 2023. Alle Rechte vorbehalten.
              </Text>
            </View>
          </View>
        </ScrollIndicatorWrapper>
      </View>
    </View>
  );
}

AboutScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.accent0,
  },

  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 36,
    height: '100%',
    marginTop: 5,
    width: '100%',
    padding: 15,
  },

  button: {
    ...theme.classes.buttonPrimary,
    bottom: 0,
    marginTop: 10,
    marginRight: 10,
  },

  buttonOutlined: {
    ...theme.classes.buttonPrimaryOutlined,
    bottom: 0,
    marginTop: 10,
    marginRight: 10,
  },

  buttonAlert: {
    ...theme.classes.buttonAlert,
    bottom: 0,
    marginTop: 20,
  },

  buttonLabel: {
    ...theme.classes.buttonLabel,
    color: theme.colors.white,
  },
  buttonLabelOutlined: {
    ...theme.classes.buttonLabel,
    color: theme.colors.primary,
  },

  containerStyle: {
    width: '100%',
    borderBottomColor: theme.colors.accent3,
    borderBottomWidth: 1,
    backgroundColor: theme.values.defaultListLinkBackgroundColor,
    padding: 15,
  },

  subTitle: {
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  warningSubTitle: {
    color: theme.colors.alert,
    ...theme.fonts.body,
  },

  title: {
    ...theme.fonts.title2,
    marginBottom: 12,
  },

  titleText: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
    ...theme.fonts.header2,
  },

  languagePickerWrapper: {
    borderWidth: 3,
    borderColor: theme.colors.white,
    borderRadius: 4,
    padding: 10,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default AboutScreen;
