import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
  Platform,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';
import GLOBAL from '../config/globals';

// components
import { Banner, ScrollIndicatorWrapper, Spinner } from '../components/shared';
import QuestionnaireModal from '../components/questionnaireModal/questionnaireModal';
import CategoriesList from '../components/survey/categoriesList';

// services
import translate, { getLanguageTag } from '../services/localization';
import { appConfig, theme } from '../config';
import exportService from '../services/questionnaireAnalyzer';

// redux actions
import {
  fetchQuestionnaire,
  switchContent,
} from '../store/questionnaire.slice';
import { sendQuestionnaireResponse } from '../store/sharedActions';

import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import endpoints from '../services/rest/endpoints';
import DashboardButton from '../components/ui/DashboardButton';
import StartScreenButton from '../components/ui/StartScreenButton';
import { formatDateString } from '../services/utils';
// TODO: check if RenderHtml works for web
import RenderHtml from 'react-native-render-html';
import moment from 'moment';
import AppLock from '../components/security/AppLock';
import questionnaireAnalyzer from '../services/questionnaireAnalyzer';
// import { set } from 'core-js/core/dict';

/***********************************************************************************************
 * renders the survey-screen with the list of all categories, the modal to answer the questionnaire
 * and a button to send the response to the server
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function OverviewSurveyScreen({ navigation }) {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.Globals);
  const { current_questionnaire_id, subjectId, start_date } = useSelector(
    (state) => state.User,
  );

  const questionnaireItemMap = useSelector(
    (state) => state.Questionnaire.itemMap,
  );

  const { categories, itemMap } = useSelector((state) => state.Questionnaire);

  const ConditionalScrollIndicatorWrapper = ({ children }) => {
    if (Platform.OS === 'native') {
      return <ScrollIndicatorWrapper>{children}</ScrollIndicatorWrapper>;
    } else {
      return <>{children}</>;
    }
  };

  let linkIds = Object.keys(itemMap);

  NetInfo.configure({
    reachabilityUrl: endpoints.ping,
    reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 3 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: true,
  });
  const netInfo = useNetInfo();

  const { registryDataVerlaufsbogenKind } = useSelector(
    (state) => state.RegistryData,
  );

  const { width } = useWindowDimensions();

  const { isBiometryAuthenticated } = useSelector((state) => state.Globals);
  const { useAppLock } = useSelector((state) => state.User);
  useEffect(() => {
    setAppLock(useAppLock);
  }, [useAppLock]);
  useEffect(() => {
    // the const isAuthenticated is only relevant for native application
    if (Platform.OS === 'native') {
      setIsAuthenticated(isBiometryAuthenticated);
    }
  }, [isBiometryAuthenticated]);
  const [appLock, setAppLock] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (Platform.OS === 'native' && !isAuthenticated && appLock) {
    return <AppLock />;
  }

  const outputItem = (itemSet, item, i) => {
    // FIXME needs adaptation
    if (
      // !questionnaireAnalyzer.checkConditionsOfSingleItem(
      //     item,
      //     questionnaireItemMap,
      // )
      // ||
      questionnaireAnalyzer.itemIsEmbedded(item, questionnaireItemMap)
    ) {
      return <></>;
    }

    let answer = undefined;
    let foundAnswers = itemSet[item.origCode];

    console.log('HEREWEARE', item.origCode, item.type);

    if (item.type == 'display') {
      answer = '';
      if (item.fieldAnnotation.includes('[displaysummaryanswer|')) {
        answer = item.fieldAnnotation;
        const summaryTextRegex = /\[displaysummaryanswer\|(.+?)\]/g;
        let matches = answer.matchAll(summaryTextRegex);
        for (let captureGroups of matches) {
          answer = captureGroups[1];
        }
      }

      const fieldEmbeddingRegex = /\{(.+?)\}/g;
      let matches = answer.matchAll(fieldEmbeddingRegex);

      if (matches) {
        for (let captureGroups of matches) {
          console.log('captureGroupsB ', captureGroups, answer);
          let embeddedFieldName = captureGroups[1];
          for (let linkId of linkIds) {
            let origCode = itemMap[linkId]?.origCode;
            if (!!origCode && origCode === embeddedFieldName) {
              let show = questionnaireAnalyzer.checkConditionsOfSingleItem(
                itemMap[linkId],
                itemMap,
              );
              // let show  = true;

              if (itemMap[linkId].type == 'string') {
                answer = answer.replace(
                  captureGroups[0],
                  show ? itemSet[itemMap[linkId].origCode] : '',
                );
              } else if (itemMap[linkId].type == 'email') {
                answer = answer.replace(
                  captureGroups[0],
                  show ? itemSet[itemMap[linkId].origCode] : '',
                );
              } else if (itemMap[linkId].type == 'date') {
                if (itemMap[linkId].fieldAnnotation.includes('[mmyyyy')) {
                  answer = answer.replace(
                    captureGroups[0],
                    show
                      ? moment(itemSet[itemMap[linkId].origCode])
                          .locale('de')
                          .format('MM / YYYY')
                      : '',
                  );
                } else {
                  answer = answer.replace(
                    captureGroups[0],
                    show
                      ? moment(itemSet[itemMap[linkId].origCode])
                          .locale('de')
                          .format('DD.MM.YYYY')
                      : '',
                  );
                }
              } else if (itemMap[linkId].type == 'choice') {
                let answerOptionValueCodingMap = {};
                itemMap[linkId].answerOption.map((answerOption, index) => {
                  answerOptionValueCodingMap[answerOption.valueCoding.code] =
                    answerOption.valueCoding.display;
                });

                if (itemMap[linkId].repeats) {
                  let choiceAnswer = itemSet[linkId]?.answer?.length
                    ? '- '
                    : '';
                  itemMap[linkId].answer?.map((ans, i) => {
                    if (choiceAnswer != '- ') {
                      choiceAnswer += '<br />- ';
                    }
                    choiceAnswer += ans?.valueCoding.display;
                  });
                  answer = answer.replace(
                    captureGroups[0],
                    show ? choiceAnswer : '',
                  );
                } else {
                  answer = answer.replace(
                    captureGroups[0],
                    show
                      ? answerOptionValueCodingMap[
                          itemSet[itemMap[linkId].origCode]
                        ]
                      : '',
                  );
                }
              } else if (itemMap[linkId].type == 'integer') {
                answer = answer.replace(
                  captureGroups[0],
                  show ? itemSet[itemMap[linkId].origCode] : '',
                );
              } else if (itemMap[linkId].type == 'decimal') {
                answer = answer.replace(
                  captureGroups[0],
                  show ? itemSet[itemMap[linkId].origCode] : '',
                );
              }
              break;
            }
          }
        }
        if (answer === '') {
          return <></>;
        }
      } else {
        return <></>;
      }
    } else if (item.fieldAnnotation.includes('[slider|')) {
      answer = foundAnswers;
    } else if (item.type == 'string') {
      answer = foundAnswers;
    } else if (item.type == 'email') {
      answer = foundAnswers;
    } else if (item.type == 'date') {
      if (item.fieldAnnotation.includes('[mmyyyy')) {
        answer = moment(foundAnswers).locale('de').format('MM / YYYY');
      } else {
        answer = moment(foundAnswers).locale('de').format('DD.MM.YYYY');
      }
    } else if (item.type == 'choice') {
      let answerOptionValueCodingMap = {};
      item.answerOption.map((answerOption, index) => {
        answerOptionValueCodingMap[answerOption.valueCoding.code] =
          answerOption.valueCoding.display;
      });

      if (item.repeats) {
        answer = '- ';
        Object.keys(answerOptionValueCodingMap).forEach((code) => {
          if (itemSet[item.origCode + '___' + code] === '1') {
            if (answer != '- ') {
              answer += '<br />- ';
            }
            answer += answerOptionValueCodingMap[code];
          }
        });
      } else {
        answer = answerOptionValueCodingMap[foundAnswers];
      }
    } else if (item.type == 'integer') {
      answer = foundAnswers;
    } else if (item.type == 'decimal') {
      answer = foundAnswers;
    }

    if (item.fieldAnnotation?.includes('[multiinput')) {
      answer = answer?.split('~ ').join('<br />');
    }

    let questiontext = item.text;
    if (item.fieldAnnotation.includes('[displaysummarytext|')) {
      questiontext = item.fieldAnnotation;
      const summaryTextRegex = /\[displaysummarytext\|(.+?)\]/g;
      let matches = questiontext.matchAll(summaryTextRegex);
      for (let captureGroups of matches) {
        questiontext = captureGroups[1];
      }
    }

    return answer ? (
      <View style={{ marginBottom: 10, marginLeft: 40, marginRight: 40 }}>
        <RenderHtml
          contentWidth={width}
          source={{
            html:
              '<div style="background: #efefef; padding: 10px "><div style="font-weight: bold; font-size: 1.2em; ">' +
              questiontext +
              '</div> ' +
              '<div style="font-weight: normal; font-size: 1.2em; color: ' +
              theme.colors.primary +
              '">' +
              (answer !== undefined ? answer : '(keine Angabe)') +
              '</div></div>',
          }}
        />
      </View>
    ) : (
      <></>
    );
  };

  const [showErstmeldebogen, setShowErstmeldebogen] = useState(false);

  const scrollViewRef = useRef();
  // setting defaults
  let scrollOffset = 0;
  const handleOnScroll = (event) => {
    // just sets the current scrollOffset
    scrollOffset = event.nativeEvent.contentOffset.y;
  };
  const handleScrollTo = (element) => {
    // scrolls to the given element if the scrollView is currently active
    scrollViewRef?.current?.scrollTo({ ...element, animated: true });
  };

  return loading ? (
    <Spinner />
  ) : (
    <View
      style={[localStyle.flexi, localStyle.wrapper]}
      testID="OverviewSurveyScreen"
    >
      {/* render the top banner */}
      <Banner nav={navigation} title={translate('survey').title} />

      <ConditionalScrollIndicatorWrapper onScroll={handleOnScroll} ref={scrollViewRef}>
        <View style={{ marginTop: 20 }}>
          <StartScreenButton
            onPress={() => {
              setShowErstmeldebogen(!showErstmeldebogen);
              handleScrollTo({ y: 0, animated: false });
            }}
            title={'Erstmeldebogen'}
            text={
              'Eingetragen am ' +
              formatDateString(
                new Date(
                  GLOBAL.currentKind.kind_datum_eintragung.substring(0, 10),
                ),
                { locale: getLanguageTag() },
              )
            }
          ></StartScreenButton>
        </View>

        {showErstmeldebogen &&
          categories[1].item.map((it, i) => {
            return outputItem(GLOBAL.currentKind, it, i);
          })}

        {registryDataVerlaufsbogenKind.map((verlaufsbogen, i) => {
          if (verlaufsbogen.ver_kind_id !== GLOBAL.currentKind.kind_kind_id) {
            return <></>;
          }

          return (
            <>
              <StartScreenButton
                title={'Verlaufsbogen'}
                text={
                  'Eingetragen am ' +
                  formatDateString(
                    new Date(
                      verlaufsbogen.ver_datum_eintragung.substring(0, 10),
                    ),
                    { locale: getLanguageTag() },
                  )
                }
              ></StartScreenButton>
              {categories[2].item.map((it, i) => {
                return outputItem(verlaufsbogen, it, i);
              })}
            </>
          );
        })}
      </ConditionalScrollIndicatorWrapper>
    </View>
  );
}

OverviewSurveyScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
  dashModal: {
    // justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    borderRadius: 10,
    padding: 20,
    backgroundColor: theme.values.defaultBackgroundColor,
  },
  dashModalIcon: {
    height: 60,
    width: 60,
  },

  buttonDash: {
    marginTop: appConfig.scaleUiFkt(5),
    marginStart: appConfig.scaleUiFkt(30),
    marginEnd: appConfig.scaleUiFkt(30),
  },
  buttonSubmitDash: {
    height: appConfig.scaleUiFkt(60),
    marginBottom: appConfig.scaleUiFkt(10),
  },
  buttonSubmitTextDash: {
    fontSize: appConfig.scaleUiFkt(20),
    color: '#ffffff',
    textAlignVertical: 'center',
  },

  wrapper: {
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },

  bottom: {
    alignSelf: 'flex-end',
    justifyContent: 'center',
    flex: 1 / 6,
    width: '100%',
  },

  button: {
    ...theme.classes.buttonPrimary,
    bottom: 0,
    width: '80%',
    textAlign: 'center',
  },

  buttonComplete: {
    backgroundColor: theme.values.defaultSendQuestionnaireButtonBackgroundColor,
  },

  buttonAlert: {
    backgroundColor: theme.colors.alert,
  },

  buttonLabel: {
    ...theme.classes.buttonLabel,
  },

  infoText: {
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  familyTitle: {
    ...theme.fonts.title2,
    marginLeft: 20,
    marginTop: 30,
    color: theme.values.defaultTitleTextColor,
  },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default OverviewSurveyScreen;
