import React, { useEffect, useState } from 'react';
// TODO: check if Linking works -> might cause errors
// TODO: Alert.alert() not supported for web, use alert() instead, but not as good
import { Alert, Linking, StyleSheet, View, Platform, Modal, TouchableOpacity, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

// find alternative for version check on web -> right now hardcoded for web
import VersionCheck from '../components/shared/VersionCheck';

import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// custom components
import { Banner, ScrollIndicatorWrapper, Spinner } from '../components/shared';
import { CheckInListView, CheckInWelcomeText } from '../components/checkIn';

// redux actions
import { getLanguages } from '../store/globals.slice';

// on web updateFCMToken might not be supported
import { updateUser } from '../store/user.slice';
import { deleteQuestionnaire } from '../store/questionnaire.slice';
import {
  sendQuestionnaireResponse,
  sendReport,
  reset,
} from '../store/sharedActions';

// services & config
import translate from '../services/localization';
import { appConfig, theme } from '../config';
import exportService from '../services/questionnaireAnalyzer';
import i18n from 'i18n-js';
import endpoints from '../services/rest/endpoints';
import { Routes, Stacks } from '../navigation/constants';

import {
  fetchRegistryErstmeldebogenEltern,
  fetchRegistryErstmeldebogenKind,
  fetchRegistryVerlaufsbogenKind,
} from '../store/registry.slice';

// next import throws error in web -> should work now
import CheckInTiles from '../components/checkIn/checkInTiles';
import StartScreenButton from '../components/ui/StartScreenButton';

import localStorage from '../services/localStorage';

/***********************************************************************************************
 * component
 * renders the checkIn-screen, which is basically the core of the app
 * displays information about the current or next questionnaire
 * contains a banner element to navigate to the survey screen
 * as well buttons to send out the questionnaire or a special report
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/

function CheckInScreen({ navigation }) {
  const dispatch = useDispatch();
  let logoutTimeout;
  let alertTimeout;
  let countdownInterval;

  const [showAlert, setShowAlert] = useState(false);
  const [countdown, setCountdown] = useState(30);

  // logs out user after 5 minutes of inactivity
  useEffect(() => {
  // events indicating user activity and reseting timeout
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    const resetTimeout = () => {
      clearTimeout(logoutTimeout);
      clearTimeout(alertTimeout);
      clearInterval(countdownInterval);

      logoutTimeout = setTimeout(() => {
        setShowAlert(false);
        dispatch(reset()).then(() => {
          navigation.replace(Stacks.SIGNED_OUT, {
            screen: Routes.STARTSCREEN,
          });
        });
      }, 300000); // 3000 ms = 5 minutes (100 = 1ms)

      alertTimeout = setTimeout(() => {
        setShowAlert(true);
        setCountdown(30);
        countdownInterval = setInterval(() => {
          setCountdown((countdown) => countdown - 1);
        }, 1000);
      }, 270000); // 2700 ms = 4.5 minutes
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(countdownInterval);
    }
  }, [countdown]);

  // get data from global state
  const { error, loading } = useSelector((state) => state.Globals);

  const {
    status,
    subjectId,
    certificate,
    firstTime,
    due_date,
    start_date,
    current_questionnaire_id,
    additional_iterations_left,
  } = useSelector((state) => state.User);

  const {
    itemMap,
    categories,
retrieveDataCycle,
    FHIRmetadata: metadata,
  } = useSelector((state) => state.Questionnaire);

  // trigger user update when app is opened and user was logged in
  useEffect(() => {
    if (subjectId) {
      dispatch(updateUser(subjectId));
      // is not supported on web
      // if (appConfig.connectToFCM) {
      //   dispatch(updateFCMToken(subjectId));
      // }
      dispatch(getLanguages());
    }
  }, [dispatch, subjectId]);

  useEffect(() => {
    console.log('dispatch erstmeldebogen eltern');
    dispatch(
      fetchRegistryErstmeldebogenEltern({
        subjectId,
      }));
  }, [retrieveDataCycle]);
  useEffect(() => {
    console.log('dispatch erstmeldebogen kind');
    dispatch(
      fetchRegistryErstmeldebogenKind({
        subjectId,
      }));
    }, [retrieveDataCycle]);
    useEffect(() => {
        console.log('dispatch verlaufsbogen kind')
        dispatch(fetchRegistryVerlaufsbogenKind({
            subjectId,
        }));
    }, [retrieveDataCycle]);

  // check if the currently persisted questionnaire is outdated
  // if so, alert user and delete data
  useEffect(() => {
    if (
      !!metadata &&
      current_questionnaire_id !== `${metadata.url}|${metadata.version}`
    ) {
      Alert.alert(
        translate('generic').info,
        translate('generic').infoRemoval,
        [
          {
            text: translate('generic').ok,
            onPress: () => dispatch(deleteQuestionnaire()),
          },
        ],
        { cancelable: false },
      );
    }
  }, [current_questionnaire_id, metadata, dispatch]);

  const noNewQuestionnaireAvailableYet = new Date() < new Date(start_date);
  const categoriesLoaded = categories && categories.length > 0;

  // check if at least one category of the questionnaire has been started
  const started = categories?.some(
    (category) => itemMap[category.linkId].started,
  );
  // check if all categories of the current questionnaire have been completed
  const done = categories?.every((category) => itemMap[category.linkId].done);

  // #################### event & button handlers ####################

  /**
   * handle submission of questionnaire response
   */
  const handleSubmit = () => {
    Alert.alert(
      translate('generic').info,
      translate('survey').sendFinishedMessage,
      [
        {
          text: translate('survey').sendFinished,
          onPress: () =>
            dispatch(
              sendQuestionnaireResponse({
                body: exportService.createResponseJSON(
                  itemMap,
                  categories,
                  metadata,
                ),
              }),
            ),
        },
        {
          text: translate('generic').abort,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  /**
   * handle submission of special report
   */
  const handleReport = () => {
    if (subjectId && additional_iterations_left) {
      // shows a dialog telling the user that he/she already send out a report
      Alert.alert(
        translate('generic').info,
        translate('generic').reportWhileInIteratedMode,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
    } else if (!noNewQuestionnaireAvailableYet) {
      // dialog telling the user to use the current questionnaire
      Alert.alert(
        translate('generic').info,
        translate('generic').reportWhileQuestionnaire,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
    } else {
      Alert.alert(
        translate('reporting').symptoms_header,
        translate('reporting').symptoms_question,
        [
          {
            text: translate('reporting').symptoms_yes,
            onPress: () => {
              // send out the report
              dispatch(sendReport({ subjectId, certificate }));
            },
          },
          {
            text: translate('reporting').symptoms_no,
            style: 'cancel',
          },
        ],
        { cancelable: false },
      );
    }
  };

  // TODO: check if this adaption works in native and web
  {
    Platform.OS === 'native' &&
      useEffect(() => {
        let country = '';
        VersionCheck.getCountry().then((c) => (country = c));
        const body = {
          country: country,
          lang: i18n.locale,
          package: VersionCheck.getPackageName(),
          build: VersionCheck.getCurrentBuildNumber(),
          version: VersionCheck.getCurrentVersion(),
        };
        console.log(body);

        VersionCheck.getLatestVersion({
          forceUpdate: true,
          provider: () =>
            fetch(endpoints.checkVersion, {
              method: 'POST',
              body: JSON.stringify(body),
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then(
                (r) => r.json(),
                function (error) {
                  console.log('ERROR: ', error.message);
                },
              )
              .then((a) => {
                console.log(a);
                return a;
              }),
        }).then((updateResponse) => {
          if (updateResponse.updateAvailable) {
            let buttons = [];
            if (!updateResponse.forceUpdate) {
              buttons.push({
                text: translate('generic').abort,
                style: 'cancel',
              });
            }
            buttons.push([
              {
                text: translate('generic').ok,
                onPress: () => {
                  // FIXME Does not work for some reason?
                  console.log(updateResponse);
                  if (updateResponse.infoURL) {
                    console.log(updateResponse.infoURL);
                    Linking.openURL(updateResponse.infoURL);
                  }
                },
              },
            ]);

            Alert.alert(
              translate('generic').info,
              updateResponse.infoText,
              buttons,
              { cancelable: false },
            );
          }
        });
      }, []);
  }

  return loading ? (
    <Spinner />
  ) : (
    <View style={localStyle.wrapper} testID="CheckInScreen">
      {/* banner at the top */}
      <Banner
        nav={navigation}
        title={''}
        subTitle={translate('survey').subTitleCheckIn}
        updateUser={() => dispatch(updateUser(subjectId))}
        isCheckIn
        noWayBack
        noRefresh={status === 'off-study'}
      />
      {/* Modal Alert to tell user about automatic logout */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={showAlert}
        onRequestClose={() => {
          setShowAlert(false);
        }}
      >
        <View style={localStyle.content}>
          <View style={localStyle.modal}>
            <Text style={localStyle.modalText}>
              You will be logged out due to inactivity in {countdown} seconds.
            </Text>
            <TouchableOpacity
              style={localStyle.button, {backgroundColor: '#2196F3'}}
              onPress={() => {
                setShowAlert(false);
              }}
            >
              <Text style={localStyle.modalText}>Continue session</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/*  center content */}
      {subjectId && (
        <View style={[localStyle.flexi, localStyle.wrapper]}>
          <ScrollIndicatorWrapper>
            <View style={localStyle.wrapper}>
              {/* welcome text with due-date information */}
              <CheckInWelcomeText
                error={error}
                status={status}
                noNewQuestionnaireAvailableYet={noNewQuestionnaireAvailableYet}
                firstTime={firstTime}
                dueDate={due_date}
                startDate={start_date}
                categoriesLoaded={categoriesLoaded}
              />

              {/* renders the button at the bottom */}
              <CheckInTiles
                done={done}
                status={status}
                iterationsLeft={additional_iterations_left}
                categoriesLoaded={categoriesLoaded}
                noNewQuestionnaireAvailableYet={noNewQuestionnaireAvailableYet}
                sendReport={handleReport}
                deleteLocalDataAndLogout={() => {
                  /* TODO */
                }}
                exportAndUploadQuestionnaireResponse={handleSubmit}
              />

              {/* renders the listview item representing the questionnaire */}
              {!noNewQuestionnaireAvailableYet && status !== 'off-study' && (
                <View>
                  <StartScreenButton
                    onPress={() => navigation.navigate(Routes.SURVEY)}
                    title={"Weiter zur\nFamilienübersicht"}
                  />
                </View>
              )}

              <View style={localStyle.buttonRow}></View>

              {/*<CheckInTiles*/}
              {/*  done={done}*/}
              {/*  status={status}*/}
              {/*  iterationsLeft={additional_iterations_left}*/}
              {/*  categoriesLoaded={categoriesLoaded}*/}
              {/*  noNewQuestionnaireAvailableYet={noNewQuestionnaireAvailableYet}*/}
              {/*  sendReport={handleReport}*/}
              {/*  deleteLocalDataAndLogout={() => {*/}
              {/*    TODO */}
              {/*  }}*/}
              {/*  exportAndUploadQuestionnaireResponse={handleSubmit}*/}
              {/*/>*/}
            </View>
          </ScrollIndicatorWrapper>
        </View>
      )}
    </View>
  );
}

CheckInScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
  modal: {
    // justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    borderRadius: theme.values.defaultModalBorderRadius,
    borderColor: theme.colors.white,
    marginTop: 80,
  },
  wrapper: {
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },

  mainButton: {
    height: 160,
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 20,
    elevation: 10,
    shadowColor: '#52006A',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
  },

  icon: {
    marginTop: 5,
    height: 30,
    width: 30,
    marginBottom: 8,
  },

  buttonText: {
    fontFamily: 'roboto-500',
    color: '#121212',
    fontSize: 25,
    marginTop: 5,
  },

  button: {
    marginTop: appConfig.scaleUiFkt(5),
    marginStart: appConfig.scaleUiFkt(30),
    marginEnd: appConfig.scaleUiFkt(30),
  },
  buttonSubmit: {
    height: appConfig.scaleUiFkt(60),
    marginBottom: appConfig.scaleUiFkt(10),
  },
  buttonSubmitText: {
    fontSize: appConfig.scaleUiFkt(20),
    height: '100%',
    marginTop: appConfig.scaleUiFkt(15),
    textAlignVertical: 'center',
  },
  modalText: {
    ...theme.fonts.body,
    color: theme.values.defaultModalContentTextColor,
  },
  content: {
    backgroundColor: theme.values.defaultModalContentBackgroundColor,
    paddingLeft: 20,
    paddingRight: 20,
    height: 'auto',
    maxHeight: '90%',
    borderTopLeftRadius: theme.values.defaultModalBorderRadius,
    borderTopRightRadius: theme.values.defaultModalBorderRadius,
    borderColor: theme.colors.white,
  },
});

export default CheckInScreen;
