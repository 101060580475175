import React, { useState, useEffect, useRef } from 'react';
import {
  I18nManager,
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';

import debounce from 'lodash.debounce';

// components
// import {CheckBox, Input} from 'react-native-elements';
import { CheckBox, Input } from '@rneui/themed';

// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import translate from '../../../services/localization';
import validator from 'validator';

// shared styles
import SharedStyles, { calculateIndent } from './sharedStyles';
import RenderHtml from 'react-native-render-html';
import ModalInfo from '../../../components/questionnaireModal/modalInfo';
import { stripTags } from '../../../services/utils';
import { Picker } from '@react-native-picker/picker';
import { theme } from '../../../config';
import { TextInput } from 'react-native-paper';
/**
 * is used to determine what kind of keyboard should be used
 * @param  {QuestionnaireItem} item a questionnaire item (from props.categories)
 */
const getKeyboardType = (item) => {
  if (item.fieldAnnotation?.includes('[phone')) {
    return 'phone-pad';
  }
  switch (item.type) {
    // numpad for integers
    case 'integer':
      return 'number-pad';
    // decimalPad for decimals
    case 'decimal':
      return 'decimal-pad';
    case 'email':
      return 'email-address';
    // and the rest
    case 'phone':
      return 'phone-pad';
    default:
      return 'default';
  }
};

/**
 * debounce the update of the global state for slightly better performance;
 * instead of dispatching an action after each keystroke, we wait for 350ms
 * should another keystroke occur before 350ms have passed, the previous actions is interrupted
 * and a new action with the updated parameters is enqueued
 *
 */
const setGlobalAnswer = debounce((item, retVal, dispatch) => {
  dispatch(setAnswer({ answer: retVal, linkId: item.linkId }));
}, 150);

/***********************************************************************************************
 * renders a questionnaire item as basic input element for either strings, decimals, or integers
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function BasicInput({ item, initValue, handleForwardPress }) {
  const dispatch = useDispatch();

  const inputRef = useRef();

  // get currentValue from state
  const globalValue = useSelector(
    (state) =>
      // whatever the item type, retrieve the current value if existent
      Object.values(
        state.Questionnaire.itemMap[item.linkId].answer?.[0] ?? { initValue },
      )[0],
  );

  // internally store value of input
  const [localValue, setLocalValue] = useState(initValue);
  // error message in case input is not valid
  const [errorMsg, setErrorMsg] = useState('');

  // when the component is updated get current value from global state if local value does not exist
  useEffect(
    () => setLocalValue(localValue ?? globalValue),
    [localValue, globalValue],
  );

  // useEffect(
  //     () => {
  //         inputRef.current?.focus();
  //     },
  //     [],
  // );

  // check and validate input
  const handleInputChange = (input) => {
    // eslint-disable-next-line no-param-reassign
    // input = input.trim();
    // reset error message
    setErrorMsg('');
    // update local state
    setLocalValue(input);
    // show error when value is not valid integer, i.e. contains '.' or ','
    if (
      item.type === 'integer' &&
      (!Number.isInteger(Number(input)) ||
        // X.0 and X,0 are treated as integers but should be treated as decimals
        input.includes(',') ||
        input.includes('.'))
    ) {
      setErrorMsg(translate('survey').invalidInteger);
      // cancel previous update to global state
      setGlobalAnswer(item, null, dispatch);
      return;

      // show error when value is not valid decimal
    } else if (item.type === 'decimal') {
      // eslint-disable-next-line no-param-reassign
      input = input.replace(',', '.');
      if (Number.isNaN(Number(input))) {
        setErrorMsg(translate('survey').invalidDecimal);
        // cancel previous update to global state
        setGlobalAnswer(item, null, dispatch);
        return;
      }
    }

    if (item.type === 'integer' || item.type === 'decimal') {
      console.log('minmax 1');
      console.log('minmax ' + JSON.stringify(item));
      input = Number(input.replace(',', '.'));
      if (item.maxVal && input > Number(item.maxVal)) {
        console.log('minmax 2');
        setErrorMsg(translate('survey').invalidTooBig + ' ' + item.maxVal);
        setGlobalAnswer(item, null, dispatch);
        return;
      }
      if (item.minVal && input < Number(item.minVal)) {
        setErrorMsg(translate('survey').invalidTooSmall + ' ' + item.minVal);
        setGlobalAnswer(item, null, dispatch);
        return;
      }
    }
    if (item.type === 'email') {
      if (!validator.isEmail(input)) {
        console.log('not an email');
        setErrorMsg(translate('survey').invalidNotEmail);
        setGlobalAnswer(item, null, dispatch);
        return;
      }
    }

    const itemControlExtension = item.extension?.find(
      (e) => e.url === 'http://hl7.org/fhir/StructureDefinition/regex',
    );

    if (itemControlExtension && !!input) {
      if (!RegExp(itemControlExtension.valueString).test(input)) {
        setErrorMsg(translate('survey').notMatchingPattern);
        // cancel previous update to global state
        setGlobalAnswer(item, null, dispatch);
        return;
      }
    }

    // only update global value if input is valid
    // construct an answer object where the key is one of answerString, answerInteger, ...
    // and the value is the trimmed input
    setGlobalAnswer(
      item,
      input
        ? {
            [`value${item.type.charAt(0).toUpperCase() + item.type.slice(1)}`]:
              item.type === 'string' || item.type === 'email'
                ? input.trim()
                : Number(input),
          }
        : null,
      dispatch,
    );
  };

  useEffect(() => {
    if (!!initValue) {
      handleInputChange(initValue);
    }
  }, [initValue]);

  const { width } = useWindowDimensions();
  const source = {
    html:
      '<div style="font-weight: bold; font-size: 1.2em">' +
      markdownToHtml(item.text) +
      '</div>',
  };

  let units = false;
  let initUnit = null;
  if (item.fieldAnnotation?.includes('[unit')) {
    const regex = /\[unit\|(.+?)]/;

    const matches = item.fieldAnnotation.match(regex);

    if (matches) {
      units = matches[1].split('|');
      console.log(units);
      if (units.length == 1) {
        initUnit = units[0];
      }
    }
  }

  const [selectedUnit, setSelectedUnit] = useState(initUnit);

  let medicationTimes = false;
  if (item.fieldAnnotation?.includes('[medication')) {
    const regex = /\[medication\|(.+?)]/;

    const matches = item.fieldAnnotation.match(regex);

    if (matches) {
      medicationTimes = matches[1].split('|');
    }
  }

  let inputWidth =
    item.type == 'integer' || item.type == 'decimal' ? 170 : '100%';

  return (
    <View style={SharedStyles.modalInput}>
      {/* title */}
      <RenderHtml contentWidth={width} source={source} />

      {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
        <ModalInfo infoText={stripTags(item.fieldAnnotation)} />
      )}

      {/* input */}
      <View
        style={{
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 15,
        }}
      >
        {!medicationTimes && (
          <>
            <TextInput
              ref={inputRef}
              placeholder={translate('login').inputPlaceholder}
              value={localValue?.toString()}
              keyboardType={getKeyboardType(item)}
              style={{
                textAlign:
                  item.type == 'integer' || item.type == 'decimal'
                    ? 'right'
                    : 'left',
                backgroundColor: theme.colors.white,
                width: inputWidth,
              }}
              underlineColor={theme.colors.primary}
              activeUnderlineColor={theme.colors.primary}
              maxLength={item.maxLength || null}
              enterKeyHint={'done'}
              // accessibilityLabel={ }
              returnKeyType={'done'}
              accessibilityHint={
                translate('accessibility').questionnaire.textFieldHint
              }
              onChangeText={handleInputChange}
              error={!!errorMsg}
              testID="BasicInput.Input"
              onSubmitEditing={handleForwardPress}
            />
            <Text style={localStyle.unitText}>{selectedUnit}</Text>
          </>
        )}

        {medicationTimes && (
          <View>
            {medicationTimes.map((medTime, i) => {
              return (
                <View style={{ flexDirection: 'column', marginBottom: 20 }}>
                  <Text>{medTime}</Text>
                  <View style={{ flexDirection: 'row' }}>
                    <TextInput
                      value={localValue?.toString()}
                      keyboardType={getKeyboardType(item)}
                      style={{
                        textAlign:
                          item.type == 'integer' || item.type == 'decimal'
                            ? 'right'
                            : 'left',
                        backgroundColor: theme.colors.primary_light,
                        width: inputWidth,
                      }}
                      underlineColor={theme.colors.secondary}
                      activeUnderlineColor={'green'}
                      maxLength={item.maxLength || null}
                      enterKeyHint={'done'}
                      // accessibilityLabel={ }
                      returnKeyType={'done'}
                      accessibilityHint={
                        translate('accessibility').questionnaire.textFieldHint
                      }
                      onChangeText={handleInputChange}
                      error={!!errorMsg}
                      testID="BasicInput.Input"
                      onSubmitEditing={handleForwardPress}
                      ref={i === 0 ? inputRef : null}
                    />
                    <Text style={localStyle.unitText}>{selectedUnit}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}
      </View>
      {!!errorMsg && (
        <Text
          style={{ marginTop: 4, color: theme.colors.no, fontWeight: 'bold' }}
        >
          {errorMsg}
        </Text>
      )}

      {!!units && units.length > 1 && (
        <>
          <Text style={localStyle.unitDescriptionText}>Einheit auswählen:</Text>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {units.map((answerOption, index) => (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: 40,
                }}
              >
                <CheckBox
                  uncheckedIcon="circle-o"
                  checkedIcon="dot-circle-o"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.linkId}.a_${index}`}
                  textStyle={SharedStyles.choiceText}
                  checkedColor={theme.colors.primary}
                  uncheckedColor={theme.colors.accent1}
                  onPress={() => {
                    setSelectedUnit(answerOption);
                  }}
                  containerStyle={{
                    ...SharedStyles.choice,
                    marginLeft: calculateIndent(item.linkId),
                  }}
                  checked={selectedUnit == answerOption}
                />
                <TouchableOpacity
                  onPress={() => {
                    setSelectedUnit(answerOption);
                  }}
                  style={{ width: width }}
                >
                  <Text>{answerOption}</Text>
                </TouchableOpacity>
              </View>
            ))}
          </View>

          {/*<Text style={localStyle.unitText}>Einheit:</Text>*/}
          {/*<Picker*/}
          {/*    testID="Picker"*/}
          {/*    selectedValue={selectedUnit}*/}
          {/*    onValueChange={(value) => {*/}
          {/*        setSelectedUnit(value);*/}

          {/*    }}*/}
          {/*>*/}
          {/*    <Picker.Item*/}
          {/*        label={'Einheit auswählen'}*/}
          {/*        value={'[]'}*/}
          {/*        // eslint-disable-next-line react/no-array-index-key*/}
          {/*        key={'null'}*/}
          {/*        color={'#999'}*/}
          {/*    />*/}
          {/*    {units.map((answerOption, index) => (*/}
          {/*        <Picker.Item*/}
          {/*            label={answerOption}*/}
          {/*            value={answerOption}*/}
          {/*            // eslint-disable-next-line react/no-array-index-key*/}
          {/*            key={index}*/}
          {/*        />))}*/}
          {/*</Picker>*/}
        </>
      )}
    </View>
  );
}

function markdownToHtml(markdown: string): string {
  const breakRegex = /\r?\n/g;
  markdown = markdown.replace(breakRegex, '<br />');

  // Replace boldface syntax with <strong> tags
  const boldRegex = /\*{2}(.+?)\*{2}/g;
  markdown = markdown.replace(boldRegex, '<strong>$1</strong>');

  // Replace italic syntax with <em> tags
  const italicRegex = /\/\/(.+?)\/\//g;
  markdown = markdown.replace(italicRegex, '<em>$1</em>');

  // Replace underlined syntax with <u> tags
  const underlineRegex = /_{2}(.+?)_{2}/g;
  markdown = markdown.replace(underlineRegex, '<u>$1</u>');

  return markdown;
}

BasicInput.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
  unitText: {
    margin: 0,
    padding: 0,
    marginLeft: 10,
    ...theme.fonts.label,
    color: theme.values.defaultModalContentTextColor,
  },
  unitDescriptionText: {
    margin: 0,
    padding: 0,
    marginTop: 10,
    ...theme.fonts.label,
    color: theme.values.defaultModalContentTextColor,
  }
});
