import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// service & config
import translate from '../services/localization';
import { loggedInClient } from '../services/rest';

// other actions
import { Alert } from 'react-native';

const fetchRegistryErstmeldebogenEltern = createAsyncThunk(
  'registryErstEltern/FETCH',
  async ({ subjectId, certificate }, thunkApi) => {
    try {
      console.log('getting registry data');
      const response = await loggedInClient.getRegistryErstmeldebogenEltern(
        subjectId,
      );
      return thunkApi.fulfillWithValue(response);
    } catch (err) {
      Alert.alert(
        translate('generic').errorTitle,
        translate('generic').sendError,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
      return thunkApi.rejectWithValue({
        error: {
          code: err.code ?? 'ERROR',
          message: err.message,
          failedAction: 'shared/SEND_REPORT',
        },
      });
    }
  },
);

const fetchRegistryErstmeldebogenKind = createAsyncThunk(
  'registryErstmeldebogenKind/FETCH',
  async ({ subjectId, certificate }, thunkApi) => {
    try {
      console.log('getting registry data');
      const response = await loggedInClient.getRegistryErstmeldebogenKind(
        subjectId,
      );
      return thunkApi.fulfillWithValue(response);
    } catch (err) {
      Alert.alert(
        translate('generic').errorTitle,
        translate('generic').sendError,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
      return thunkApi.rejectWithValue({
        error: {
          code: err.code ?? 'ERROR',
          message: err.message,
          failedAction: 'shared/SEND_REPORT',
        },
      });
    }
  },
);

const fetchRegistryVerlaufsbogenKind = createAsyncThunk(
  'registryVerlaufsbogenKind/FETCH',
  async ({ subjectId, certificate }, thunkApi) => {
    try {
      console.log('getting registry data');
      const response = await loggedInClient.getRegistryVerlaufsbogenKind(
        subjectId,
      );
      return thunkApi.fulfillWithValue(response);
    } catch (err) {
      Alert.alert(
        translate('generic').errorTitle,
        translate('generic').sendError,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
      return thunkApi.rejectWithValue({
        error: {
          code: err.code ?? 'ERROR',
          message: err.message,
          failedAction: 'shared/SEND_REPORT',
        },
      });
    }
  }
);

const fetchRegistryLaborwerte = createAsyncThunk(
    'registryLaborwerte/FETCH',
    async ({subjectId, certificate}, thunkApi) => {
        try {
            console.log('getting registry data')
            const response = await loggedInClient.getRegistryLaborwerte(subjectId);
            return thunkApi.fulfillWithValue(response);
        } catch (err) {
            Alert.alert(
                translate('generic').errorTitle,
                translate('generic').sendError,
                [
                    {
                        text: translate('generic').ok,
                    },
                ],
                {cancelable: false},
            );
            return thunkApi.rejectWithValue({
                error: {
                    code: err.code ?? 'ERROR',
                    message: err.message,
                    failedAction: 'shared/SEND_REPORT',
                },
            });
        }
    }
);


const RegistrySlice = createSlice({
  name: 'registry',
  initialState: {
    registryDataEltern: undefined,
    registryDataErstmeldebogenKind: undefined,
    registryDataVerlaufsbogenKind: undefined,
    registryLaborwerte: undefined
  },
  reducers: {
    INIT: (state) => ({ ...state, loading: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        // generate local state when questionnaire has successfully been fetched from backend
        fetchRegistryErstmeldebogenEltern.fulfilled,
        (state, { payload: { redcapResponse } }) => {
          return {
            ...state,
            registryDataEltern: redcapResponse,
          };
        },
      )
      .addCase(
        // generate local state when questionnaire has successfully been fetched from backend
        fetchRegistryErstmeldebogenKind.fulfilled,
        (state, {payload: {redcapResponse}}) => {
          return {
            ...state,
            registryDataErstmeldebogenKind: redcapResponse,
          };
        },
      )
      .addCase(
        // generate local state when questionnaire has successfully been fetched from backend
        fetchRegistryVerlaufsbogenKind.fulfilled,
        (state, {payload: {redcapResponse}}) => {
          return {
            ...state,
            registryDataVerlaufsbogenKind: redcapResponse,
          };
        },
      )
      .addCase(
          // generate local state when questionnaire has successfully been fetched from backend
          fetchRegistryLaborwerte.fulfilled,
          (state, {payload: {redcapResponse}}) => {
              return {
                  ...state,
                  registryLaborwerte: redcapResponse
              }
          },
      )
    }
});

export default RegistrySlice.reducer;

export {fetchRegistryErstmeldebogenEltern, fetchRegistryErstmeldebogenKind, fetchRegistryVerlaufsbogenKind, fetchRegistryLaborwerte};



