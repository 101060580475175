import React from 'react';
import {Text, useWindowDimensions} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';

// components
// import { CheckBox } from 'react-native-elements';
import { CheckBox } from '@rneui/themed';

// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import { theme } from '../../../config';
import translate from '../../../services/localization';

import SharedStyles, { calculateIndent } from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {stripTags} from "../../../services/utils";

/***********************************************************************************************
 * renders a questionnaire item as boolean input
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function BooleanInput({ item }) {
  const dispatch = useDispatch();

  // get current value from State
  const currentAnswer = useSelector(
    (state) =>
      state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueBoolean,
  );

  const { width } = useWindowDimensions();
  const source = {
    html: '<div style="font-weight: bold; font-size: 1.2em">' + markdownToHtml(item.text) + '</div>'
  };

  return (
    <>
      <RenderHtml
        contentWidth={width}
        source={source}
      />

        { !!item.fieldAnnotation && !! stripTags(item.fieldAnnotation) &&
        <ModalInfo
            infoText={stripTags(item.fieldAnnotation)}
        />
      }

      <CheckBox
        title={translate('generic').yes}
        uncheckedIcon="circle-o"
        checkedIcon="dot-circle-o"
        checkedColor={theme.colors.primary}
        uncheckedColor={theme.colors.accent1}
        checked={currentAnswer}
        onPress={() =>
          dispatch(
            setAnswer({
              linkId: item.linkId,
              answer: { valueBoolean: true },
            }),
          )
        }
        key={`${item.linkId}_true`}
        containerStyle={{
          ...SharedStyles.choice,
          marginLeft: calculateIndent(item.linkId),
        }}
        textStyle={SharedStyles.choiceText}
        testID="BooleanInput.true"
      />
      <CheckBox
        uncheckedIcon="circle-o"
        checkedIcon="dot-circle-o"
        title={translate('generic').no}
        checkedColor={theme.colors.primary}
        uncheckedColor={theme.colors.accent1}
        checked={currentAnswer === false}
        onPress={() =>
          dispatch(
            setAnswer({
              linkId: item.linkId,
              answer: { valueBoolean: false },
            }),
          )
        }
        key={`${item.linkId}_false`}
        containerStyle={{
          ...SharedStyles.choice,
          marginLeft: calculateIndent(item.linkId),
        }}
        textStyle={SharedStyles.choiceText}
        testID="BooleanInput.false"
      />
    </>
  );
}

function markdownToHtml(markdown: string): string {
    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

    // Replace italic syntax with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, "<em>$1</em>");

    // Replace underlined syntax with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, "<u>$1</u>");

    return markdown;
}

BooleanInput.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};
