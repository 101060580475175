export default {
  /** strings for accessibility features (i.e. VoiceOver) */
  accessibility: {
    types: {
      button: 'button',
      header: 'header',
    },

    refresh: 'Aktualisierung',
    refreshHint:
      'Holen Sie sich den Fragebogen und den Status neu.Die aktuelle Seite wird aktualisiert.',

    back: 'zurück',
    backHint: 'Springt zur vorherigen Seite.',

    close: 'nah dran',
    closeHint: 'Schließt die aktuelle Seite oder Modal.',

    accept: 'annehmen',
    cancel: 'Abbrechen',
    acceptHint:
      'Wechselt in die Standard-Browser-Anwendung und öffnet dort Link.',

    menu: 'Weitere Informationen',
    menuHint: 'Öffnet neue Seite mit zusätzlichen Informationen und Links.',

    logoutHint: 'Startet den Abmeldeprozess und umleiten die Anmeldeseite.',
    loginHint: 'Startet Anmeldevorgang und leitet den QR-Code-Scanner um.',

    questionnaire: {
      middleButtonFinished: 'Nächste Frage. Frage wird beantwortet.',
      middleButtonUnfinished:
        'Nächste Frage, obwohl die Frage noch nicht beantwortet wird.',
      middleButtonHint:
        'Springt zu den nächsten Fragen oder schließt das Modal, wenn dies die letzte Frage war.',

      rightButtonHint: 'Springt zur nächsten Frage.',
      leftButtonHint: 'Springt zur vorherigen Frage.',
      alertButtonHint: 'Schließt den Alarm.',

      questionnaireCellHint:
        'Öffnet neue Seite, um die Kategorien der Fragebogen anzuzeigen.',
      categoryCellHint:
        'Öffnet MODAL, um diesen Fragebogeneintrag auszufüllen.',
      category: 'Diese Kategorie',
      questionnaire: 'Dieser Fragebogen',
      notStarted: 'wurde noch nicht beantwortet.',
      notFinished:
        'wurde bereits gestartet, ist aber noch nicht vollständig ausgefüllt.',
      finished: 'ist vollständig vollständig.',

      sendHint: 'Sendet den Fragebogen an die Bewertung.',

      multipleChoice: '(Mehrfachauswahl möglich)',
      singleChoice:
        'Einzelne Wahl - Nur eine Antwort-Option kann ausgewählt werden.',
      textFieldHint:
        'Bitte füllen Sie hier die angeforderten Informationen aus.',
      dateFieldHint: 'Öffnet ein neues Modal, um ein Datum auszuwählen.',
      sliderFieldEquals: 'entspricht',
      sliderFieldAnd: ', und',

      triggerHint:
        'Wenn dieses Element ausgewählt ist, wird eine weitere zusätzliche Frage angezeigt, die mit diesem Element verbunden ist.Bitte füllen Sie auch die zusätzliche Frage aus.',
      expandCategory: 'Fragen dieser Kategorie anzeigen',
      collapseCategory: 'Fragen dieser Kategorie ausblenden',

      requiredNotAnswered:
        'Um fortzufahren müssen Sie diese Frage beantworten.',
      warningNotAnswered:
        'Sie haben diese Frage noch nicht beantwortet. Sind Sie sicher, dass Sie zur nächsten Frage übergehen möchten?',
      warningNotAnsweredTitle: 'Keine Antwort gegeben',
      warningAnswerNow: 'Jetzt beantworten',
      warningSkip: 'Frage überspringen',
      continueFromLastTitle: 'Fortfahren',
      continueFromLast:
        'Sie haben zuletzt mit der Beantwortung der Fragen abgebrochen. Möchten Sie mit der Frage fortfahren, bei der Sie zuletzt stehen geblieben sind?',
      continueFromLastYes: 'Jetzt fortfahren',
      continueFromLastNo: 'Abbrechen',
    },
  },

  /** contains all string rendered on the login-screen, as well as the ones for the landing-screen */
  login: {
    title: 'Anmeldung',
    subTitle: '',
    submit: 'Anmeldung',
    user: 'Subjekt-ID.',
    pass: 'Passwort',
    waiting: 'Anmelden...',
    errorPass: 'Bitte versuche es erneut.',
    noSubjectId: 'Kein gültiges SubjektD gefunden',
    errorUserUnauthorized: 'Ungültige Benutzerdaten.',
    qrInfo: 'Bitte scannen Sie den erhaltenen QR-Code mit Ihrer Kamera.',
    errorUserGeneric: 'Bei der Anmeldung trat ein Problem auf.',
    permissionDialog:
      'Bitte erlauben Sie der App, auf Ihre Kamera zuzugreifen.',
    nextStepAfterError: 'Bitte versuchen Sie es später erneut anzumelden.',

    /** contains all strings of the landing-screen */
    landing: {
      buttonText: 'Navigieren Sie zum Anmeldebildschirm',
      title: ' ',
      subTitle: ' ',
      welcomeTitle: 'Willkommen bei der AATM-App',
      text:
        'Die App ist Ihr persönlicher Zugang zum Alpha-1-Register für Kinder und Jugendliche.\n\n' +
        '' +
        'Sie haben von der Studienleitung für die Studie in der Sie teilnehmen einen QR-Code zur Anmeldung in der App erhalten.\n\nBitte melden Sie sich nun mit dem erhaltenen QR-Code in der App an.',
      autoLoginErrorTitle: 'Auto-Anmeldung fehlgeschlagen',
      autoLoginError:
        'Beim Versuch, sich automatisch anzumelden, ist ein Fehler aufgetreten.Bitte überprüfen Sie Ihr Netzwerk und versuchen Sie es erneut',
      retry: 'wiederholen',
      deleteAll: 'Alle lokal gespeicherten Daten löschen',
    },
  },

  start: {
    k1: {
      title: 'Alpha-1-KIDS-App',
      text: 'Herzlich willkommen in der Alpha-1-KIDS-App, dem Register für Kinder und Jugendliche mit Alpha-1-Antitrypsin-Mangel.',
    },
    k2: {
      title: 'Das Register',
      text: 'Sie möchten wissen, was ein Register ist und warum es so wichtig ist? Wir erklären es Ihnen gerne! Nutzen Sie auch gerne unseren kleinen „Rundgang“, um einen ersten Eindruck von der App zu bekommen.',
    },
    k3: {
      title: 'Ihre Einwilligung',
      text: 'Um die Sicherheit Ihrer Daten im Register zu gewährleisten, müssen Sie sich in zwei Schritten registrieren. Im ersten Schritt benötigen wir Ihren Namen und Ihre Anschrift, damit wir Ihnen per Post einen Zugangscode zusenden können. Wenn Sie im 2. Schritt den QR-Code einscannen oder den Zahlencode eingeben, wird die Registrierung abgeschlossen.',
    },
  },

  walkthrough: {
    k1: {
      title: 'Rundgang durch die App',
      text: 'In diesem kurzen Rundgang möchten wir Ihnen die wichtigsten Funktionen der App vorstellen.',
    },
    k2: {
      title: 'Familienübersicht',
      text: 'Hier können Sie Familienmitglieder anlegen oder bereits gemeldete Personen aufrufen.',
    },
    k3: {
      title: 'Erstmeldebogen',
      text: 'Um ein Familienmitglied anzulegen muss der Erstmeldebogen ausgefüllt werden. Achten Sie auf das Fragezeichen-Symbol: Wenn Sie auf ein Fragezeichen-Symbol klicken, werden weitere hilfreiche Informationen angezeigt. ',
    },
    k4: {
      title: 'Laborwerte',
      text: 'Die Laborwerte sind von großer Bedeutung für das Register. Sie können die Laborwerte einfach abfotografieren und hochladen oder selbst eingeben.',
    },
    k5: {
      title: 'Verlaufsbogen',
      text: 'Bei bereits angelegten Familienmitgliedern können regelmäßig Verlaufsmeldungen (Verlaufsfragebogen) erfolgen. Folgemeldungen liefern wichtige Informationen und kosten Sie nur wenig Zeit.',
    },
    k6: {
      title: 'Was für Unterlagen sollten am besten vorliegen?',
      text: 'Um die Beantwortung der Fragebögen möglichst problemlos durchführen zu können, sollten Ihnen vor Beginn Gewicht und Größe (aktuelle Werte oder z.B. die letzten Werte aus dem gelben Vorsorgeheft) sowie die letzten Blutergebnisse vorliegen.',
    },
    k7: {
      title: 'Datenübersicht',
      text:
        'Sie möchten einen Überblick über die bisher eingegeben Daten? Klicken Sie dazu in der Familienübersicht auf die entsprechende Person. \n\n' +
        'Wenn Sie möchten können Sie sich die Übersicht ausdrucken.',
    },
    k8: {
      title: 'Allgemeine Informationen',
      text: 'Über das Menü können Sie allgemeine Informationen zum Register, dem Alpha-1-Antitrypsinmangel und unser Registerteam aufrufen.',
    },
    k9: {
      title: 'Feedback',
      text: 'Ihre Meinung zählt! Egal ob positives Feedback oder Verbesserungsvorschläge. Die Feedback-Funktion finden Sie ebenfalls über das Menü.',
    },
  },

  /** strings with generic purpose that can be used throughout the application */
  generic: {
    questionnaire: 'Fragebogen',
    no: 'Nein',
    yes: 'Ja',
    ok: 'OK',
    choose: 'Auswählen',
    error: 'Fehler',
    abort: 'Abbrechen',
    warning: 'Warnung',
    info: 'Information',
    errorTitle: 'Fehler',
    successTitle: 'Erfolg',
    delete: 'Ja, Daten löschen',
    goBack: 'Ja, bitte melden Sie sich an',
    logoutWarning: 'Abmeldung Warnung',
    sendSuccess: 'Ihre Antworten wurden erfolgreich abgeschickt.',
    sendError:
      'Bei der Datenübermittlung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie den Administrator.',
    sendErrorTwoDevices:
      'Sie haben diesen Fragebogen bereits durch die App an einem anderen Gerät gesendet.',
    eraseAllWarning:
      'Dadurch werden alle auf Ihrem Gerät gespeicherten Daten gelöscht und Sie werden abgemeldet.\n\nMöchten Sie fortfahren?',
    eraseLocalDataAtEndOfStudyText:
      'Dies wird die Studie beenden. Sobald der Benutzer die lokalen Daten bestätigt hat, wird der Teilnehmer abgemeldet. Möchten Sie fortfahren?',
    eraseLocalDataAtEndOfStudyTitle:
      'Lokal gespeicherte Daten löschen und abmelden',
    wrongLanguageVersionDetected:
      'Der aktuelle Fragebogen stimmt nicht mit der aktuell ausgewählten Sprache auf Ihrem Gerät überein. Dieser Fragebogen wird nun durch eine passende Version ersetzt. Ihre Antworten gehen dabei verloren.',
    updateError:
      'Beim Erhalten der angeforderten Daten ist ein Fehler aufgetreten - bitte prüfen Sie Ihre Daten und versuchen Sie es erneut.',
    infoRemoval:
      'Der derzeit aktuelle Fragebogen ist veraltet und wird nun entfernt.',
    reportWhileInIteratedMode:
      'Dialog, um dem Benutzer mitzuteilen, dass er bereits in einem speziellen Bericht gesendet wurde und dass er gerade in einem speziellen Intervall ist.',
    reportWhileQuestionnaire:
      'Dialog, um dem Benutzer mitzuteilen, dass derzeit ein Fragebogen zur Verfügung steht und dass er das verwenden könnte, um über seinen Zustand zu berichten.',
    errorNoInternetTitle: 'Keine aktive Internetverbindung gefunden.',
    errorNoInternet:
      'Bitte aktivieren Sie Ihre Internetverbindung, sorgen Sie für eine gute Signalstärke und versuchen Sie es erneut.',
  },

  /** strings of the about-screen */
  about: {
    title: 'Einstellungen und Infos',
    subTitle: 'Alpha-1-KIDS-App',
    logout: 'Abmelden',
    delete: 'Meine Daten löschen und Abmelden',
    demoDelete: 'Alles löschen & Abmelden (nur für diese Demo)',
    languageSelection: 'Sprachauswahl',
    languageWarning:
      'Bei Änderung der Sprachauswahl werden Ihre bisher eingegebenen Daten vom Gerät gelöscht.',
    languageWarningAddition: '\nWillst du fortfahren?',

    /** contains the strings for the optional LegalInformation Screen */
    legal: {
      title: 'Datenschutzerklärung',
      subTitle: 'Wie wir Ihre Daten behandeln',
      iconType: 'entypo',
      iconTitle: 'suitcase',
    },
    intro: {
      title: 'Rundgang durch die App',
      subTitle: 'Alle wichtigen Funktionalitäten auf einen Blick',
      iconType: 'entypo',
      iconTitle: 'help-with-circle',
    },
  },

  /** contains the strings for the legal information screen */
  legalInformation: {
    title: 'Datenschutzerklärung',
    subTitle: 'Stand 18. August 2022',
    headline: 'Datenschutzerklärung',

    content:
      'Datenschutzerklärung\n' +
      'Einleitung\n' +
      'Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.\n' +
      'Die verwendeten Begriffe sind nicht geschlechtsspezifisch.\n' +
      'Stand: 17. August 2022\n' +
      'Inhaltsübersicht\n' +
      '•\tEinleitung\n' +
      '•\tVerantwortlicher\n' +
      '•\tKontakt Datenschutzbeauftragter\n' +
      '•\tÜbersicht der Verarbeitungen\n' +
      '•\tMaßgebliche Rechtsgrundlagen\n' +
      '•\tSicherheitsmaßnahmen\n' +
      '•\tDatenverarbeitung in Drittländern\n' +
      '•\tLöschung von Daten\n' +
      '•\tÄnderung und Aktualisierung der Datenschutzerklärung\n' +
      '•\tRechte der betroffenen Personen\n' +
      '•\tBegriffsdefinitionen\n' +
      'Verantwortlicher\n' +
      'Universitätsklinikum Bonn AöR\n' +
      'Venusberg-Campus 1\n' +
      '53127 Bonn\n' +
      'E-Mail-Adresse: \n' +
      'marko.jovanovic@ukbonn.de\n' +
      'Kontakt Datenschutzbeauftragter\n' +
      'datenschutz@ukbonn.de\n' +
      'Übersicht der Verarbeitungen\n' +
      'Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.\n' +
      'Maßgebliche Rechtsgrundlagen\n' +
      'Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.\n' +
      'Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.\n' +
      'Sicherheitsmaßnahmen\n' +
      'Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.\n' +
      'Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.\n' +
      'SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.\n' +
      'Datenverarbeitung in Drittländern\n' +
      'Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. \n' +
      'Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission: https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).\n' +
      'Löschung von Daten\n' +
      'Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist. \n' +
      'Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.\n' +
      'Änderung und Aktualisierung der Datenschutzerklärung\n' +
      'Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.\n' +
      'Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.\n' +
      'Rechte der betroffenen Personen\n' +
      'Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:\n' +
      '•\tWiderspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.\n' +
      '•\tWiderrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.\n' +
      '•\tAuskunftsrecht: Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.\n' +
      '•\tRecht auf Berichtigung: Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.\n' +
      '•\tRecht auf Löschung und Einschränkung der Verarbeitung: Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.\n' +
      '•\tRecht auf Datenübertragbarkeit: Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.\n' +
      '•\tBeschwerde bei Aufsichtsbehörde: Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.\n' +
      'Begriffsdefinitionen\n' +
      'In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.\n' +
      '•\tPersonenbezogene Daten: "Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind. \n' +
      '•\tVerantwortlicher: Als "Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet. \n' +
      '•\tVerarbeitung: "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen. \n' +
      ' \n' +
      '\n',
  },
  aboutApp: {
    title: 'Über das Register',
    subTitle: 'Eine kurze Einführung in das Register',
    screenSubTitle: '',
    menu: true,
    settings: false,
    uri: 'https://alpha1bonn.de/app/about-index',
  },
  theTeam: {
    title: 'Über uns',
    subTitle: 'Das Team hinter dem Register',
    screenSubTitle: '',
    menu: true,
    settings: false,
    uri: 'https://alpha1bonn.de/app/about',
  },
  aatmInfo: {
    title: 'Allgemeine Infos zur Erkrankung (AATM)',
    subTitle: 'Alles Wissenswerte rund um den Alpha-1-Antitrypsin-Mangel',
    screenSubTitle: '',
    menu: true,
    settings: false,
    uri: 'https://alpha1bonn.de/app/info',
  },
  firstReportInfo: {
    title: 'Erstmeldung',
    subTitle: '',
    headline: 'Erstmeldung',

    content:
      'Ihre Daten werden nur für die hier genannten Forschungszwecke verwendet. Alle Beteiligten erhalten nur Zugriff auf die Daten, die sie für diese Zwecke unbedingt benötigen. Wir setzen verschiedene technische Maßnahmen ein, um Ihre Daten vor unberechtigtem Zugriff zu schützen. Auf Wunsch können Sie Ihre Daten jederzeit löschen lassen.<br /><br />' +
      '<strong>Nur ausgewählte Personen haben Zugriff auf Ihre Daten.</strong> Wir achten darauf, dass diese Personen nur so viele Daten wie unbedingt nötig einsehen können. Im Einzelnen handelt es sich um folgenden Personen:\n' +
      '<ul><li><strong>Register-Team und die Ärzte und Mitarbeiter des Universitätsklinikums Bonn:</strong> Die Mitarbeiter des Registers am Universitätsklinikum Bonn können alle Daten einsehen und bearbeiten. Dazu gehören sowohl Ihr Name und Ihre Kontaktdaten als auch Ihre Antworten im Fragebogen und andere Befunde, die an die Registerzentrale geschickt wurden.</li>' +
      '<li><strong>Technische Administratoren:</strong> Um die Datensicherheit bestmöglich zu gewährleisten, wird das Register technisch durch IT-Spezialisten des Instituts für Digitale Medizin des Universitätsklinikums Bonn betreut. Wir benötigen daher keine externen Dienstleister.</li></ul>' +
      '<li><strong>Andere Wissenschaftler und Ärzte:</strong> Andere Wissenschaftler und Ärzte, die auf dem Gebiet des Alpha-1-Antitrypsin-Mangels forschen, können bei uns Daten aus dem Register erfragen. Dazu müssen diese einen Antrag bei den für das Register verantwortliche Personen stellen, in dem sie beschreiben, wofür und warum sie die Daten benötigen. Außerdem muss das Forschungsvorhaben von der zuständigen Ethikkommission genehmigt werden. Wenn die Leitung des Alpha-1-KIDS-Registers dem Antrag zustimmt, stellen wir den Forschern nur die medizinischen Daten zur Verfügung, die sie benötigen. Sie erhalten von uns keine Namen oder Kontaktdaten, sondern lediglich pseudonymisierte Daten.</li>' +
      '<strong>Die Daten werden im Universitätsklinikum Bonn gespeichert und verschlüsselt übertragen.</strong> Dort haben wir für das Patientenregister einen besonders geschützten Server eingerichtet. Wenn Sie Ihre Daten eingeben, werden diese über eine SSL-Verbindung übertragen. Dabei werden die Daten so verschlüsselt, dass sie von Dritten praktisch nicht eingesehen werden können. Diese Technik findet beispielsweise auch beim Online-Banking Einsatz. Es besteht jedoch ein geringes Restrisiko, dass die Daten von Unbefugten bei der Übertragung eingesehen werden können.\n' +
      '<br/><br/>' +
      '<strong>Sie haben jederzeit die Möglichkeit, Ihre Daten löschen zu lassen.</strong> Bitte wenden Sie sich dazu bitte direkt an uns. Die Angabe von Gründen für die Löschung Ihrer Daten ist nicht erforderlich. Wir werden Ihre Daten dann umgehend aus dem Register löschen. Bitte beachten Sie, dass die Daten noch einige Zeit in Sicherungskopien vorhanden sind. Diese sind jedoch besonders gut geschützt und nicht über das Internet zugänglich.\n',
  },

  /** strings of the survey-screen */
  survey: {
    subTitle: 'test',
    logout: 'Abmelden',
    subTitleCheckIn: '',
    title: 'Fragebögen',
    isLoading: 'Wird geladen...',
    yourAnswer: 'Ihre Antwort',
    titleCheckIn: 'Alpha-1-KIDS-App',
    welcomeTitle: 'Willkommen',
    noUserTitle: 'Benutzer wurde nicht gefunden',
    sendFinished: 'Antworten abschicken',
    send: 'Fragebogen abschicken',
    additionalAnswer: 'zusätzlicheAntwort',
    alternativeAnswer: 'alternativeAntwort',
    surveySubTitle: 'Bitte ausfüllen bis zum: ',
    surveyTitle: 'Weiter zur Familienübersicht',
    inputPlaceholder: 'Bitte gib deine Antwort ein',
    endedStudyTitle: 'Ende des Teilnahme-Titels',
    noQuestionnaireTitle: 'Fragebogen nicht gefunden',
    loadingQuestionnaire: 'Anfragen \n Questionnaire.',
    inputPlaceholderTime: 'Bitte geben Sie das Datum hier ein',
    sessionTimeout: 'Ihr Benutzer konnte nicht erkannt werden',
    reloadingQuestionnaire: 'Wiederverladung \n Questionnaire',
    welcomeTitleFirstTime: 'Studienteilnahme',
    surveyTitleFirstTime: 'Ihr Einstiegs-Fragebogen-Titel',
    noUserText:
      'Ihre ID konnte nicht überprüft werden.Bitte versuche es erneut.',
    welcomeTextFirstTimeUser2: '- Dies ist der zweite Teil dieser Nachricht.',
    sendFinishedMessage:
      'Bitte bestätigen Sie, dass Sie die Antworten nun abschicken möchten.',
    noNewQuestionnaireAvailableYetTitle:
      'Titel, falls kein Fragebogen verfügbar ist',
    sendUnfinishedMessageDenied:
      'Der aktuelle Fragebogen ist noch nicht abgeschlossen.Bitte tun Sie dies.',
    endedStudyText:
      'Diese Zeichenfolge enthält Informationen zum Ende der Studie für den Benutzer.',
    nextOne:
      'Dieser Text wird angezeigt, um zu kommunizieren, wenn der nächste Fragebogen verfügbar ist: ',
    noQuestionnaireText:
      'Beim Laden des Fragebogens trat ein Fehler auf - bitte versuchen Sie es erneut.',
    noNewQuestionnaireAvailableYet:
      'Aktuell ist kein Fragebogen für Sie verfügbar. Wir bedanken uns für Ihre Teilnahme an der Studie.',
    nextOneNew:
      'Aktuell ist kein Fragebogen für Sie verfügbar. Der nächste Fragebogen wird am folgenden Tag für Sie verfügbar gemacht: ',
    furtherInfo:
      'Wir informieren Sie, sobald ein weiterer Fragebogen verfügbar wird.',
    welcomeTextUser:
      'Hallo! \n\n' +
      'Ich bin die Alpha-1-KIDS-App. Ich helfe Ihnen, die Daten Ihrer Kinder und Ihrer Familie im AATM-Register für' +
      ' Kinder und Jugendliche zu pflegen.',

    welcomeTextFirstTimeUser1:
      'Wir informieren Sie, sobald ein weiterer Fragebogen verfügbar wird. ',
    invalidInteger: 'Nur ganzzahlige Werte erlaubt!',
    invalidDecimal: 'Keine gültige Dezimalzahl!',
    invalidTooBig: 'Wert muss kleiner sein als:',
    invalidTooSmall: 'Wert muss größer sein als:',
    invalidNotEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    invalidEmpty: 'Bitte machen Sie eine Angabe',
    notMatchingPattern: 'Eingabe entspricht nicht den Vorgaben!',
  },

  /** strings for the special-report-flow */
  reporting: {
    symptoms_no: 'NEIN',
    symptoms_yes: 'JAWOHL',
    symptoms_success_ok: 'Erfolgsbestätigung',
    symptoms_success_header: 'Erfolgs-Nachrichten-Header',
    symptoms_header: 'Sonderbericht schicken',
    symptoms_success_message: 'Erfolgsnachricht-Inhalt',
    symptoms_question: 'Bestätigungsfrage hier einfügen',
  },

  /** each entry in this array will generate a new listItem on the about-screen. When clicked on such an item, a webView will open that
   * can display any website. "iconType" is the name of a free Webfont and the title is the name of the particular icon. .
   * as this app is using react-native-elements, the list of compatible fonts can be found in its documentation: * https://reactnativeelements.com/docs/icon
   */
  webViews: [
    {
      title: 'Wichtige Links',
      subTitle: ' ',
      screenSubTitle: '',
      menu: true,
      settings: false,
      uri: 'https://alpha1bonn.de/app/links',
    },
    {
      title: 'Nutzungsbedingungen',
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://alpha1bonn.de/app/terms',
      menu: true,
      settings: true,
      iconTitle: 'suitcase',
      iconType: 'entypo',
    },
    {
      title: 'Datenschutzerklärung',
      subTitle: 'Unsere Datenschutzerklärung',
      screenSubTitle: '',
      uri: 'https://www.ukbonn.de/datenschutzerklaerung/',
      menu: true,
      settings: true,
      iconTitle: 'suitcase',
      iconType: 'entypo',
    },
    {
      title: 'Impressum',
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://www.ukbonn.de/impressum/',
      menu: true,
      settings: true,
      iconTitle: 'info',
      iconType: 'entypo',
    },
    {
      title: 'Über uns',
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://alpha1bonn.de/app/about',
      menu: true,
      settings: true,
      iconTitle: 'info',
      iconType: 'entypo',
    },
    {
      title: 'Über das Register',
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://alpha1bonn.de/app/about-index',
      menu: true,
      settings: true,
      iconTitle: 'info',
      iconType: 'entypo',
    },
    {
      title: "Alpha-1-Antitrypsin-Mangel",
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://alpha1bonn.de/app/info',
      menu: true,
      settings: true,
      iconTitle: 'info',
      iconType: 'entypo',
    },
    {
      title: "Wichtige Links",
      subTitle: ' ',
      screenSubTitle: '',
      uri: 'https://alpha1bonn.de/app/links',
      menu: true,
      settings: true,
      iconTitle: 'info',
      iconType: 'entypo',
    },
  ],

  /** each entry in this array will generate a new listItem on the about-screen. When clicked on such an item, a modal will open
   * that informs the user that he/she is being redirected to their local browser. "iconType" is the name of a free Webfont and the title is the name of the particular icon. .
   * as this app is using react-native-elements, the list of compatible fonts can be found in its documentation: * https://reactnativeelements.com/docs/icon
   */
  modalLinks: [
    // {
    //   title: 'Erster Link',
    //   subTitle:
    //     'Dies ist ein regulärer Link.Es wird ein Modal eröffnet, bevor der Benutzer den Benutzer auf seinen eigenen Browser umleitet.',
    //   text: 'Ihre werden jetzt umgeleitet.',
    //   uri: 'https://github.blog/',
    //   iconTitle: 'link',
    //   iconType: 'entypo',
    // },
    // {
    //   title: 'Zweiter Link',
    //   subTitle:
    //     'Für jeden Artikel fügen Sie der Eigenschaft Modulks von textconfig.js hinzu, ein anderer Eintrag in dieser Liste wird generiert.',
    //   text: 'Ihre werden jetzt umgeleitet.',
    //   uri: 'https://ibm.com/',
    //   iconTitle: 'link',
    //   iconType: 'entypo',
    // },
  ],
};
