import React from 'react';
import {View, Text, Dimensions, StyleSheet, useWindowDimensions} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';
import appConfig from '../../../config/appConfig';

// components
// react-native-slider spitts out module warning in web: Failed to parse source map
// TODO: find proper alternative, perhaps: https://github.com/reactrondev/react-native-web-swiper
// Addition: should actually work for react-native-web as well (https://github.com/miblanchard/react-native-slider) -> check again
import { Slider } from '@miblanchard/react-native-slider';


// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import { theme } from '../../../config';
import translate from '../../../services/localization';

import SharedStyles, {calculateFontSize, calculateIndent, calculateLineHeight} from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {stripTags} from "../../../services/utils";

/***********************************************************************************************
 * component
 * renders a questionnaire item as slider input
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 ***********************************************************************************************/
export default function SliderInput({ item }) {
  const dispatch = useDispatch();

  let answerOptionValueCodingMap = {};
  // get current value from state
  const currentValue = useSelector(
    (state) => {
        // console.log(state.Questionnaire.itemMap[item.linkId]);
        let r = state.Questionnaire.itemMap[item.linkId].answer?.[0];
        if (typeof r?.valueCoding !== 'undefined') {
            console.log('VALUE CODING')
            item.answerOption.map((answerOption, index) => {
                answerOptionValueCodingMap[answerOption.valueCoding.code] = index;
            });
            console.log('VALUE CODING END')
            console.log('itemmap', state.Questionnaire.itemMap[item.linkId])
            console.log('selector code', r.valueCoding)
            console.log('selector code', r.valueCoding.code)
            return answerOptionValueCodingMap[r.valueCoding.code];
        }
        if (typeof r !== 'undefined' && r !== 'NOANSWER') {
            return r.toString();
        }
        return 0;
    },
  );

    const currentDisplay = useSelector(
        (state) => {
            // console.log(state.Questionnaire.itemMap[item.linkId]);
            let r = state.Questionnaire.itemMap[item.linkId].answer?.[0];
            if (typeof r?.valueCoding !== 'undefined') {
                item.answerOption.map((answerOption, index) => {
                    answerOptionValueCodingMap[answerOption.valueCoding.code] = index;
                });
                return r.valueCoding.display;
            }
            if (typeof r !== 'undefined') {
                return r.toString();
            }
            return 0;
        },
    );

    const isDone = useSelector(
        (state) => {
            console.log(state.Questionnaire.itemMap[item.linkId]);
            return state.Questionnaire.itemMap[item.linkId].done;
      },
    );
    // creates the default slider-object

    const SliderContainer = (props: {
        caption: string;
        children: React.ReactElement;
        vertical?: boolean;
    }) => {
        const {caption} = props;

        const renderChildren = () => {
            return React.Children.map(
                props.children,
                (child: React.ReactElement) => {
                    if (!!child && child.type === Slider) {
                        return React.cloneElement(child);
                    }
                    return child;
                },
            );
        };

        return (
          <View>
            <View>
              <Text>{caption}</Text>
            </View>
            {renderChildren()}
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start", padding: 5
              }}>
              {isDone &&
                        (
                <View style={{alignItems: 'center'}}>


                                {currentDisplay !== 'NOANSWER' &&
                                    <>
                  <Text style={{
                      ...theme.fonts.body,
                    }}
                  >
                    Ihre Auswahl:
                  </Text>

                  <RenderHtml
                    contentWidth={width}
                    source={{
                      html: '<div style="font-weight: bold; font-size: 2em; color: ' + theme.colors.primary +'">' + markdownToHtml(currentDisplay) + '</div>'
                    }}
                  />

                  <Pressable
                    style={[styles.button, styles.buttonOpen]}
                    onPress={() => {
                      let dispatchAnswer = {
                        linkId: item.linkId,
                        answer: null,
                      };
                      dispatch(setAnswer(dispatchAnswer));
                    }}
                  >
                    <Text
                      style={[styles.buttonTextStyle]}>Auswahl
                                                aufheben</Text>
                                        </Pressable>
                                    </>}
                                {currentDisplay === 'NOANSWER' &&
                                    <Text style={{
                                        ...theme.fonts.label,
                                        color: theme.colors.primary
                                    }}>
                                        Sie möchten keine Angabe machen. Falls Sie dennoch eine Angabe machen möchten,
                                        tätigen Sie Ihre Auswahl auf dem grauen
                                        Balken, indem Sie an entsprechender Stelle des Balkens tippen.
                    </Text>
}
                            </View>
                        )
                    }
                    {!isDone &&
                        (<View style={{flexDirection: 'column'}}><Text style={{
                            ...theme.fonts.label,
                        }}>Sie haben noch keine Auswahl getätigt. Tätigen Sie Ihre Auswahl auf dem grauen
                            Balken,
                            indem Sie an entsprechender Stelle des Balkens tippen. </Text>

                            {item.fieldAnnotation.includes('[noanswer') &&
                                <Pressable
                                    style={[styles.button, styles.buttonOpen]}
                                    onPress={() => {
                                        let dispatchAnswer = {
                                            linkId: item.linkId,
                                            answer: "NOANSWER",
                                        }
                                        dispatch(
                                            setAnswer(dispatchAnswer),
                                        );
                                    }}>
                                    <Text
                                        style={[styles.buttonTextStyle]}>Ich
                                        möchte {'\n'}keine Angabe machen</Text>
                  </Pressable>
                }
                        </View>)
                    }
            </View>
          </View>
        );
    };


    // checks the dependencies of the item and renders it (if the dependencies check out)
    let questionItem;
    // console.log('a slider');
    // itemControlExtension = item.extension?.find(
    //   (e) =>
    //     e.url ===
    //     'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
    // );
    // eslint-disable-next-line no-case-declarations
    let min = 0,
        max = 1,
        step = 0;
    // isSlider = itemControlExtension?.valueCodeableConcept?.coding?.find(
    //   (c) =>
    //     c.system === 'http://hl7.org/fhir/questionnaire-item-control' &&
    //     c.code === 'slider',
    // );

    // console.log(item);
    // if (isSlider) {

    if (
        !!item.fieldAnnotation &&
        item.fieldAnnotation.includes('[slider|') &&
        item.fieldAnnotation.indexOf(']') != -1
    ) {
        const minMaxStep = betweenMarkers(item.fieldAnnotation, '[slider|', ']');
        min = parseInt(minMaxStep.substring(0, minMaxStep.indexOf('|')));
        let rest = minMaxStep.substring(minMaxStep.indexOf('|') + 1);
        step = parseInt(rest.substring(0, rest.indexOf('|')));
        rest = rest.substring(rest.indexOf('|') + 1);
        max = parseInt(rest);
    } else if (item.fieldAnnotation.includes('[slider]')) {
        min = 0;
        step = 1;

        console.log('the map');
        console.log(answerOptionValueCodingMap);
        max = item.answerOption.length - 1;
    } else {
        min = 0;
        step = 1;
        max = 100;
    }

    const trackMarks = [];
    for (let i = min; i <= max; i += step) {
        trackMarks.push(i);
    }

    const {width} = useWindowDimensions();
    const source = {
      html: '<div style="font-weight: bold; font-size: 1.2em">' + markdownToHtml(item.text) + '</div>'
    };

    let answersOptionDescription = ''

    item.answerOption?.map((answerOption, index) => {
        answersOptionDescription += getItemTitle(answerOption);
        answersOptionDescription += "\n";
    });


    if (item.type == 'choice') {

        questionItem = (
          <View style={{}}>
            <RenderHtml contentWidth={width} source={source} />

            {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
              <ModalInfo infoText={stripTags(item.fieldAnnotation)} />
            )}
            <View
              style={{
                marginStart: 15,
                marginEnd: 15,
              }}
            >
              <View
                style={{
                  marginTop: 30,
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start"
                }}>
                {item.answerOption.map((answerOption, index) => (
                  <Text
                    style={styles.sliderLabel}
                    label={getItemTitle(answerOption)}
                    value={JSON.stringify(answerOption)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {getItemTitle(answerOption).substring(0, 1)}
                  </Text>
                ))}
              </View>
              <SliderContainer caption={''}>
                <Slider
                  value={currentValue}
                  minimumTrackTintColor={'#999999'}
                  maximumTrackTintColor={'#999999'}
                  minimumValue={min}
                  maximumValue={max}
                  step={step}
                  trackMarks={trackMarks}
                  thumbStyle={isDone && currentDisplay!=='NOANSWER' ? SharedStyles.thumb1 : SharedStyles.thumb2}

                  onSlidingComplete={(value) => {
                    let val = value.reduce(combine);
                    console.log('VALSTART');
                    console.log(min);
                    console.log(max);
                    console.log(currentValue);
                    console.log(value);
                    console.log(val);
                    // console.log(step);
                    // console.log(min);
                    // console.log(max);
                    let answer = item.answerOption[val];
                    let dispatchAnswer = {
                      linkId: item.linkId,
                      answer: answer,
                    };
                    console.log(dispatchAnswer);
                    console.log('VALEND');
                    dispatch(setAnswer(dispatchAnswer));
                  }}
                />
              </SliderContainer>
            </View>
            <View
              style={{
                marginTop: 10,
              }}
            >
              <RenderHtml
                contentWidth={width}
                source={{
                  html:
                    '<p>' + markdownToHtml(answersOptionDescription) + '</p>',
                }}
              />
            </View>
          </View>
        );
    } else {
        questionItem = (
          <View style={{}}>
            <RenderHtml contentWidth={width} source={source} />

            <View
              style={{
                marginStart: 15,
                marginEnd: 15,
              }}
            >
              <View
                style={{
                  marginTop: 5,
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                {item.sliderLabels &&
                  ['left', 'center', 'right'].map((pos, index) => (
                    <Text
                      label={item.sliderLabels[pos]}
                      value={JSON.stringify(item.sliderLabels[pos])}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      
                                    style={[styles.sliderLabel, {
                                        'maxWidth': '50%',
                                        'textAlign': pos
                                    }]}>
                                    {item.sliderLabels[pos]}{item.sliderLabels[pos] ? '\n▾' : ''}
                    </Text>
                  ))}
              </View>
              <SliderContainer caption={''}>
                <Slider
                  value={currentValue}
                  minimumTrackTintColor={'#999999'}
                  maximumTrackTintColor={'#999999'}
                  minimumValue={min}
                  maximumValue={max}
                  step={step}
                  trackMarks={trackMarks}
                  thumbStyle={isDone && currentDisplay!=='NOANSWER' ? SharedStyles.thumb1 : SharedStyles.thumb2}
                  onSlidingComplete={(value) => {
                    console.log('VALSTART');
                    console.log(min);
                    console.log(max);
                    console.log(currentValue);
                    console.log(value);

                    let val = value.reduce(combine);
                    let answer = val + '';

                    console.log(val);

                    // console.log({
                    //     linkId: item.linkId,
                    //     answer: answer,
                    // })
                    // ,
                    dispatch(
                      setAnswer({
                        linkId: item.linkId,
                        answer: answer,
                      }),
                    );
                  }}
                />
              </SliderContainer>
            </View>

            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            ></View>
          </View>
        );
    }
    return questionItem;
}


function markdownToHtml(markdown: string): string {
    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

    // Replace italic syntax with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, "<em>$1</em>");

    // Replace underlined syntax with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, "<u>$1</u>");

    return markdown;
}

const getItemTitle = (item) => {
  let title;

  // sets the title in case of a valueCoding attribute
  if (item.valueCoding) {
    title = item.valueCoding.display ?? item.valueCoding.code;
  } else {
    // get the object entry whose key starts with 'value'
    title =
        item[Object.keys(item).find((key) => key.startsWith('value'))].toString();
  }
  return title;
};
const combine = (a, b) => a + "" + b + "";

function betweenMarkers(text, begin, end) {
  const firstChar = text.indexOf(begin) + begin.length;
  const lastChar = text.indexOf(end);
  const newText = text.substring(firstChar, lastChar);
  return newText;
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
  },
  buttonOpen: {
    backgroundColor: theme.colors.primary,
  },
buttonTextStyle: {
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        padding: 10,
        fontSize: appConfig.scaleFontsFkt(15)
    },
    sliderLabel: {
        color: theme.colors.primary,
        fontWeight: 'bold',
        fontSize: appConfig.scaleFontsFkt(15)
    }
});
