// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {Image, StyleSheet, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../../propTypes';

// custom components
import {Banner} from '../../components/shared';

// services & config
import {theme} from '../../config';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function AboutRegisterScreen({navigation}) {

    let {width} = useWindowDimensions();


    return (
        <View>
            <View style={localStyle.wrapper}>
                {/* banner */}
                <Banner
                    nav={navigation}
                />
                {/* content */}
                <Image source={require('src/assets/images/illu-einfuehrung1.png')}
                       style={{width: 1170/3.5, height: 855/3.5, align: 'center', alignSelf: 'center'}} resizeMode={"contain"}/>
                <RenderHtml contentWidth={width} source={{
                    html:
                        '<h1 className="font-bold text-2xl">Eine kurze Einführung in das Register</h1>' +
                        '<p style="font-size: 120%">Das Register für Kinder und ' +
                        'Jugendliche mit Alpha-1-Antitrypsinmangel (Alpha-1-KIDS) soll dabei helfen die Erkrankung besser zu verstehen ' +
                        'und damit die Betreuung von Betroffenen zu verbessern. Im Register werden relevante Daten wie Laborwerte, ' +
                        'Symptome und Schweregrad der Erkrankung erfasst und regelmäßig ausgewertet.</p><p style="font-size: 120%">Hierfür benötigen wir <strong>Ihre ' +
                        'Mithilfe</strong>. Denn nur wenn Sie sich die Mühe machen, die entsprechenden Informationen in das Register ' +
                        'einzutragen, können wir genügend Informationen sammeln, um dieses Ziel zu erreichen. Die meisten der benötigten ' +
                        'Informationen sind Ihnen ohnehin bekannt (z.B. aktuelle Medikamente). Für einige Informationen benötigen Sie ' +
                        'eventuell noch zusätzlich Auskunft von Ihrem behandelnden Arzt/Ihrer behandelnden Ärztin. So ist es für uns ' +
                        'besonders wertvoll, Laborwerte zu erhalten die uns Auskunft über den Zustand der Leber geben.</p><p style="font-size: 120%">Ein besseres ' +
                        'Verständnis des natürlichen Verlaufs der Erkrankung ist von großer Bedeutung. So können wir in Zukunft ' +
                        'vielleicht schon frühzeitig abschätzen, welche Patienten ein hohes Risiko haben, eine schwere Lebererkrankung zu ' +
                        'entwickeln und welche eher nicht. Neben der Eintragung ins Register ist es daher besonders wichtig, dass in ' +
                        'regelmäßigen Abständen Verlaufsmeldungen an das Register erfolgen. Ideal wäre es, wenn etwa alle 6-12 Monate ' +
                        'eine Verlaufsmeldung erfolgt. Auch wenn dies nicht immer möglich ist: jede Verlaufsmeldung hilft!</p><p style="font-size: 120%">Wir ' +
                        'bemühen uns, Ihnen die Teilnahme am Register so einfach wie möglich zu gestalten und freuen uns, dass wir Ihnen ' +
                        'die Teilnahme auf rein digitaler Ebene anbieten können. Ein Rundgang durch die App ermöglicht es Ihnen, die ' +
                        'wichtigsten Funktionen der App kennenzulernen. Wenn Ihnen etwas auffällt, was verbessert werden könnte, zögern ' +
                        'Sie bitte nicht das Feedback-Tool zu nutzen.</p><p style="font-size: 120%">Ihr Alpha-1-KIDS Team aus Bonn.</p>',
                }}/>


            </View>
        </View>
    );
}

AboutRegisterScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        width: 700,
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default AboutRegisterScreen;
