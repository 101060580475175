import React, { useState } from 'react';
import { loggedInClient } from '../services/rest';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

// This function works for the data type .img and
// is intended for web, because document scanning does not make
// sense for web. This also works for multiple images.
function DocumentDropzone() {
  const { subjectId } = useSelector((state) => state.User);

  const [uploadStatus, setUploadStatus] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        setUploadStatus('Upload läuft...');
        for (const file of acceptedFiles) {
          const response = await loggedInClient.importRecord(
            subjectId,
            'labordocs',
            '<labordocs_comment> </labordocs_comment>',
            file,
            'labordocs_file',
          );
          console.log(response);
        }
        setUploadStatus('Upload erfolgreich!');
      } catch (error) {
        console.log('None or not all files could be uploaded: ' + error);
        setUploadStatus(
          'Upload fehlgeschlagen! Bitte versuchen Sie es noch einmal oder kontaktieren Sie den Support.',
        );
        // throw error;
      }
    },
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <p>{uploadStatus}</p>
      </div>
    </>
  );
};

export default DocumentDropzone;
