/**
 * utility functions used throughout the application
 */

import moment from 'moment';
import 'moment/locale/de';
import ReactNativeBiometrics, {BiometryTypes} from "react-native-biometrics";
import {Platform} from "react-native";
import {setUseAppLock} from "../store/user.slice";
import {setIsBiometryAuthenticated} from "../store/globals.slice";
import showMessage from "../components/shared/showMessage";

/**
 * generates a date string from Date Object (dd.mm.yyyy)
 * @param  {Date}    inputDate the input date
 * @param  {boolean} includeTime if true: the exported string will also contain the time
 */
const formatDateString = (inputDate, { includeTime, locale = 'de' }) => {
  return moment(new Date(inputDate)).locale(locale).format(!includeTime ? 'LL' : 'LLL');
};

/**
 * converts a yyyy-mm-dd string to a Date object
 * @param  {Date}    inputDate the input date string in yyyy-mm-dd format
 */
const yyyymmddToDate = (dateString) => {
  const dateParts = dateString.split('-').map(Number);

  // Ensure there are three parts (year, month, day)
  if (dateParts.length !== 3) {
    return null;
  }

  const [year, month, day] = dateParts;

  // Check if the date is valid
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  const dateObject = new Date(year, month - 1, day);

  // Check if the date is valid after considering month indexing (0-based in JavaScript)
  if (
      dateObject.getFullYear() !== year ||
      dateObject.getMonth() !== month - 1 ||
      dateObject.getDate() !== day
  ) {
    return null;
  }

  return dateObject;
}

const stripTags = (input: string) => {
  return input.replace(/(\[.*?\])/gm, '').trim();
}

const switchAppLock = (dispatch, appLockSetting) => {
  const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials: true});

  rnBiometrics.isSensorAvailable()
      .then((resultObject) => {
        const {available, biometryType} = resultObject

        let promptMessage = '';
        if (available && biometryType === BiometryTypes.TouchID) {
          promptMessage = 'Mit Touch ID entsperren';
        } else if (available && biometryType === BiometryTypes.FaceID) {
          promptMessage = 'Mit FaceID entsperren';
        } else if (available && biometryType === BiometryTypes.Biometrics) {
          promptMessage = 'Mit Fingerabdruck entsperren';
        } else {
          console.log('Biometrics not supported')
        }

        let fallbackPromptMessage = 'Geben Sie Ihre PIN ein';
        if (Platform.OS === 'ios') {
          fallbackPromptMessage = 'Geben Sie Ihren Passcode ein';
        }

        if (available) {
          rnBiometrics.simplePrompt({
            promptMessage: promptMessage,
            fallbackPromptMessage: fallbackPromptMessage,
            cancelButtonText: 'Abbruch',
          })
              .then((resultObject) => {
                const {success} = resultObject

                if (success) {
                  dispatch(setUseAppLock(appLockSetting)).then(()=> {
                      let message = appLockSetting ?
                          'Die App-Sperre ist nun gesetzt.' : 'Die App-Sperre wurde entfernt.';
                      showMessage({
                          message: message,
                          position: "bottom",
                          type: "info",
                      })
                  });
                  dispatch(setIsBiometryAuthenticated(true));
                  console.log('successful biometrics provided')
                } else {
                  console.log('user cancelled biometric prompt')
                }
              })
              .catch((e) => {
                console.log('biometrics failed')
                console.error(e)
              })
        }
      })

}

// eslint-disable-next-line import/prefer-default-export
export { formatDateString };
export { stripTags };
export { yyyymmddToDate };
export { switchAppLock };
