/**********************************************************************************
import
***********************************************************************************************/

import React, {useState} from 'react';
import {
  View,
  StyleSheet,
  Modal,
  Alert,
  Pressable,
  Text,
  useWindowDimensions,
  Image,
  I18nManager,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';

import { theme } from '../../config';
import RenderHtml from "react-native-render-html";
import {IconButton} from "react-native-paper";
import RNModal from 'react-native-modal';
import translate from '../../services/localization';

function ModalInfo({ infoText }) {

  const [modalVisible, setModalVisible] = useState(false);
  const { width } = useWindowDimensions();

  const html = {
    html: '' + markdownToHtml(infoText) + ''
  }

  return (
    <View style={styles.centeredView}>
      <RNModal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        style={{ margin: -40, padding: 40 }}
                backdropColor={"black"}
        backdropOpacity={0.7}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={[
            styles.centeredView,
            {
              backgroundColor: 'rgba(0,0,0,0.5)',
            },
          ]}
        >
          <View style={styles.modalView}>
            <View style={[styles.titleWrapper, {position: 'relative', width: '100%'}]}>
              <Text
                style={styles.modalTitle}
                accessibilityRole={translate('accessibility').types.header}
              >
                Erklärung
              </Text>

              <IconButton
                icon="close"
                iconColor={theme.colors.black}
                size={30}
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
style={{ right: -20, top: 8, position: 'absolute'}}
                containerColor={theme.colors.white}
                mode={'contained'}
              />
            </View>
            <View style={{ flexDirection: 'row', width: '80%'}}>
              <Image source={require('../../assets/images/info.png')}
                style={{height: 40, width: 40, marginRight: 20, marginLeft: -30}}/>
              <RenderHtml
                                contentWidth={width}
                                source={html}
                            />
            </View>
          </View>
        </View>
      </RNModal>

      <Pressable
        style={[styles.button, styles.buttonBordered]}
        onPress={() => setModalVisible(true)}
      >
        <Image
          source={require('../../assets/images/info.png')}
          style={{ height: 40, width: 40, marginRight: 20 }}
        />

        <Text
          style={[
            {
              marginRight: 10,
              fontSize: 15,
              textAlign: 'left',
            },
          ]}
        >
          Zusätzliche Erklärung{'\n'}anzeigen
        </Text>
      </Pressable>
    </View>
  );
}

ModalInfo.propTypes = {
  infoText: PropTypes.string.isRequired,
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
width: '90%',
    borderRadius: 20,
    padding: 25,
paddingTop: 0,
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
  },
  buttonOpen: {
    backgroundColor: theme.colors.primary,
  },

  buttonBordered: {
    borderWidth: 3,
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    borderColor: theme.colors.primary,
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },

  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
    alignItems: 'center',
    width: I18nManager.isRTL ? '100%' : 'auto',

    paddingTop: 10,
    
    backgroundColor: theme.colors.white,
  },

  modalTitle: {
    ...theme.fonts.header2,
    fontSize: 20,
    paddingTop: 10,
    ...theme.fonts.header1,
    color: theme.values.defaultModalTitleColor,
  },
});

function markdownToHtml(markdown: string): string {
  const breakRegex = /\r?\n/g;
  markdown = markdown.replace(breakRegex, '<br />');

  // Replace boldface syntax with <strong> tags
  const boldRegex = /\*{2}(.+?)\*{2}/g;
  markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

  // Replace italic syntax with <em> tags
  const italicRegex = /\/\/(.+?)\/\//g;
  markdown = markdown.replace(italicRegex, "<em>$1</em>");

  // Replace underlined syntax with <u> tags
  const underlineRegex = /_{2}(.+?)_{2}/g;
  markdown = markdown.replace(underlineRegex, "<u>$1</u>");

  console.log(markdown);
  return markdown;
}

export default ModalInfo;
