import React, {useEffect, useState} from 'react';
import {Image, Modal, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Drawer, TextInput} from 'react-native-paper';
import {Routes} from '../navigation/constants';
import translate from '../services/localization';
import {theme} from '../config';
import {useDispatch, useSelector} from 'react-redux';
import {loggedInClient} from '../services/rest';

const PersistentColumnNavigation = ({navigationRef, style}) => {
    const dispatch = useDispatch();

    const [feedbackText, setFeedbackText] = useState("");
    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

    const [isNavigationReady, setIsNavigationReady] = useState(false);
    const [activeRoute, setActiveRoute] = useState('');

    const [showAllgemeine, setShowAllgemeine] = useState(false);
    const [showRechtliches, setShowRechtliches] = useState(false);

    // TODO: hide 'Abmelden' button, when user is logged out already

    useEffect(() => {
        if (navigationRef.current) {
            setIsNavigationReady(true);
            const route = navigationRef.current.getCurrentRoute();
            if (route) {
                setActiveRoute(route.name);
            }
        }
    }, [navigationRef.current]);

    useEffect(() => {
        const unsubscribe = navigationRef.current?.addListener('state', () => {
            setActiveRoute(navigationRef.current.getCurrentRoute().name);
        });

        return unsubscribe;
    }, [navigationRef.current]);

    const navigateTo = (screenName, props = {}) => {
        if (isNavigationReady) {
            navigationRef.current.navigate(screenName, props);
        } else {
            console.error('Navigation has not been initialized.');
        }
    };

    const drawerTheme = {
        colors: {
            onSecondaryContainer: theme.colors.primary,
            onSurfaceVariant: theme.colors.primary,
            secondaryContainer: '#EDEDED',
        },
    };

    const specialDrawerTheme = {
        colors: {
            onSecondaryContainer: '#aa0100',
            onSurfaceVariant: '#aa0100',
            secondaryContainer: '#aa0100',
        },
    };

    function FeedbackModal() {
        const subjectId = useSelector((state) => state?.User?.subjectId ?? 'AATMSYSTEM');

        const [msg, setMsg] = useState({});
        const [showMsg, setShowMsg] = useState(false);
        const showMessage = (message) => {
            if (message.type == 'info') {
                message.backgroundColor = '#5bc0de';
            } else if (message.type == 'success') {
                message.backgroundColor = '#5cb85c';
            } else if (message.type == 'danger') {
                message.backgroundColor = '#d9534f';
            }
            setMsg(message);
            setShowMsg(true);
            if (message.autoHide !== false) {
                setTimeout(() => {
                    setShowMsg(false);
                }, message.duration ?? 1850)
            }
        };

        return (<Modal visible={feedbackModalVisible} transparent={true} onRequestClose={() => {
            setFeedbackModalVisible(false)
        }}>
            <View style={{'width': 700, 'height': '100%', backgroundColor: 'white', padding: 30, shadowColor: '#171717',
                shadowOffset: {width: 10, height: 0},
                shadowOpacity: .3,
                shadowRadius: 10}}>

                <Text style={[localStyle.title, {marginTop: Platform.OS === 'ios' ? 40 : 0}]}>
                    Haben Sie Feedback an uns?
                </Text>
                <Text style={localStyle.subTitle}>
                    Hier können Sie uns Ihr Feedback mitteilen:
                </Text>


                <TextInput
                    contentStyle={{backgroundColor: '#EBEBEB'}}
                    outlineStyle={{borderColor: 'black'}}
                    underlineStyle={{borderColor: 'black'}}
                    outlineColor={"black"}
                    activeOutlineColor={"black"}
                    activeUnderlineColor={"black"}
                    underlineColor={"black"}
                    multiline={false}
                    label={"Hier Feedback eingeben"}
                    height={200}
                    numberOfLines={10}
                    defaultValue={feedbackText}
                    onChangeText={setFeedbackText} style={{marginTop: 20}}></TextInput>


                {showMsg && <View style={[
                    localStyle.message,
                    {background: msg?.backgroundColor ?? 'white'}]}>
                    <Text style={{color: msg?.textStyle?.textColor ?? 'white'}}>{msg.message}</Text>
                </View>}

                <View style={{
                    flexDirection: 'row',
                    marginTop: 50
                }}>


                    <TouchableOpacity
                        style={[localStyle.buttonOutlined, {width: '50%'}]}
                        onPress={() => {
                            setFeedbackModalVisible(false);
                        }}
                    >
                        <Text style={localStyle.buttonLabelOutlined}>
                            Abbrechen
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[localStyle.button, {width: '50%'}]}
                        onPress={() => {

                            showMessage({
                                message: "Einen Moment... Ihr Feedback wird abgeschickt...",
                                position: "bottom",
                                type: "info",
                            });
                            loggedInClient.importRecord(subjectId, 'feedback', '<feedback_text>' + feedbackText + '</feedback_text>')
                                .then(() => {
                                    showMessage({
                                        message: "Vielen Dank! Ihr Feedback ist erfolgreich abgeschickt.",
                                        position: "bottom",
                                        type: "success",
                                    });
                                    setTimeout(() => setFeedbackModalVisible(false), 1850);
                                });

                        }}
                    >
                        <Text style={localStyle.buttonLabel}>
                            Senden
                        </Text>
                    </TouchableOpacity>


                </View>
            </View>
        </Modal>);
    }

    return (<>
            <FeedbackModal
            ></FeedbackModal>
            <View style={[styles.container, style]}>

                <Image
                    style={styles.logo}
                    source={require('../assets/images/illu-einfuehrung1.png')}
                    resizeMode="contain"
                />
                {isNavigationReady && (
                    <View style={{flexGrow: 1}}>
                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_startseite.png')}
                            label="Startseite"
                            onPress={() => navigateTo(Routes.STARTSCREEN)}
                            active={activeRoute === Routes.STARTSCREEN}
                        />
                        {/* TODO: implement Allgemeines */}
                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_allgemeine.png')}
                            label="Allgemeine Infos"
                            onPress={() => {
                                setShowAllgemeine(!showAllgemeine)
                            }}
                            active={activeRoute === Routes.CHECKIN}
                        />
                        {showAllgemeine &&
                            <Drawer.Section title="" showDivider={false}>
                                <Drawer.Item
                                    theme={drawerTheme}

                                    label="Über das Register"
                                    onPress={() => navigateTo(Routes.ABOUT_REGISTER)}

                                />
                                <Drawer.Item
                                    theme={drawerTheme}

                                    label="Alpha-1-Antitrypsin-Mangel"
                                    onPress={() => navigateTo(Routes.ABOUT_AATM)}
                                />
                                <Drawer.Item
                                    theme={drawerTheme}

                                    label="Über uns"
                                    onPress={() => navigateTo(Routes.ABOUT_TEAM)}
                                />
                                <Drawer.Item
                                    theme={drawerTheme}

                                    label="Wichtige Links"
                                    onPress={() => navigateTo(Routes.LINKS)}
                                />
                            </Drawer.Section>}


                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_info.png')}
                            label="App-Rundgang"
                            onPress={() =>
                                navigateTo(Routes.WALKTHROUGH)
                            }
                        />

                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_privacy.png')}
                            label="Datenschutzerklärung"
                            onPress={() =>
                                window.open(
                                    translate('webViews').find(
                                        (item) => item.title === 'Datenschutzerklärung',
                                    ).uri,
                                )
                            }
                        />


                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_feedback.png')}
                            label="Feedback"
                            onPress={() =>
                                setFeedbackModalVisible(true)
                            }
                        />

                        <Drawer.Item
                            theme={drawerTheme}
                            icon={require('../assets/images/menu_rechtliches.png')}
                            label="Rechtliches"
                            onPress={() =>
                                setShowRechtliches(!showRechtliches)
                            }
                        />

                        {showRechtliches &&
                            <Drawer.Section title="" showDivider={false}>

                                <Drawer.Item
                                    theme={drawerTheme}
                                    label="Nutzungsbedingungen"
                                    onPress={() => navigateTo(Routes.TERMS)}
                                />
                                <Drawer.Item
                                    theme={drawerTheme}
                                    label="Impressum"
                                    onPress={() =>
                                        window.open(
                                            translate('webViews').find(
                                                (item) => item.title === 'Impressum',
                                            ).uri,
                                        )
                                    }
                                />
                            </Drawer.Section>}

                        <Drawer.Item
                            theme={specialDrawerTheme}
                            label="Medizinisches Fachpersonal"
                            onPress={() =>
                                navigateTo(Routes.MEDICAL_STAFF)
                            }
                        />

                        {/*<View style={{ justifyContent: 'flex-end', flex: 1 }}>*/}
                        {/*  <Button*/}
                        {/*    icon="logout"*/}
                        {/*    mode="elevated"*/}
                        {/*    onPress={() => {*/}
                        {/*      dispatch(reset()).then(() => {*/}
                        {/*        if (isNavigationReady) {*/}
                        {/*          navigationRef.current?.dispatch(*/}
                        {/*            CommonActions.reset({*/}
                        {/*              index: 0,*/}
                        {/*              routes: [*/}
                        {/*                {*/}
                        {/*                  name: Stacks.SIGNED_OUT,*/}
                        {/*                  // params: { screen: Routes.STARTSCREEN },*/}
                        {/*                },*/}
                        {/*              ],*/}
                        {/*            }),*/}
                        {/*          );*/}
                        {/*          navigationRef.current.navigate(Stacks.SIGNED_OUT);*/}
                        {/*          // navigationRef.current.navigate(Routes.STARTSCREEN);*/}
                        {/*        } else {*/}
                        {/*          window.alert(*/}
                        {/*            'Abmelden felhlgeschlagen. Bitte versuchen Sie es erneut.',*/}
                        {/*          );*/}
                        {/*        }*/}
                        {/*      });*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Abmelden*/}
                        {/*  </Button>*/}
                        {/*</View>*/}
                    </View>


                )}
                <View style={{
                    alignItems: 'flex-end', flexDirection: 'row', flex: 1,
                    borderColor: theme.colors.accent1, padding: 15, marginTop: 50
                }}>
                    <Text>Ein Projekt des</Text>
                    <Image source={require('src/assets/images/logo_ukb.png')}
                           style={{height: 30, width: 120, marginLeft: 20}} resizeMode={"contain"}/>

                </View>
            </View></>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    logo: {
        aspectRatio: 1.368,
    }
});

const localStyle = StyleSheet.create({
    button: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        marginTop: 10,
        marginRight: 10
    },

    buttonOutlined: {
        ...theme.classes.buttonPrimaryOutlined,
        bottom: 0,
        marginTop: 10,
        marginRight: 10
    },

    buttonAlert: {
        ...theme.classes.buttonAlert,
        bottom: 0,
        marginTop: 20,
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
        color: theme.colors.white
    },
    buttonLabelOutlined: {
        ...theme.classes.buttonLabel,
        color: theme.colors.primary
    },


    subTitle: {
        color: theme.colors.accent4,
        ...theme.fonts.body,
    },


    title: {
        ...theme.fonts.title2,
        marginBottom: 12,
    },

    drawerItemIconStyle: {width: 25, height: 25, marginRight: -15},


    message: {
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 8,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
    }

});

export default PersistentColumnNavigation;
