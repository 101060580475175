import React, {useState} from 'react';
import {Text, useWindowDimensions, View} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';

// components
import { Picker } from '@react-native-picker/picker';
// import { CheckBox } from 'react-native-elements';
import { CheckBox } from '@rneui/themed';
import { SegmentedButtons, DefaultTheme } from 'react-native-paper';
import RenderHtml from "react-native-render-html";


// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import translate from '../../../services/localization';
import {stripTags} from '../../../services/utils';
import {appConfig, theme} from '../../../config';

import SharedStyles, {
  calculateFontSize,
  calculateIndent,
  calculateLineHeight,
} from './sharedStyles';
import exportService from "../../../services/questionnaireAnalyzer";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";


/**
 * when an item is of type choice it has the attribute "answerOptions".
 * the entries of that attribute contain the possible choices - and the titles of those
 * choices are either provided by the attribute valueString ot valueInteger.
 * this functions determines what is available an returns it.
 * @param  {AnswerOption} item entry of an answerOption-entry.
 */
const getItemTitle = (item) => {
  let title;

  // sets the title in case of a valueCoding attribute
  if (item.valueCoding) {
    title = item.valueCoding.display ?? item.valueCoding.code;
  } else {
    // get the object entry whose key starts with 'value'
    title =
      item[Object.keys(item).find((key) => key.startsWith('value'))].toString();
  }
  return title;
};

/***********************************************************************************************
 * component:
 * renders a list of choices either as checkboxes, radio buttons or a dropdown
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
function SegmentedButtonsInput({ item }) {
  const dispatch = useDispatch();

  const questionnaireItemMap = useSelector(
    (state) => state.Questionnaire.itemMap,
  );

  const itemMapEntry = useSelector(
    (state) => state.Questionnaire.itemMap[item.linkId],
  );

  const { width } = useWindowDimensions();
  const source = {
    html: '<div style="font-weight: bold; font-size: 1.2em">' + markdownToHtml(item.text) + '</div>'
  };

  const segTheme = {
    ...DefaultTheme,
    dark: true,
    roundness: 22,
    colors: {
      ...DefaultTheme.colors,
      secondaryContainer: theme.colors.primary,
    }
  }

  function* chunks(arr: any[], n: number) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  function* chunksArr(arr: any[], chunkSizes: number[]) {
    let startIndex = 0;
    for (const chunkSize of chunkSizes) {
      const endIndex = Math.min(startIndex + chunkSize, arr.length);
      yield arr.slice(startIndex, endIndex);
      startIndex = endIndex;
      if (startIndex >= arr.length) {
        break;
      }
    }
  }


  let buttons = [];
  let initValue = null;
  item.answerOption.map((answerOption, index) => {
    let label = getItemTitle(answerOption);
    let matches = label.match(/(.*?)\s*\-\s*.*/);
    if (!!matches) {
      label = matches[1];
    } else {
      label = label[0];
    }
    buttons.push({
      value: JSON.stringify(answerOption),
      label: label
    });
    if (!!questionnaireItemMap[item.linkId].answer?.find(
        (entry) => {
            return entry.valueCoding.code === answerOption.valueCoding.code;
        }
    )) {
      initValue = JSON.stringify(answerOption);
    }
  });

  if (
      !! item.fieldAnnotation &&
      item.fieldAnnotation.includes('[matrix|') &&
      item.fieldAnnotation.indexOf(']') !== -1
  ) {
    const chunkSizesStr = betweenMarkers(item.fieldAnnotation, '[matrix|', ']');
    const chunkSizes =  chunkSizesStr.split("|").map((numStr) => parseInt(numStr));

    buttons = [...chunksArr(buttons, chunkSizes)];
  } else {
    buttons = [...chunks(buttons, 5)];
  }

  const [localValue, setLocalValue] = useState(initValue);

  let answersOptionDescription = ''
  item.answerOption.map((answerOption, index) => {
    answersOptionDescription += getItemTitle(answerOption);
    answersOptionDescription += "\n";
  });


  // checks the dependencies of the item and renders it (if the dependencies check out)
  return (
    <>
      {/* title */}

      <View
          style={{
            marginBottom: 15
          }}
      >
        <RenderHtml
            contentWidth={width}
            source={source}
        />
      </View>

      { !!item.fieldAnnotation && !! stripTags(item.fieldAnnotation) &&
          <ModalInfo
              infoText={stripTags(item.fieldAnnotation)}
          />
      }


      {/* checks if the drop-down extension is available. */}
      {/* if yes, it will render it. */}
      {/* if not, the default way is chosen. */}
      {
        buttons.map((buttons, idx) =>
          <SegmentedButtons
              theme={segTheme}
              value={localValue}
              onValueChange={(value) => {
                setLocalValue(value);
                dispatch(
                    setAnswer({
                      linkId: item.linkId,
                      answer: JSON.parse(value),
                    }),
                )}}
              buttons={buttons}
              style={{
                ...SharedStyles.matrix
              }}
          />
        )
      }
      <View style={{ marginTop: 10 }}>
          <RenderHtml
              contentWidth={width}
              source={{
                html: '<p>' + markdownToHtml(answersOptionDescription) + '</p>'
              }}
          />
      </View>
    </>
  );

  function betweenMarkers(text, begin, end) {
    const firstChar = text.indexOf(begin) + begin.length;
    const lastChar = text.indexOf(end);
    const newText = text.substring(firstChar, lastChar);
    return newText;
  }

  function markdownToHtml(markdown: string): string {
    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

    // Replace italic syntax with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, "<em>$1</em>");

    // Replace underlined syntax with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, "<u>$1</u>");

    console.log(markdown);
    return markdown;
  }
}

function markdownToHtml(markdown: string): string {
  const breakRegex = /\r?\n/g;
  markdown = markdown.replace(breakRegex, '<br />');

  // Replace boldface syntax with <strong> tags
  const boldRegex = /\*{2}(.+?)\*{2}/g;
  markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

  // Replace italic syntax with <em> tags
  const italicRegex = /\/\/(.+?)\/\//g;
  markdown = markdown.replace(italicRegex, "<em>$1</em>");

  // Replace underlined syntax with <u> tags
  const underlineRegex = /_{2}(.+?)_{2}/g;
  markdown = markdown.replace(underlineRegex, "<u>$1</u>");

  return markdown;
}

SegmentedButtonsInput.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};

export default SegmentedButtonsInput;
