import React, {Component, useEffect, useState} from "react";
import {
    StyleSheet,
    View,
    Image,
    TouchableOpacity,
    Text,
    Platform,
    Dimensions,
    useWindowDimensions,
    Pressable
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import {LineChart} from "react-native-chart-kit";
import moment from "moment";
import RenderHtml from "react-native-render-html";
import {theme} from "../config";
import {useSelector} from "react-redux";
import AppLock from "../components/security/AppLock";
import {ScrollIndicatorWrapper} from "../components/shared";

import RNHTMLtoPDF from "../components/shared/RNHTMLtoPDF";
import FileViewer from "react-native-file-viewer";

// for web
import jsPDF from 'jspdf';

import {Button} from "react-native-paper";
import questionnaireAnalyzer from "../services/questionnaireAnalyzer";
import {parseInteger} from "jsdom/lib/jsdom/living/helpers/strings";
import {switchContent} from "../store/questionnaire.slice";
import GLOBAL from "../config/globals";

function LabResultsScreen({props, navigation}) {


    let [chartData, setChartData] = useState([]);
    let [chartLabels, setChartLabels] = useState([]);
    let [listData, setListData] = useState([]);

    const [pickerOpen, setPickerOpen] = useState(false);
    const [pickerValue, setPickerValue] = useState(null);

    useEffect(
        () => {
            navigation.removeListener('blur');
            navigation.addListener('blur', (e) => {
                setChartData([]);
                setChartLabels([]);
                setListData([]);
                setPickerOpen(false);
                setPickerValue(null);
            })
        },
        []
    );

    const {itemMap, categories} = useSelector(
        (state) => state.Questionnaire,
    );
    let categoryIndex = 3;

    let labItems = [{
        label: 'Gewichtsverlauf',
        value: 'Gewichtsverlauf',
        origCode: undefined
    }, {
        label: 'Körpergröße',
        value: 'Körpergröße',
        origCode: undefined
    }];
    // sonstiges aus Verlaufsbogen? Könnten wir diese auch mit labvalue [kennzeichnen]?

    categories[categoryIndex].item.forEach((item) => {
        console.log('iterating', JSON.stringify(item));
        if (item.fieldAnnotation?.includes('[labvalue]')) {
            let text = item.text;
            if (item.fieldAnnotation.includes('[displaysummarytext|')) {
                text = item.fieldAnnotation;
                const summaryTextRegex = /\[displaysummarytext\|(.+?)\]/g;
                let matches = text.matchAll(summaryTextRegex);
                for (let captureGroups of matches) {
                    text = captureGroups[1];
                }
            }

            labItems.push({
                label: text,
                value: text,
                origCode: item.origCode
            });
        }
    });

    console.log('labItems:', labItems);

    const {
        registryLaborwerte
    } = useSelector((state) => state.RegistryData);
    console.log('registryLaborwerte', registryLaborwerte);

    let linkIds = Object.keys(itemMap);

    labItems.forEach((labItem) => {
        if (!!labItem.origCode) {



            categories[categoryIndex].item.forEach((item) => {
                if (item.origCode === labItem.origCode) {



                    let answerSummary = undefined;
                    let labResults = [];
                    if (item.type == 'display') {
                        answerSummary = '';
                        if (item.fieldAnnotation.includes('[displaysummaryanswer|')) {
                            answerSummary = item.fieldAnnotation;
                            const summaryTextRegex = /\[displaysummaryanswer\|(.+?)\]/g;
                            let matches = answerSummary.matchAll(summaryTextRegex);
                            for (let captureGroups of matches) {
                                answerSummary = captureGroups[1];
                            }
                        }

                        const fieldEmbeddingRegex = /\{(.+?)\}/g;
                        let matches = answerSummary.matchAll(fieldEmbeddingRegex);

                        if (matches) {
                            for (let captureGroups of matches) {
                                console.log("captureGroupsB ", captureGroups, answerSummary);
                                let embeddedFieldName = captureGroups[1];
                                for (let linkId of linkIds) {
                                    let origCode = itemMap[linkId]?.origCode;
                                    if (!!origCode && origCode === embeddedFieldName) {

                                        let show =
                                            questionnaireAnalyzer.checkConditionsOfSingleItem(
                                                itemMap[linkId],
                                                itemMap,
                                            )
                                        console.log('show', show)

                                        registryLaborwerte.forEach((laborwert) => {
                                            if (laborwert.labor_kind_id !== GLOBAL.currentKind.kind_kind_id) {
                                                return;
                                            }
                                            if (laborwert[origCode] == '') {
                                                return;
                                            }
                                            // if (itemMap[linkId].type == 'string') {
                                            //
                                            //     answer = answer.replace(captureGroups[0], show ? labItem[origCode] : '');
                                            // } else if (itemMap[linkId].type == 'email') {
                                            //     answer = answer.replace(captureGroups[0], show ? labItem[origCode] : '');
                                            // } else if (itemMap[linkId].type == 'date') {
                                            //     if (itemMap[linkId].fieldAnnotation.includes('[mmyyyy')) {
                                            //         answer = answer.replace(captureGroups[0], show ? moment(labItem[origCode]).locale('de').format('MM / YYYY') : '');
                                            //     } else {
                                            //         answer = answer.replace(captureGroups[0], show ? moment(labItem[origCode]).locale('de').format('DD.MM.YYYY') : '');
                                            //     }
                                            // } else if (itemMap[linkId].type == 'choice') {
                                            //     if (itemMap[linkId].repeats) {
                                            //         let choiceAnswer = labItem[origCode]?.length ? '- ' : '';
                                            //         labItem[origCode]?.map((ans, i) => {
                                            //             if (choiceAnswer != '- ') {
                                            //                 choiceAnswer += "<br />- ";
                                            //             }
                                            //             choiceAnswer += ans?.valueCoding.display
                                            //         })
                                            //         answer = answer.replace(captureGroups[0], show ? choiceAnswer : '');
                                            //     } else {
                                            //         answer = answer.replace(captureGroups[0], show ? labItem[origCode]?.[0]?.valueCoding.display : '');
                                            //     }
                                            // } else
                                            if (itemMap[linkId].type == 'integer') {
                                                let answer = answerSummary.replace(captureGroups[0], laborwert[origCode]);
                                                let labResult = {
                                                    date: laborwert.labor_datum,
                                                    text: answer,
                                                    number: parseInteger(laborwert[origCode])
                                                };
                                                labResults.push(labResult)
                                            } else if (itemMap[linkId].type == 'decimal') {
                                                let answer = answerSummary.replace(captureGroups[0], laborwert[origCode]);
                                                let labResult = {
                                                    date: laborwert.labor_datum,
                                                    text: answer,
                                                    number: parseFloat(laborwert[origCode])
                                                };
                                                labResults.push(labResult)
                                            }
                                        })
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    labItem['labResults'] = labResults;
                }
            });
        }
    })


    const {width} = useWindowDimensions();

    const {isBiometryAuthenticated} = useSelector((state) => state.Globals);
    const {useAppLock} = useSelector((state) => state.User);
    useEffect(() => {
        setAppLock(useAppLock);
    }, [useAppLock]);
    useEffect(() => {
        setIsAuthenticated(isBiometryAuthenticated);
    }, [isBiometryAuthenticated]);
    const [appLock, setAppLock] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    if (!isAuthenticated && appLock) {
        return (<AppLock/>);
    }


    // List all available Lab Results
    // Select Lab results to display


    let createPDF = async () => {
        let htmlSource = '<div style="margin: 40;">';
        htmlSource += '<h1>Alpha-1-KIDS Laborwerte</h1>';

let dates = [];
        labItems.forEach((labItem) => {
                            labItem.labResults?.forEach((labResult) => {
                if (!!labResult.number) {
                    let found = false;
                    dates.forEach((d) => {
                        if (moment(d).isSame(labResult.date)) {
                            found = true;
                            return;
                        }
                    })
                    if (!found) {
                        dates.push(labResult.date)
                    }
                }
            });
        });
        htmlSource += '<table border="1" cellpadding="3" cellspacing="0">';
        htmlSource += '<tr><td></td>';

        dates.forEach((d) => {
            htmlSource += '<td>' + moment(d, 'YYYY-MM-DD').locale('de').format('Do MMM[<br />]YYYY') + '</td>';
        })
        labItems.forEach((labItem) => {

            htmlSource += '<tr><td style="font-weight: bold;">' + labItem.label + '</td>';


            for (let i = 0; i < dates.length; i++) {
                htmlSource += '<td>';
                labItem.labResults?.forEach((labResult) => {
                    if (!!labResult.number) {
                        if (moment(dates[i]).isSame(labResult.date)) {
                            htmlSource += labResult.text;
                        }
                    }
                })
                htmlSource += '</td>';
            }
            htmlSource += '</tr>';

        })
        htmlSource += '</tr>';
        htmlSource += '</table>';
htmlSource += '<p>Diese Übersicht wurde mit Hilfe der Alpha-1-KIDS-App erstellt. Für die Richtigkeit der Werte für keine Verantwortung übernommen.</p>';
        htmlSource += '</div>';

        let options = {
            html: htmlSource,
            fileName: 'Laborwerte-alpha-1-kids.pdf',
            directory: 'Documents',
        };

        if (Platform.OS === 'native') {
            let file = await RNHTMLtoPDF.convert(options);
            FileViewer.open(file.filePath) // absolute-path-to-my-local-file.
                .then(() => {

                })
                .catch((error) => {

                });

        } else {
            // if on web, download the file using jsPDF
            // TODO: jsPDF doesn't support adding margins to the page, try adding padding to the html
            const doc = new jsPDF({
              format: 'a4',
              unit: 'px',
            });
            doc.html(options.html, {
                async callback(doc) {
                    await doc.save(options.fileName);
                },
		});

        }
    }


    return (
        <View style={styles.container}>

            <DropDownPicker
                containerStyle={{marginTop: 20, marginBottom: 20}}
                open={pickerOpen}
                setOpen={setPickerOpen}
                value={pickerValue}
                setValue={setPickerValue}
                items={labItems}
                onSelectItem={(item) => {
                    console.log('selectedItem', item);


                    let l = [];
                    let data = [];

                    item.labResults?.forEach((labResult) => {
                        if (!!labResult.number) {
                            l.push(moment(labResult.date, 'YYYY-MM-DD').locale('de').format('Do MMM YYYY'));
                            data.push(labResult.number);
                        }

                    })
                    setChartLabels(l)
                    setChartData(data);
                    setListData(item.labResults);
                }}
                listMode={labItems.length > 6 ? "MODAL" : "FLATLIST"}

                textStyle={{
                    fontSize: 18
                }}
                placeholder={"Wählen Sie aus"}
            />

            <Pressable
                style={[styles.dlgButton, styles.dlgButtonBordered]}
                onPress={createPDF}>
                <Image source={require('../assets/images/pdf.png')}
                       style={{height: 40, width: 40, marginRight: 20}}/>

                <Text style={[{
                    marginRight: 10, fontSize: 15,
                    textAlign: 'left',
                }]}>Alle Werte als{'\n'}PDF-Datei exportieren</Text>
            </Pressable>

            <ScrollIndicatorWrapper>
                <View>
                    {chartData.length == 0 &&
                    <Text style={{textAlign: 'center'}}>(Keine Werte für {pickerValue} vorhanden)</Text>}

                    {chartData.length > 0 &&
                    <LineChart
                        data={{
                            labels: chartLabels,
                            datasets: [
                                {
                                    data: chartData
                                }
                            ]
                        }}
                        fromZero={true}
                        width={Dimensions.get("window").width - 40} // from react-native
                        height={330}
                        withShadow={false}
                        verticalLabelRotation={-70}
                        xLabelsOffset={30}
                        chartConfig={{
                            backgroundColor: "#A50000",
                            backgroundGradientFrom: "#4CAAED",
                            backgroundGradientTo: "#71618A",
                            propsForLabels: {
                                fontWeight: 'bold'
                            },
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            style: {
                                borderRadius: 13,
                            },
                            propsForDots: {
                                r: 5,
                                strokeWidth: 2,
                                stroke: "#4D658D"
                            }
                        }}
                        style={{
                            marginBottom: 20,
                            borderRadius: 10
                        }}
                    />}


                    <View style={{
                        borderBottomColor: 'black',
                        borderBottomWidth: 1,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 10,
                        marginLeft: 40,
                        marginRight: 40,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Text style={{fontWeight: 'bold'}}>
                            Datum
                        </Text>
                        <Text style={{fontWeight: 'bold'}}>
                            Wert
                        </Text>
                    </View>
                    {listData.map((d, i) => {
                        return <>
                            <View style={{
                                borderBottomColor: 'black',
                                borderBottomWidth: 1,
                                paddingTop: 5,
                                paddingBottom: 10,
                                marginBottom: 10,
                                marginLeft: 40,
                                marginRight: 40,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Text>
                                    {moment(d.date, 'YYYY-MM-DD').locale('de').format('Do MMMM YYYY')}
                                </Text>
                                <Text style={{color: theme.colors.primary, fontWeight: 'bold'}}>
                                    {d.text}
                                </Text>
                            </View>
                        </>
                    })


                    }</View>
            </ScrollIndicatorWrapper>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        padding: 20,
        backgroundColor: theme.values.defaultBackgroundColor,
    },
    logoStack: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center"
    },
    logo: {
        top: 0,
        height: 100,
        width: 200
    },
    buttonRow: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: "aliceblue",
        justifyContent: "space-evenly",
        paddingEnd: 15,
        paddingStart: 15
    },

    mainButton: {
        width: 160,
        height: 160,
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: 34,
        elevation: 10,
        shadowColor: '#52006A',
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 10
    },
    icon: {
        marginTop: 5,
        height: 70,
        width: 70,
        marginBottom: 8
    },
    buttonText: {
        fontFamily: "roboto-500",
        color: "#121212",
        fontSize: 25,
        marginTop: 5
    },


    dlgButton: {
        flexDirection: "row",
        alignContent: "center",
        alignSelf: "center",
        alignItems: "center",
        borderRadius: 10,
        padding: 5,
        marginBottom: 20
    },

    dlgButtonBordered: {
        borderWidth: 3,
        padding: 7,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        borderColor: theme.colors.primary
    },
});

export default LabResultsScreen;
