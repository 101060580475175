import React, { useEffect, useState } from 'react';
import {
  Alert,
  FlatList,
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';
import GLOBAL from '../config/globals';

// components
// not supported by web -> should work now
import { Banner, ScrollIndicatorWrapper, Spinner } from '../components/shared';
import QuestionnaireModal from '../components/questionnaireModal/questionnaireModal';
// not used here
import CategoriesList from '../components/survey/categoriesList';

// services
import translate, { getLanguageTag } from '../services/localization';
import { appConfig, theme } from '../config';
import exportService from '../services/questionnaireAnalyzer';

// redux actions
import {
  fetchQuestionnaire,
  setContinueWithCategoryIndex,
  switchContent,
} from '../store/questionnaire.slice';
import { sendQuestionnaireResponse } from '../store/sharedActions';

import { Routes } from '../navigation/constants';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import endpoints from '../services/rest/endpoints';
// not used here
// import DashboardButton from '../components/ui/DashboardButton';
import RNModal from 'react-native-modal';
import { Button, IconButton } from 'react-native-paper';
import PersonButton from '../components/ui/PersonButton';
import { formatDateString } from '../services/utils';

import {
  fetchRegistryErstmeldebogenEltern,
  fetchRegistryErstmeldebogenKind,
  fetchRegistryVerlaufsbogenKind,
  fetchRegistryLaborwerte,
} from '../store/registry.slice';
import AppLock from '../components/security/AppLock';
import alert from '../components/shared/Alert';

/***********************************************************************************************
 * renders the survey-screen with the list of all categories, the modal to answer the questionnaire
 * and a button to send the response to the server
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function SurveyScreen({ navigation }) {
  const dispatch = useDispatch();

  const {
    itemMap,
    categories,
    FHIRmetadata,
    retrieveDataCycle,
    continueWithCategoryIndex,
  } = useSelector((state) => state.Questionnaire);
  const { loading, error } = useSelector((state) => state.Globals);
  const { current_questionnaire_id, subjectId, start_date } = useSelector(
    (state) => state.User,
  );

  const [currentItem, setCurrentItem] = useState({});

  NetInfo.configure({
    reachabilityUrl: endpoints.ping,
    reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 3 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: true,
  });
  const netInfo = useNetInfo();

  // when the component loads and no categories are present - i.e. no questionnaire has previously been fetched
  // get questionnaire from backend if no error ocurred before
  useEffect(() => {
    console.log('CALCULATION 2');
    if (!categories && !error && !loading) {
      if (new Date() > new Date(start_date)) {
        dispatch(
          fetchQuestionnaire({
            questionnaireID: current_questionnaire_id,
            subjectId,
          }),
        );
      } else {
        navigation.navigate(Routes.CHECK_IN);
      }
    }
  }, [
    categories,
    dispatch,
    error,
    loading,
    current_questionnaire_id,
    subjectId,
    navigation,
    start_date,
  ]);

  const {
    registryDataEltern,
    registryDataErstmeldebogenKind,
    registryDataVerlaufsbogenKind,
  } = useSelector((state) => state.RegistryData);

  const [continueCategoryIndex, setContinueCategoryIndex] = useState(-1);

  useEffect(() => {
    setContinueCategoryIndex(continueWithCategoryIndex);
  }, [continueWithCategoryIndex]);

  useEffect(() => {
    dispatch(
      fetchRegistryErstmeldebogenEltern({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryErstmeldebogenKind({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryVerlaufsbogenKind({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);
  useEffect(() => {
    dispatch(
      fetchRegistryLaborwerte({
        subjectId,
      }),
    );
  }, [retrieveDataCycle]);

  useEffect(() => {
    if (continueCategoryIndex !== -1) {
      dispatch(switchContent({ categoryIndex: continueCategoryIndex }));
      dispatch(setContinueWithCategoryIndex(-1));
    }
  }, [retrieveDataCycle]);

  let usedElternIds = [];
  let usedKindIds = [];
  const [shownEltern, setShownEltern] = useState([]);
  const [shownKind, setShownKind] = useState([]);

  const [showDashEltern, setShowDashEltern] = useState(false);
  const [showDashKind, setShowDashKind] = useState(false);

  useEffect(() => {
    setShownEltern([]);
    usedElternIds = {};
    registryDataEltern?.map((eltern) => {
      if (!Object.keys(usedElternIds).includes(eltern.elternteil_id)) {
        usedElternIds[eltern.elternteil_id] = eltern;
      } else {
        if (
          usedElternIds[eltern.elternteil_id].elternteil_eintragung_datum <=
          eltern.elternteil_eintragung_datum
        ) {
          usedElternIds[eltern.elternteil_id] = eltern;
        }
      }
    });
    let newElements = [];
    let vals = Object.values(usedElternIds);
    for (let id = 0; id < vals.length; id++) {
      newElements.push({
        id: id + 1,
        eltern: vals[id],
      });
    }
    newElements.push({
      id: -1,
    });
    if (vals.length % 2 === 0) {
      newElements.push({
        id: -100,
      });
    }
    console.log('newElements');
    console.log(newElements);
    setShownEltern(newElements);
  }, [registryDataEltern]);

  useEffect(() => {
    setShownKind([]);
    usedKindIds = {};
    registryDataErstmeldebogenKind?.map((kind) => {
      if (!Object.keys(usedKindIds).includes(kind.kind_kind_id)) {
        usedKindIds[kind.kind_kind_id] = kind;
      } else {
        if (
          usedKindIds[kind.kind_kind_id].eintragung_datum_kind <=
          kind.eintragung_datum_kind
        ) {
          usedKindIds[kind.kind_kind_id] = kind;
        }
      }
    });
    let newElements = [];
    let vals = Object.values(usedKindIds);
    for (let id = 0; id < vals.length; id++) {
      newElements.push({
        id: id + 1,
        kind: vals[id],
      });
    }
    newElements.push({
      id: -1,
    });
    if (vals.length % 2 === 0) {
      newElements.push({
        id: -100,
      });
    }
    console.log(newElements);
    setShownKind(newElements);
  }, [registryDataErstmeldebogenKind]);

  // check if all categories of the questionnaire have been answered as required
  const done = categories?.every((category) => itemMap[category.linkId].done);

  /**
   * handle submission of questionnaire
   */
  const handleSubmit = () => {
    alert(
      translate('generic').info,
      translate('survey').sendFinishedMessage,
      [
        {
          text: translate('survey').sendFinished,
          onPress: () => {
            if (
              NetInfo.refresh().then((state) => {
                if (state.isInternetReachable) {
                  dispatch(
                    sendQuestionnaireResponse({
                      body: exportService.createResponseJSON(
                        itemMap,
                        categories,
                        FHIRmetadata,
                      ),
                    }),
                  );
                  navigation.navigate(Routes.CHECK_IN);
                } else {
                  Alert.alert(
                    translate('generic').errorNoInternetTitle,
                    translate('generic').errorNoInternet,
                    [
                      {
                        text: translate('generic').ok,
                      },
                    ],
                    { cancelable: false },
                  );
                }
              })
            );
          },
        },
        {
          text: translate('generic').abort,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  let totalQuestions = 0;
  let mandatoryQuestions = 0;
  let completedQuestions = 0;
  for (const i in itemMap) {
    totalQuestions++;
    mandatoryQuestions += itemMap[i].required ? 1 : 0;
    completedQuestions += itemMap[i].done ? 1 : 0;
  }
  console.log('CALCULATION 1');

  const images = {
    M: {
      uri: require('../assets/images/mother.png'),
    },
    V: {
      uri: require('../assets/images/father.png'),
    },
    E: {
      uri: require('../assets/images/family.png'),
    },
    m: {
      uri: require('../assets/images/son.png'),
    },
    w: {
      uri: require('../assets/images/daughter.png'),
    },
  };

  const { isBiometryAuthenticated } = useSelector((state) => state.Globals);
  const { useAppLock } = useSelector((state) => state.User);

  useEffect(() => {
    // only make use of app lock on native application
    if (Platform.OS === 'native') {
      setAppLock(useAppLock);
    }
  }, [useAppLock]);
  useEffect(() => {
    setIsAuthenticated(isBiometryAuthenticated);
  }, [isBiometryAuthenticated]);
  const [appLock, setAppLock] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (Platform.OS === 'native' && !isAuthenticated && appLock) {
    return <AppLock />;
  }

  const numColumns = Platform.OS === 'web' ? 3 : 2;

  //  for web, ScrollView won't be displayed properly -> use regular View
  //  for native: use ScrollView 
  const ContentView = ({ children, ...props }) => {
  if (Platform.OS === 'web') {
    return <View {...props}>{children}</View>;
  } else {
    return <ScrollView {...props}>{children}</ScrollView>;
  }
};

  // renders scrollview for web, and not for native
  const ScrollViewWeb = ({ children, ...props }) => {
    if (Platform.OS === 'web') {
      return <ScrollView {...props}>{children}</ScrollView>;
    } else {
      return {children};
    }
  };


  return loading ? (
    <Spinner />
  ) : (
    <View style={[localStyle.flexi, localStyle.wrapper]} testID="SurveyScreen">
      {/* render the top banner */}
      <Banner nav={navigation} title={translate('survey').title} />

      {/* the questionnaire modal */}
      <QuestionnaireModal nav={navigation} />

      <ContentView style={localStyle.content}>
        <Text style={localStyle.familyTitle}>Eltern</Text>

        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <FlatList
            data={shownEltern}
            numColumns={numColumns} // Set the number of columns to 2
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => {
              if (item.id === -100) {
                return <View style={{ width: '50%' }}></View>;
              }
              if (item.id === -1) {
                if (shownEltern.length <= 2) {
                  return (
                    <>
                      <View>
                        <PersonButton
                          name={'Elternteil\nhinzufügen'}
                          image={
                            <Image
                              source={require('../assets/images/add.png')}
                              resizeMode="contain"
                              style={[localStyle.dashModalIcon]}
                            ></Image>
                          }
                          onPress={() => {
                            GLOBAL.currentEltern = null;
                            GLOBAL.currentInstrument = 'erstmeldebogen_eltern';
                            dispatch(switchContent({ categoryIndex: 0 }));
                          }}
                        />
                      </View>
                    </>
                  );
                } else {
                  return <View style={{ width: '50%' }}></View>;
                }
              }
              let eltern = item.eltern;
              let elternTyp;
              let imgFile = images[eltern.elternteil_anrede].uri;
              switch (eltern.elternteil_anrede) {
                case 'M':
                  elternTyp = 'Mutter';
                  break;
                case 'V':
                  elternTyp = 'Vater';
                  break;
                case 'E':
                  elternTyp = 'Vormund';
                  break;
              }

              return (
                <>
                  <View>
                    <PersonButton
                      name={
                        eltern.elternteil_vorname +
                        ' ' +
                        eltern.elternteil_nachname
                      }
                      image={
                        <Image
                          source={imgFile}
                          resizeMode="contain"
                          style={[localStyle.dashModalIcon]}
                        ></Image>
                      }
                      onPress={() => {
                        GLOBAL.currentEltern = eltern;
                        GLOBAL.currentInstrument = 'erstmeldebogen_eltern';
                        setShowDashEltern(true);
                        // dispatch(switchContent({categoryIndex: 0}));
                      }}
                    />
                  </View>
                  {/*<DashboardButton text={eltern.elternteil_vorname + ' ' + eltern.elternteil_nachname}*/}
                  {/*                 onPress={*/}
                  {/*                     () => {*/}
                  {/*                         GLOBAL.currentEltern = eltern;*/}
                  {/*                         GLOBAL.currentInstrument = 'erstmeldebogen_eltern';*/}
                  {/*                         setShowDashEltern(true);*/}
                  {/*                         // dispatch(switchContent({categoryIndex: 0}));*/}
                  {/*                     }*/}
                  {/*                 }*/}
                  {/*                 img={imgFile}/>*/}
                </>
              );
            }}
          />
        </View>

        <Text style={localStyle.familyTitle}>Kinder</Text>

        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <FlatList
            data={shownKind}
            numColumns={numColumns} // Set the number of columns to 2
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => {
              if (item.id === -100) {
                return <View style={{ width: '50%' }}></View>;
              }
              if (item.id == -1) {
                return (
                  <>
                    <View>
                      <PersonButton
                        name={'Kind\nhinzufügen'}
                        image={
                          <Image
                            source={require('../assets/images/add.png')}
                            resizeMode="contain"
                            style={[localStyle.dashModalIcon]}
                          ></Image>
                        }
                        onPress={() => {
                          GLOBAL.currentKind = {};
                          GLOBAL.currentInstrument = 'erstmeldebogen_kind';
                          dispatch(switchContent({ categoryIndex: 1 }));
                        }}
                      />
                    </View>

                    {/*<DashboardButton text={"Kind\nhinzufügen"}*/}
                    {/*                     onPress={*/}
                    {/*                         () => {*/}
                    {/*                             GLOBAL.currentKind = {};*/}
                    {/*                             GLOBAL.currentInstrument = 'erstmeldebogen_kind';*/}
                    {/*                             dispatch(switchContent({categoryIndex: 1}));*/}
                    {/*                         }}*/}
                    {/*                     img={require("../assets/images/add.png")}/>*/}
                  </>
                );
              }
              let kind = item.kind;
              if (!kind.kind_geschlecht) {
                return;
              }
              let kindType = kind.kind_geschlecht == 'm' ? 'Sohn' : 'Tochter';
              let imgFile = images[kind.kind_geschlecht].uri;
              return (
                <>
                  <View>
                    <PersonButton
                      name={kind.kind_vorname + ' ' + kind.kind_nachname}
                      image={
                        <Image
                          source={imgFile}
                          resizeMode="contain"
                          style={[localStyle.dashModalIcon]}
                        ></Image>
                      }
                      onPress={() => {
                        GLOBAL.currentKind = kind;
                        GLOBAL.currentInstrument = 'erstmeldebogen_kind';
                        setShowDashKind(true);
                        // dispatch(switchContent({categoryIndex: 1}));
                      }}
                    />
                  </View>
                  {/*<DashboardButton text={kind.kind_vorname + ' ' + kind.kind_nachname}*/}
                  {/*                 onPress={*/}
                  {/*                     () => {*/}
                  {/*                         GLOBAL.currentKind = kind;*/}
                  {/*                         GLOBAL.currentInstrument = 'erstmeldebogen_kind';*/}
                  {/*                         setShowDashKind(true);*/}
                  {/*                         // dispatch(switchContent({categoryIndex: 1}));*/}
                  {/*                     }*/}
                  {/*                 }*/}
                  {/*                 img={imgFile}/>*/}
                </>
              );
            }}
          />
        </View>

        {/*<View style={[localStyle.flexi, localStyle.wrapper]}>*/}
        {/*    /!* creates the list items for the categories *!/*/}
        {/*    <CategoriesList*/}
        {/*        showQuestionnaireModal={(categoryIndex, pageIndex) => {*/}
        {/*            dispatch(switchContent({categoryIndex, pageIndex}));*/}
        {/*        }}*/}
        {/*        categories={categories}*/}
        {/*        itemMap={itemMap}*/}
        {/*        FHIRmetadata={FHIRmetadata}*/}
        {/*    />*/}
        {/*</View>*/}
      </ContentView>
      {/* renders a send-button at the bottom if the questionnaire is completed */}
      <View style={localStyle.bottom}>
        {/*{done && (*/}
        {/*    <TouchableOpacity*/}
        {/*        accessibilityLabel={translate('survey').send}*/}
        {/*        accessibilityRole={translate('accessibility').types.button}*/}
        {/*        accessibilityHint={*/}
        {/*            translate('accessibility').questionnaire.sendHint*/}
        {/*        }*/}
        {/*        onPress={handleSubmit}*/}
        {/*        style={[localStyle.button, localStyle.buttonComplete]}*/}
        {/*    >*/}
        {/*        <Text style={localStyle.buttonLabel}>*/}
        {/*            {translate('survey').send}*/}
        {/*        </Text>*/}
        {/*    </TouchableOpacity>*/}
        {/*)}*/}
        {/* renders a button to retry fetching of questionnaire */}
        {error?.failedAction === 'questionnaire/FETCH' && (
          <TouchableOpacity
            accessibilityLabel={translate('login').landing.retry}
            accessibilityRole={translate('accessibility').types.button}
            accessibilityHint={translate('accessibility').refreshHint}
            onPress={() =>
              dispatch(
                fetchQuestionnaire({
                  questionnaireID: current_questionnaire_id,
                  subjectId,
                }),
              )
            }
            style={[localStyle.button, localStyle.buttonAlert]}
          >
            <Text style={localStyle.buttonLabel}>
              {translate('login').landing.retry}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      {showDashEltern && (
        <RNModal
          scrollOffsetMax={100}
          avoidKeyboard={true}
          propagateSwipe
          coverScreen={() => {
            Platform.OS === 'native' ? false : true;
          }}
          transparent={true}
          onBackdropPress={() => setShowDashEltern(false)}
          style={[localStyle.dashModal]}
          isVisible={showDashEltern}
        >
          <ScrollViewWeb contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
          <View
            style={{ backgroundColor: theme.colors.white, borderRadius: 10 }}
          >
            <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
              <IconButton
                icon="close"
                iconColor={'black'}
                size={30}
                onPress={() => {
                  setShowDashEltern(false);
                }}
                containerColor={theme.colors.white}
                mode={'contained'}
              />
            </View>

            <TouchableOpacity
              onPress={() => {
                setShowDashEltern(false);
                dispatch(switchContent({ categoryIndex: 0 }));
              }}
              style={{
                backgroundColor: '#EFEFEF',
                padding: 20,
                marginBottom: 10,
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Image
                  source={images[GLOBAL.currentEltern.elternteil_anrede].uri}
                  resizeMode="contain"
                  style={[localStyle.dashModalIcon, { marginRight: 15 }]}
                ></Image>

                <View style={{ flexDirection: 'column', width: '60%' }}>
                  <Text
                    style={[
                      localStyle.familyTitle,
                      {
                        marginLeft: 0,
                        marginTop: 0,
                        marginBottom: 8,
                      },
                    ]}
                  >
                    {GLOBAL.currentEltern.elternteil_vorname +
                      ' ' +
                      GLOBAL.currentEltern.elternteil_nachname}
                  </Text>

                  <Text>{GLOBAL.currentEltern.elternteil_strasse}</Text>
                  <Text>
                    {GLOBAL.currentEltern.elternteil_plz}{' '}
                    {GLOBAL.currentEltern.elternteil_wohnort}
                  </Text>
                  <Text>{GLOBAL.currentEltern.elternteil_land}</Text>
                </View>

                <View
                  style={{
                    height: '100%',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    alignSelf: 'center',
                  }}
                >
                  <Image
                    source={require('../assets/images/edit.png')}
                    resizeMode="contain"
                    style={{
                      marginLeft: 20,
                      marginTop: -40,
                      height: 40,
                      width: 40,
                    }}
                  ></Image>
                </View>
              </View>

              <View style={{ marginTop: 8, marginLeft: 75 }}>
                <Text>{GLOBAL.currentEltern.elternteil_email}</Text>
                <Text>{GLOBAL.currentEltern.elternteil_telefon}</Text>
              </View>
            </TouchableOpacity>
          </View>
          </ScrollViewWeb>
        </RNModal>
      )}

      {showDashKind && (
        <RNModal
          scrollOffsetMax={100}
          avoidKeyboard={() => {
            Platform.OS === 'native' ? true : false;
          }}
          propagateSwipe
          coverScreen={() => {
            Platform.OS === 'native' ? false : true;
          }}
          transparent={true}
          onBackdropPress={() => setShowDashKind(false)}
          style={[localStyle.dashModal, { marginTop: 20 }]}
          isVisible={showDashKind}
        >
          <ScrollViewWeb contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
          <View
            style={{
              backgroundColor: theme.colors.white,
              borderRadius: 10,
              paddingTop: 5,
              paddingBottom: 15,
            }}
          >
            <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
              <IconButton
                icon="close"
                iconColor={'black'}
                size={30}
                onPress={() => {
                  setShowDashKind(false);
                }}
                containerColor={theme.colors.white}
                mode={'contained'}
              />
            </View>

            <View style={{ padding: 20 }}>
              <View style={{ flexDirection: 'row' }}>
                <Image
                  source={images[GLOBAL.currentKind.kind_geschlecht].uri}
                  resizeMode="contain"
                  style={[localStyle.dashModalIcon, { marginRight: 15 }]}
                ></Image>
                <View
                  style={{
                    flexDirection: 'column',
                    width: '60%',
                    paddingTop: 6,
                  }}
                >
                  <Text
                    style={[
                      localStyle.familyTitle,
                      {
                        marginLeft: 0,
                        marginTop: 0,
                        marginBottom: 8,
                      },
                    ]}
                  >
                    {GLOBAL.currentKind.kind_vorname +
                      ' ' +
                      GLOBAL.currentKind.kind_nachname}
                  </Text>
                  <Text>
                    geb. am{' '}
                    {formatDateString(new Date(GLOBAL.currentKind.kind_geb), {
                      locale: getLanguageTag(),
                    })}
                  </Text>
                </View>
              </View>
            </View>

            {GLOBAL.currentKind.kind_gesund !== '1' && (
              <>
                <View>
                  <Pressable
                    style={[localStyle.dlgButton]}
                    onPress={() => {
                      setShowDashKind(false);
                      GLOBAL.currentInstrument = 'verlaufsbogen';
                      dispatch(switchContent({ categoryIndex: 2 }));
                    }}
                  >
                    <View style={{ paddingRight: 40 }}>
                      <View style={{ width: '100%', height: 200 }}>
                        <Text
                          style={[
                            {
                              marginRight: 10,
                              fontSize: 15,
                              marginTop: 22,
                              textAlign: 'left',
                              textColor: theme.colors.primary,
                            },
                          ]}
                        >
                          Verlaufsbogen eintragen
                        </Text>
                      </View>
                    </View>

                    <Image
                      source={require('../assets/images/add.png')}
                      style={{
                        height: 40,
                        width: 40,
                        marginRight: 0,
                        marginTop: 15,
                        paddingLeft: 15,
                        marginLeft: 'auto',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    />
                  </Pressable>
                </View>

                {/*<Pressable*/}
                {/*    style={[localStyle.dlgButton]}*/}
                {/*    onPress={() => {*/}
                {/*        setShowDashKind(false);*/}
                {/*        dispatch(switchContent({categoryIndex: 3}));*/}
                {/*    }}>*/}
                {/*    <View style={{ paddingRight: 40 }}>*/}
                {/*        <View style={{ width: '100%', height: 200 }}>*/}
                {/*            <Text style={[{*/}
                {/*                marginRight: 10, fontSize: 15, marginTop: 22,*/}
                {/*                textAlign: 'left', textColor: theme.colors.primary*/}
                {/*            }]}>Laborwerte eintragen</Text>*/}
                {/*        </View>*/}
                {/*    </View>*/}

                {/*    <Image source={require('../assets/images/add.png')}*/}
                {/*           style={{height: 40, width: 40, marginRight: 0, marginTop: 15, paddingLeft: 15, marginLeft: 'auto', alignContent: "center", alignItems: "center", flexDirection: "row"}}/>*/}
                {/*</Pressable>*/}
              </>
            )}

            <Pressable
              style={[localStyle.dlgButton]}
              onPress={() => {
                setShowDashKind(false);
                navigation.navigate(Routes.OVERVIEW_SURVEY);
              }}
            >
              <View style={{ paddingRight: 40 }}>
                <View style={{ width: '100%', height: 200 }}>
                  <Text
                    style={[
                      {
                        marginRight: 10,
                        fontSize: 15,
                        marginTop: 22,
                        textAlign: 'left',
                        textColor: theme.colors.primary,
                      },
                    ]}
                  >
                    Bisherige Fragebögen
                  </Text>
                </View>
              </View>

              <Image
                source={require('../assets/images/ausklappen.png')}
                style={{
                  width: 15,
                  height: 30,
                  marginRight: 0,
                  marginTop: 20,
                  paddingLeft: 15,
                  marginLeft: 'auto',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              />
            </Pressable>

            <Pressable
              style={[localStyle.dlgButton]}
              onPress={() => {
                setShowDashKind(false);
                navigation.navigate(Routes.LAB_RESULTS);
              }}
            >
              <View style={{ paddingRight: 40 }}>
                <View style={{ width: '100%', height: 200 }}>
                  <Text
                    style={[
                      {
                        marginRight: 10,
                        fontSize: 15,
                        marginTop: 22,
                        textAlign: 'left',
                        textColor: theme.colors.primary,
                      },
                    ]}
                  >
                    Bisherige Laborwerte
                  </Text>
                </View>
              </View>

              <Image
                source={require('../assets/images/ausklappen.png')}
                style={{
                  width: 15,
                  height: 30,
                  marginRight: 0,
                  marginTop: 20,
                  paddingLeft: 15,
                  marginLeft: 'auto',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              />
            </Pressable>
          </View>
          </ScrollViewWeb>
        </RNModal>
      )}
    </View>
  );
}

SurveyScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle =
  // Platform.OS === 'native'
    // ? 
    StyleSheet.create({
        dashModal: {
          // justifyContent: 'flex-end',
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
          borderRadius: 10,
          padding: 20,
        },
        dashModalIcon: {
          marginTop: 5,
          height: 60,
          width: 60,
        },

        buttonDash: {
          marginTop: appConfig.scaleUiFkt(5),
          marginStart: appConfig.scaleUiFkt(30),
          marginEnd: appConfig.scaleUiFkt(30),
        },
        buttonSubmitDash: {
          height: appConfig.scaleUiFkt(60),
          marginBottom: appConfig.scaleUiFkt(10),
        },
        buttonSubmitTextDash: {
          fontSize: appConfig.scaleUiFkt(20),
          color: '#ffffff',
          textAlignVertical: 'center',
        },

        wrapper: {
          backgroundColor: theme.values.defaultBackgroundColor,
         },

        flexi: {
          flex: 1,
        },

        bottom: {
          alignSelf: 'flex-end',
          justifyContent: 'center',
          flex: 1 / 6,
          width: '100%',
        },

        button: {
          ...theme.classes.buttonPrimary,
          bottom: 0,
          width: '80%',
          textAlign: 'center',
        },

        buttonComplete: {
          backgroundColor:
            theme.values.defaultSendQuestionnaireButtonBackgroundColor,
        },

        buttonAlert: {
          backgroundColor: theme.colors.alert,
        },

        buttonLabel: {
          ...theme.classes.buttonLabel,
        },

        dlgButton: {
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,

          paddingLeft: 20,
          paddingRight: 20,

          paddingTop: 0,
          paddingBottom: 70,

          flex: 1,
          flexDirection: 'row',
          borderRadius: 5,
          borderWidth: 0,
          backgroundColor: '#EDEDED',
        },

        infoText: {
          textAlign: 'center',
          alignSelf: 'center',
          color: theme.colors.accent4,
          ...theme.fonts.body,
        },

        familyTitle: {
          ...theme.fonts.title2,
          marginLeft: 20,
          marginTop: 30,
          color: theme.values.defaultTitleTextColor,
        },

        content: {
          paddingHorizontal: 50,
          paddingVertical: 20,
        }
      })
    // : StyleSheet.create({
    //     dashModal: {
    //       // justifyContent: 'flex-end',
    //       marginLeft: 0,
    //       marginRight: 0,
    //       marginBottom: 0,
    //       borderRadius: 10,
    //       padding: 20,
    //      },
    //     dashModalIcon: {
    //       marginTop: 5,
    //       height: 60,
    //       width: 60,
    //     },

    //     buttonDash: {
    //       marginTop: appConfig.scaleUiFkt(5),
    //       marginStart: appConfig.scaleUiFkt(30),
    //       marginEnd: appConfig.scaleUiFkt(30),
    //     },
    //     buttonSubmitDash: {
    //       height: appConfig.scaleUiFkt(60),
    //       marginBottom: appConfig.scaleUiFkt(10),
    //     },
    //     buttonSubmitTextDash: {
    //       fontSize: appConfig.scaleUiFkt(20),
    //       color: '#ffffff',
    //       textAlignVertical: 'center',
    //     },

    //     wrapper: {
    //       backgroundColor: theme.values.defaultBackgroundColor,
    //      },

    //     content: {
    //       paddingHorizontal: 50,
    //       paddingVertical: 20,
    //     },

    //     flexi: {
    //       flex: 1,
    //     },

    //     bottom: {
    //       alignSelf: 'flex-end',
    //       justifyContent: 'center',
    //       flex: 1 / 6,
    //       width: '100%',
    //     },

    //     button: {
    //       ...theme.classes.buttonPrimary,
    //       bottom: 0,
    //       width: '80%',
    //       textAlign: 'center',
    //     },

    //     buttonComplete: {
    //       backgroundColor:
    //         theme.values.defaultSendQuestionnaireButtonBackgroundColor,
    //     },

    //     buttonAlert: {
    //       backgroundColor: theme.colors.alert,
    //     },

    //     buttonLabel: {
    //       ...theme.classes.buttonLabel,
    //     },

    //     dlgButton: {
    //       marginTop: 20,
    //       marginLeft: 20,
    //       marginRight: 20,

    //       paddingLeft: 20,
    //       paddingRight: 20,

    //       paddingTop: 0,
    //       paddingBottom: 70,

    //       flex: 1,
    //       flexDirection: 'row',
    //       borderRadius: 5,
    //       borderWidth: 0,
    //       backgroundColor: '#EDEDED',
    //     },

    //     infoText: {
    //       textAlign: 'center',
    //       alignSelf: 'center',
    //       color: theme.colors.accent4,
    //       ...theme.fonts.body,
    //     },

    //     familyTitle: {
    //       ...theme.fonts.title2,
    //       fontSize: 40,
    //       marginLeft: 20,
    //       marginTop: 30,
    //       color: theme.values.defaultTitleTextColor,
    //     },
      // })
      ;

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default SurveyScreen;
