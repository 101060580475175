import React, { useEffect, useState } from 'react';
import {
  Platform,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';

// components
// import {Picker} from '@react-native-picker/picker';
// import {CheckBox} from 'react-native-elements';
import { CheckBox } from '@rneui/themed';
import RenderHtml from 'react-native-render-html';

// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import translate from '../../../services/localization';
import { stripTags } from '../../../services/utils';
import { theme } from '../../../config';

import SharedStyles, { calculateIndent } from './sharedStyles';
import ModalInfo from '../../../components/questionnaireModal/modalInfo';

import DropDownPicker from "react-native-dropdown-picker";


/**
 * when an item is of type choice it has the attribute "answerOptions".
 * the entries of that attribute contain the possible choices - and the titles of those
 * choices are either provided by the attribute valueString ot valueInteger.
 * this functions determines what is available an returns it.
 * @param  {AnswerOption} item entry of an answerOption-entry.
 */
const getItemTitle = (item) => {
  let title;

  // sets the title in case of a valueCoding attribute
  if (item.valueCoding) {
    title = item.valueCoding.display ?? item.valueCoding.code;
  } else {
    // get the object entry whose key starts with 'value'
    title =
      item[Object.keys(item).find((key) => key.startsWith('value'))].toString();
  }
  return title;
};

/***********************************************************************************************
 * component:
 * renders a list of choices either as checkboxes, radio buttons or a dropdown
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
function ChoicesInput({ item, initValue }) {
  const dispatch = useDispatch();

  const questionnaireItemMap = useSelector(
    (state) => state.Questionnaire.itemMap,
  );

  const itemMapEntry = useSelector(
    (state) => state.Questionnaire.itemMap[item.linkId],
  );

  let { width } = useWindowDimensions();
  console.log('WIDTH');
  console.log(width);
  width -= 80;
  console.log(width);

  const source = {
    html:
      '<div style="font-weight: bold; font-size: 1.2em">' +
      item.text +
      '</div>',
  };

  const isDropdown =
    item.fieldAnnotation?.includes('[dropdown]') ||
    (item.extension &&
      item.extension.some((extension) =>
        extension.valueCodeableConcept?.coding?.some(
          (coding) => coding.code === 'drop-down',
        ),
      ));

  const setOneAnswer = (answerOption) => {
    dispatch(
      setAnswer({
        linkId: item.linkId,
        answer: {
          [Object.keys(answerOption)[0]]:
            answerOption[Object.keys(answerOption)[0]],
        },
      }),
    );
  };
  const setMultiAnswer = (answerOption) => {
    dispatch(
      setAnswer({
        linkId: item.linkId,
        answer: {
          [Object.keys(answerOption)[0]]:
            answerOption[Object.keys(answerOption)[0]],
        },
        repeats: true,
      }),
    );
  };

  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerValue, setPickerValue] = useState(() => {
    let initValueCoding = item?.answerOption?.find(
      (entry) =>
        JSON.stringify(entry.valueCoding.code) === JSON.stringify(initValue),
    );
    if (!initValueCoding) {
      initValueCoding = null;
    }
    console.log('initValueCoding');
    console.log(initValueCoding, typeof initValueCoding);
    console.log(itemMapEntry?.answer?.[0], typeof itemMapEntry?.answer?.[0]);
    console.log('itemMapEntry.answer[0]');

    return JSON.stringify(
      itemMapEntry.answer ? itemMapEntry.answer[0] : initValueCoding,
    );
  });

  useEffect(() => {
    if (isDropdown) {
      dispatch(
        setAnswer({
          linkId: item.linkId,
          answer: JSON.parse(pickerValue),
        }),
      );
    }
  }, [pickerValue]);

  const pickerItems = [];
  item.answerOption.map((answerOption, index) => {
    pickerItems.push({
      label: getItemTitle(answerOption),
      value: JSON.stringify(answerOption),
    });
  });

  const bgColor = (item, answerOption) => {
    let checked = !!questionnaireItemMap[item.linkId].answer?.find(
      (entry) => JSON.stringify(entry) === JSON.stringify(answerOption),
    );
    return checked ? theme.colors.primary_light : theme.colors.white;
  };

  // checks the dependencies of the item and renders it (if the dependencies check out)
  return (
    <>
      {/* title */}

      <RenderHtml contentWidth={width} source={source} />

      {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
        <ModalInfo infoText={stripTags(item.fieldAnnotation)} />
      )}

      {item.repeats && item.answerOption.length > 1 && (
        <Text
          style={[
            {
              fontWeight: 'bold',
              marginBottom: 5,
              marginTop: 15,
            },
          ]}
        >
          {translate('accessibility').questionnaire.multipleChoice}
        </Text>
      )}
      {/* checks if the drop-down extension is available. */}
      {/* if yes, it will render it. */}
      {/* if not, the default way is chosen. */}
      {isDropdown ? (
        <>
          <DropDownPicker
            containerStyle={{ marginTop: 20 }}
            open={pickerOpen}
            setOpen={setPickerOpen}
            value={pickerValue}
            setValue={setPickerValue}
            items={pickerItems}
            listMode={pickerItems.length > 6 ? 'MODAL' : 'FLATLIST'}
            textStyle={{
              fontSize: 18,
            }}
            placeholder={'Wählen Sie aus der Liste aus...'}
          />
        </>
      ) : /* repeat: false; display as radio buttons */

      !item.repeats ? (
        <View
          style={{
            marginTop: 20,
            flex: 1,
            flexWrap: 'wrap',
            flexDirection: item.custom_alignment === 'RH' ? 'row' : 'column',
          }}
        >
          {item.answerOption.map((answerOption, index) => (
            <TouchableOpacity
              onPress={() => setOneAnswer(answerOption)}
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 10,
                marginLeft: item.custom_alignment === 'RH' ? 0 : 5,
                paddingLeft: item.custom_alignment === 'RH' ? 20 : 5,
                marginBottom: 15,
                borderColor: theme.colors.accent1,
                borderWidth: 1,
                borderRadius: 10,
                backgroundColor: bgColor(item, answerOption),
              }}
            >
              {item.custom_alignment !== 'RH' && (
                <CheckBox
                  uncheckedIcon="circle-o"
                  checkedIcon="dot-circle-o"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.linkId}.a_${index}`}
                  textStyle={SharedStyles.choiceText}
                  checkedColor={theme.colors.accent4}
                  uncheckedColor={theme.colors.accent1}
                  containerStyle={{
                    ...SharedStyles.choice,
                    marginLeft: calculateIndent(item.linkId),
                  }}
                  onPress={() => setOneAnswer(answerOption)}
                  onIconPress={() => setOneAnswer(answerOption)}
                  checked={
                    !!questionnaireItemMap[item.linkId].answer?.find(
                      (entry) =>
                        JSON.stringify(entry) === JSON.stringify(answerOption),
                    )
                  }
                />
              )}
              <RenderHtml
                contentWidth={width}
                baseStyle={{
                  marginRight: item.custom_alignment === 'RH' ? 0 : 45,
                }}
                source={{
                  html:
                    '<p style="font-size: 1.3rem">' +
                    markdownToHtml(getItemTitle(answerOption)) +
                    '</p>',
                }}
              />
            </TouchableOpacity>
          ))}
        </View>
      ) : (
        /* repeat: true; display as checkboxes */
        <View
          style={{
            marginTop: 20,
            flex: 1,
            flexDirection: item.custom_alignment === 'RH' ? 'row' : 'column',
          }}
        >
          {item.answerOption.map((answerOption, index) => (
            /* repeat: true; display as checkboxes */
            <TouchableOpacity
              onPress={() => setMultiAnswer(answerOption)}
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 10,
                marginLeft: item.custom_alignment === 'RH' ? 0 : 5,
                paddingLeft: item.custom_alignment === 'RH' ? 20 : 5,
                marginBottom: 15,
                borderColor: theme.colors.accent1,
                borderWidth: 1,
                borderRadius: 10,
                backgroundColor: bgColor(item, answerOption),
              }}
            >
              <CheckBox
                checkedColor={theme.colors.accent4}
                uncheckedColor={theme.colors.accent1}
                onPress={() => setMultiAnswer(answerOption)}
                onIconPress={() => setMultiAnswer(answerOption)}
                checked={
                  !!questionnaireItemMap[item.linkId].answer?.find((entry) => {
                    return (
                      entry.valueCoding.code === answerOption.valueCoding.code
                    );
                  })
                }
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.linkId}.a_${index}`}
                containerStyle={{
                  ...SharedStyles.choice,
                  marginLeft: calculateIndent(item.linkId),
                }}
                textStyle={SharedStyles.choiceText}
              />

              <RenderHtml
                contentWidth={width}
                baseStyle={{ marginRight: 45 }}
                source={{
                  html:
                    '<p style="font-size: 1.3rem">' +
                    markdownToHtml(getItemTitle(answerOption)) +
                    '</p>',
                }}
              />
            </TouchableOpacity>
          ))}
        </View>
      )}
    </>
  );

  function markdownToHtml(markdown: string): string {
    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, '<strong>$1</strong>');

    // Replace italic syntax with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, '<em>$1</em>');

    // Replace underlined syntax with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, '<u>$1</u>');

    return markdown;
  }
}

ChoicesInput.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};

export default ChoicesInput;