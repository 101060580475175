import { Alert, Platform } from 'react-native';

const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join('\n'),
  );

  if (result) {
    const confirmOption = options.find(({ button }) => button !== 'cancel');
    console.log("Confirm Option: " + confirmOption)
    confirmOption && confirmOption.onPress !== undefined && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({ button }) => button === 'cancel');
    console.log("Cancel Option: " + cancelOption);
    cancelOption && cancelOption.onPress();
  }
};

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default alert;
