import { Platform } from 'react-native';
// import EncryptedStorage from 'react-native-encrypted-storage';
import Storage from 'src/services/localStorage';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { loggedInClient } from '../services/rest';
import translate from '../services/localization';
import kioskApi from '../config/kioskApiConfig';
// import PushNotification from "react-native-push-notification";
// check if this works for web and native with using mock RNFS -> yes, should work
import RNFS from '../components/shared/RNFS';
import { appConfig } from '../config';
import { setUseAppLock } from '../store/user.slice';
import showMessage from '../components/shared/showMessage';
import alert from '../components/shared/Alert';

const isKioskMode = kioskApi.active;

/**
 * shared actions used by multiple slices of the state
 */

const hideModal = createAction('shared/HIDE_MODAL');

const sendQuestionnaireResponse = createAsyncThunk(
  'shared/SEND_QUESTIONNAIRE_RESPONSE',
  async ({ body, triggerMap, instrument }, thunkApi) => {
    const {
      User: {
        subjectId,
        current_questionnaire_id,
        current_instance_id,
        certificate,
      },
    } = thunkApi.getState();
    try {
      console.log('send questionnaire response...');
      console.log('instrument');
      console.log(instrument);
      // send out response
      const response = await (isKioskMode
        ? kioskApi.sendQuestionnaire()
        : loggedInClient.sendQuestionnaire(
            body,
            triggerMap,
            subjectId,
            current_questionnaire_id,
            current_instance_id,
            certificate,
          ));
      
            showMessage({
                duration: 5000,
                message: translate('generic').sendSuccess,
                position: "bottom",
                type: "success",
            });
            //
            // Alert.alert(
        //     translate('generic').successTitle,
        //     translate('generic').sendSuccess,
      // );
      console.log('PRE fullfillWithvalue')
      return thunkApi.fulfillWithValue( { 'response': response, 'instrument_name': instrument });
      console.log('POST fullfillWithvalue')
    } catch (err) {
      alert(
        translate('generic').errorTitle,
        translate('generic').sendError,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
      return thunkApi.rejectWithValue({
        error: {
          code: err.code ?? 'ERROR',
          message: err.message,
          failedAction: 'shared/SEND_QUESTIONNAIRE_RESPONSE',
        },
      });
    }
  },
);

const resetAnswers = createAsyncThunk(
    'shared/RESET_ANSWERS',
    async () => {
  console.log('resetting answers');
});

const sendReport = createAsyncThunk(
  'shared/SEND_REPORT',
  async ({ subjectId, certificate }, thunkApi) => {
    try {
      // send out report
      const response = await loggedInClient.sendReport(subjectId, certificate);
      // return data to update state
      Alert.alert(
        translate('generic').successTitle,
        translate('generic').sendSuccess,
      );
      return thunkApi.fulfillWithValue(response);
    } catch (err) {
      Alert.alert(
        translate('generic').errorTitle,
        translate('generic').sendError,
        [
          {
            text: translate('generic').ok,
          },
        ],
        { cancelable: false },
      );
      return thunkApi.rejectWithValue({
        error: {
          code: err.code ?? 'ERROR',
          message: err.message,
          failedAction: 'shared/SEND_REPORT',
        },
      });
    }
  },
);

const reset = createAsyncThunk('shared/RESET', async () => {
  if (appConfig.bleManager) {
    if (Platform.OS === 'ios') {
      // appConfig.bleManager.cancelDeviceConnection(DEVICE_ID)
      //     .catch((error) => {
      //         console.error(error);
      //         throw error;
      //     });
    }
    appConfig.bleManager.destroy();
    appConfig.bleManager = null;
    console.log('Manager destroyed');
  }
  await Storage.clear();

  if (Platform.OS === 'native') {
    [appConfig.docscanFilePath, appConfig.zipFilePath].forEach((path) => {
      // TODO: Find out if I have to write something similar for web or if it suffices that this is never called in web
      RNFS.exists(path).then((exists) => {
        exists
          ? RNFS.unlink(path).catch((err) => {
              console.error('Error while deleting path', path, err);
              throw err;
            })
          : null;
      });
    });

    PushNotification.cancelAllLocalNotifications();
  }

  setUseAppLock(false);
  
  isKioskMode ? kioskApi.resetKioskModeData() : null;
});

export {
  hideModal,
  sendQuestionnaireResponse,
  sendReport,
  reset,
  resetAnswers,
};
