import React, { useEffect } from 'react';
import { Image, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import RenderHtml, {
  HTMLContentModel,
  HTMLElementModel,
} from 'react-native-render-html';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../propTypes';

// services & config
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';

// custom input components for the different types of questions
import BasicInput from './input/basicInput';
import BooleanInput from './input/booleanInput';
import ChoicesInput from './input/choicesInput';
import SegmentedButtonsInput from './input/segmentedButtonsInput';
import DateInput from './input/dateInput';
import SliderInput from './input/sliderInput';

// shared styles & style calculations
import SharedStyles, {
  calculateFontSize,
  calculateIndent,
  calculateLineHeight,
} from './input/sharedStyles';
import BasicMultiInput from '../../components/questionnaireModal/input/basicMultiInput';
import GLOBAL from '../../config/globals';
import { theme } from '../../config';
import moment from 'moment/moment';
import { switchContent } from '../../store/questionnaire.slice';
import { stripTags } from '../../services/utils';
import ModalInfo from '../../components/questionnaireModal/modalInfo';

const renderers = {};

/***********************************************************************************************
 * component:
 * renders a single FHIR-Questionnaire Item
 * should this item be oif type group, its child items are rendered recursively
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function QuestionnaireItem({ item, handleForwardPress }) {
  const dispatch = useDispatch();

  const questionnaireItemMap = useSelector(
    (state) => state.Questionnaire.itemMap,
  );

  const {
    pageIndex,
    categoryIndex,
    categories,
    itemMap,
    FHIRmetadata: metadata,
  } = useSelector((state) => state.Questionnaire);

  const {
        registryDataEltern,
        registryDataErstmeldebogenKind,
        registryDataVerlaufsbogenKind
    } = useSelector((state) => state.RegistryData);

  const summary = () => {
    const { width } = useWindowDimensions();

    return (
      <View style={{ marginTop: 15 }}>
        {categories?.[categoryIndex]?.item.map((item, pageIndex) => {
          if (
            !questionnaireAnalyzer.checkConditionsOfSingleItem(
              item,
              questionnaireItemMap,
              registryDataVerlaufsbogenKind,
            ) ||
            questionnaireAnalyzer.itemIsEmbedded(item, questionnaireItemMap)
          ) {
            return <></>;
          }

          let answer = undefined;

          if (item.type == 'display') {
            answer = '';
            if (item.fieldAnnotation.includes('[displaysummaryanswer|')) {
              answer = item.fieldAnnotation;
              const summaryTextRegex = /\[displaysummaryanswer\|(.+?)\]/g;
              let matches = answer.matchAll(summaryTextRegex);
              for (let captureGroups of matches) {
                answer = captureGroups[1];
              }
            }

            const fieldEmbeddingRegex = /\{(.+?)\}/g;
            let matches = answer.matchAll(fieldEmbeddingRegex);

            if (matches) {
              for (let captureGroups of matches) {
                console.log('captureGroupsB ', captureGroups, answer);
                let embeddedFieldName = captureGroups[1];
                for (let linkId of linkIds) {
                  let origCode = questionnaireItemMap[linkId]?.origCode;
                  if (!!origCode && origCode === embeddedFieldName) {
                    let show =
                      questionnaireAnalyzer.checkConditionsOfSingleItem(
                        questionnaireItemMap[linkId],
                        questionnaireItemMap,
                        registryDataVerlaufsbogenKind,
                      );

                    if (itemMap[linkId].type == 'string') {
                      answer = answer.replace(
                        captureGroups[0],
                        show ? itemMap[linkId].answer?.[0]?.valueString : '',
                      );
                    } else if (itemMap[linkId].type == 'email') {
                      answer = answer.replace(
                        captureGroups[0],
                        show ? itemMap[linkId].answer?.[0]?.valueEmail : '',
                      );
                    } else if (itemMap[linkId].type == 'date') {
                      if (itemMap[linkId].fieldAnnotation.includes('[mmyyyy')) {
                        answer = answer.replace(
                          captureGroups[0],
                          show
                            ? moment(itemMap[linkId].answer?.[0]?.valueDate)
                                .locale('de')
                                .format('MM / YYYY')
                            : '',
                        );
                      } else {
                        answer = answer.replace(
                          captureGroups[0],
                          show
                            ? moment(itemMap[linkId].answer?.[0]?.valueDate)
                                .locale('de')
                                .format('DD.MM.YYYY')
                            : '',
                        );
                      }
                    } else if (itemMap[linkId].type == 'choice') {
                      if (itemMap[linkId].repeats) {
                        let choiceAnswer = itemMap[linkId]?.answer?.length
                          ? '- '
                          : '';
                        itemMap[linkId].answer?.map((ans, i) => {
                          if (choiceAnswer != '- ') {
                            choiceAnswer += '<br />- ';
                          }
                          choiceAnswer += ans?.valueCoding.display;
                        });
                        answer = answer.replace(
                          captureGroups[0],
                          show ? choiceAnswer : '',
                        );
                      } else {
                        answer = answer.replace(
                          captureGroups[0],
                          show
                            ? itemMap[linkId].answer?.[0]?.valueCoding.display
                            : '',
                        );
                      }
                    } else if (itemMap[linkId].type == 'integer') {
                      answer = answer.replace(
                        captureGroups[0],
                        show ? itemMap[linkId].answer?.[0]?.valueInteger : '',
                      );
                    } else if (itemMap[linkId].type == 'decimal') {
                      answer = answer.replace(
                        captureGroups[0],
                        show ? itemMap[linkId].answer?.[0]?.valueDecimal : '',
                      );
                    }
                    break;
                  }
                }
              }
              if (answer === '') {
                return <></>;
              }
            } else {
              return <></>;
            }
          } else if (item.fieldAnnotation.includes('[slider|')) {
            let r = itemMap[item.linkId].answer?.[0];
            if (typeof r?.valueCoding !== 'undefined') {
              let answerOptionValueCodingMap = {};
              item.answerOption.map((answerOption, index) => {
                answerOptionValueCodingMap[answerOption.valueCoding.code] =
                  index;
              });
              answer = answerOptionValueCodingMap[r.valueCoding.code];
            }
            if (typeof r !== 'undefined') {
              if (r === 'NOANSWER') {
                answer = 'Sie möchten hierzu<br />keine Angabe machen';
              } else {
                answer = r.toString();
              }
            }
          } else if (item.type == 'string') {
            answer = itemMap[item.linkId].answer?.[0]?.valueString;
          } else if (item.type == 'email') {
            answer = itemMap[item.linkId].answer?.[0]?.valueEmail;
          } else if (item.type == 'date') {
            if (item.fieldAnnotation.includes('[mmyyyy')) {
              answer = moment(itemMap[item.linkId].answer?.[0]?.valueDate)
                .locale('de')
                .format('MM / YYYY');
            } else {
              answer = moment(itemMap[item.linkId].answer?.[0]?.valueDate)
                .locale('de')
                .format('DD.MM.YYYY');
            }
          } else if (item.type == 'choice') {
            if (item.repeats) {
              answer = '- ';
              itemMap[item.linkId].answer?.map((ans, i) => {
                if (answer != '- ') {
                  answer += '<br />- ';
                }
                answer += ans?.valueCoding.display;
              });
            } else {
              answer = itemMap[item.linkId].answer?.[0]?.valueCoding.display;
            }
          } else if (item.type == 'integer') {
                        answer = itemMap[item.linkId].answer?.[0]?.valueInteger;
          } else if (item.type == 'decimal') {
                        answer = itemMap[item.linkId].answer?.[0]?.valueDecimal;
          }

          if (item.fieldAnnotation?.includes('[multiinput')) {
            answer = answer?.split('~ ').join('<br />');
          }

          let questiontext = item.text;
          if (item.fieldAnnotation.includes('[displaysummarytext|')) {
            questiontext = item.fieldAnnotation;
            const summaryTextRegex = /\[displaysummarytext\|(.+?)\]/g;
            let matches = questiontext.matchAll(summaryTextRegex);
            for (let captureGroups of matches) {
              questiontext = captureGroups[1];
            }
          }

          return (
            <View
              style={{
                marginBottom: 10,
                backgroundColor: '#efefef',
                position: 'relative',
              }}
            >
              <RenderHtml
                contentWidth={width - 50}
                source={{
                  html:
                    '<div style="padding: 10px "><div style="font-weight: bold; font-size: 1.2em; ">' +
                    questiontext +
                    '</div> ' +
                    '<div style="font-weight: normal; font-size: 1.2em; color: ' +
                    theme.colors.primary +
                    '">' +
                    (answer !== undefined ? answer : '(keine Angabe)') +
                    '</div></div>',
                }}
              />

              <TouchableOpacity
                style={{ position: 'absolute', bottom: 3, right: 5 }}
                onPress={() => {
                  dispatch(
                    switchContent({
                      pageIndex: pageIndex + 1,
                    }),
                  );
                }}
              >
                <Image
                  source={require('src/assets/images/edit.png')}
                  style={{ height: 30, width: 30 }}
                />
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    );
  };

  let linkIds = Object.keys(questionnaireItemMap);

  // if the item represents a group of questions, display the title of the group and render the children below
  if (item.type === 'display') {
    const { width } = useWindowDimensions();
    let text = item.text;

    const customHTMLElementModels = {};

    const fieldEmbeddingRegex = /\{(.+?)\}/g;
    let matches = item.text.matchAll(fieldEmbeddingRegex);

    if (matches) {
      for (let captureGroups of matches) {
        text = text.replace(
          captureGroups[0],
          '<' +
            captureGroups[1] +
            '>' +
            captureGroups[1] +
            '</' +
            captureGroups[1] +
            '>',
        );

        customHTMLElementModels[captureGroups[1]] =
          HTMLElementModel.fromCustomModel({
            tagName: captureGroups[1],
            mixedUAStyles: {
              width: 50,
              height: 50,
              borderRadius: 25,
              alignSelf: 'center',
              backgroundColor: 'blue',
            },
            contentModel: HTMLContentModel.block,
          });

        let embeddedFieldName = captureGroups[1];
        console.log('found embedded', embeddedFieldName);

        for (let linkId of linkIds) {
          let origCode = questionnaireItemMap[linkId]?.origCode;
          if (!!origCode && origCode === embeddedFieldName) {
            if (!renderers[origCode]) {
              function Renderer({
                TDefaultRenderer,
                tnode,
                ...defaultRendererProps
              }) {
                return (
                  <QuestionnaireItem
                    handleForwardPress={handleForwardPress}
                    item={questionnaireItemMap[linkId]}
                    key={linkId}
                  />
                );
              }

              renderers[origCode] = Renderer;
            }
          }
        }
      }

      // TODO Conditions to move on resolved?
    }

    const pipingRegex = /\[(.+?)\]/g;
    matches = text.matchAll(pipingRegex);

    if (matches) {
      for (let captureGroups of matches) {
        for (let linkId of linkIds) {
          let origCode = questionnaireItemMap[linkId]?.origCode;
          if (!!origCode && origCode === captureGroups[1]) {
            let conditions = questionnaireAnalyzer.checkConditionsOfSingleItem(
              questionnaireItemMap[linkId],
              questionnaireItemMap,
              registryDataVerlaufsbogenKind,
            );
            if (!conditions) {
              text = text.replace(captureGroups[0], '');
            } else {
              if (questionnaireItemMap[linkId].type == 'choice') {
                let textAnswers = '';
                questionnaireItemMap[linkId]?.answer?.forEach((answer) => {
                  textAnswers += answer?.valueCoding?.display + '<br />';
                });
                text = text.replace(captureGroups[0], textAnswers);
              }
            }
            break;
          }
        }
      }
    }

    const source = {
      html:
        '<div style="font-weight: bold; font-size: 1.2em">' + text + '</div>',
    };

    if (
      !questionnaireAnalyzer.checkConditionsOfSingleItem(
        item,
        questionnaireItemMap,
        registryDataVerlaufsbogenKind
      )
    ) {
      return (
        <RenderHtml
          contentWidth={width}
          source={{
            html: '<span></span>',
          }}
        />
      );
    }

    return (
      <>
        <RenderHtml
          contentWidth={width}
          source={source}
          renderers={renderers}
          customHTMLElementModels={customHTMLElementModels}
        />
        {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
          <ModalInfo infoText={stripTags(item.fieldAnnotation)} />
        )}
        {item.fieldAnnotation.includes('[summary]') && <>{summary()}</>}
      </>
    );
  } else {
    if (
      !questionnaireAnalyzer.checkConditionsOfSingleItem(
        item,
        questionnaireItemMap,
        registryDataVerlaufsbogenKind,
      )
    ) {
      return <></>;
    }

    let itemControlExtension;
    let isSlider;
    let questionItem;

    let initValue = null;
    if (GLOBAL.currentInstrument === 'erstmeldebogen_eltern') {
      // FIXME Unterscheide ersteintragung und Datenänderung
            let eltern = registryDataEltern?.[0];
            initValue = eltern?.[item.origCode];
        } else if (GLOBAL.currentInstrument === 'erstmeldebogen_kind') {
      let eltern = registryDataEltern?.[0];
            switch(item.origCode) {
                case 'kind_wohnort':
                    initValue = eltern?.['elternteil_wohnort'];
                    break;
                case 'kind_plz':
                    initValue = eltern?.['elternteil_plz'];
                    break;
                case 'kind_strasse':
                    initValue = eltern?.['elternteil_strasse'];
                    break;
                case 'kind_land':
                    initValue = eltern?.['elternteil_land'];
                    break;
            }
        } else if (GLOBAL.currentInstrument === 'verlaufsbogen') {
            let lastEntryDate = '';
            registryDataVerlaufsbogenKind?.forEach((verlaufsbogen, i) => {
                if (verlaufsbogen.ver_kind_id === GLOBAL?.currentKind?.kind_kind_id) {
                    if (lastEntryDate < verlaufsbogen.ver_datum_eintragung) {
                        lastEntryDate = verlaufsbogen.ver_datum_eintragung;
                        initValue = verlaufsbogen[item.origCode];
                    }
                }
            });
            if (lastEntryDate === '') {
                let origCode = 'kind_' + item.origCode.slice(4);
                console.log('trying', origCode);
                initValue = GLOBAL?.currentKind?.[origCode];
console.log('should be', initValue)
      }
    }

    switch (item.type) {
      // creates regular inputs for strings
      case 'integer':
      case 'decimal':
      case 'string':
      case 'email':
        if (
          item.fieldAnnotation &&
          item.fieldAnnotation.includes('[slider|') &&
          item.fieldAnnotation.indexOf(']') != -1
        ) {
          questionItem = <SliderInput item={item} key={item.linkId} />;
        } else if (
          item.fieldAnnotation &&
          item.fieldAnnotation.includes('[multiinput')
        ) {
          questionItem = <BasicMultiInput item={item} key={item.linkId} />;
        } else {
          questionItem = (
            <BasicInput
              initValue={initValue}
              handleForwardPress={handleForwardPress}
              item={item}
              key={item.linkId}
            />
          );
        }
        break;
      // creates either a list of radio buttons, a list of checkboxes or a drop-down element
      case 'choice':
        if (item.fieldAnnotation.includes('[matrix')) {
          questionItem = (
            <SegmentedButtonsInput item={item} key={item.linkId} />
          );
        } else if (item.fieldAnnotation.includes('[slider')) {
          questionItem = <SliderInput item={item} key={item.linkId} />;
        } else {
          questionItem = (
            <ChoicesInput item={item} key={item.linkId} initValue={initValue} />
          );
        }
        break;
      // creates a checkbox
      case 'boolean':
        return <BooleanInput item={item} key={item.linkId} />;

      // creates a date input
      case 'date':
      case 'time':
      case 'datetime':
        if (!initValue) {
          if (item.fieldAnnotation.includes('[init')) {
            initValue = betweenMarkers(item.fieldAnnotation, '[init|', ']');
          } else {
            initValue = moment(new Date()).locale('de').format('YYYY-MM-DD');
          }
        }
        questionItem = (
          <DateInput item={item} key={item.linkId} initValue={initValue} />
        );
        break;
      // creates the inputs for decimals and integers (and numerical sliders)
      // this also utilizes the decimal-pad or the num-pad
      case 'slider':
        questionItem = <SliderInput item={item} key={item.linkId} />;
        break;
      // if nothing else matches - display the title
      default:
        questionItem = (
          <Text
            style={{
              ...SharedStyles.contentTitle,
              fontSize: calculateFontSize(item.linkId),
              lineHeight: calculateLineHeight(item.linkId),
              marginLeft: calculateIndent(item.linkId),
            }}
          >
            {item.text}
          </Text>
        );
    }
    return (
      <>
        {/* the question item itself */}
        {questionItem}
        {/* nested items of the question if existent */}
        {item.item &&
          item.item.map((subItem) => (
            <QuestionnaireItem item={subItem} key={subItem.linkId} />
          ))}
      </>
    );
  }
}

function betweenMarkers(text, begin, end) {
  const firstChar = text.indexOf(begin) + begin.length;
  const lastChar = text.indexOf(end);
  const newText = text.substring(firstChar, lastChar);
  return newText;
}

QuestionnaireItem.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};
